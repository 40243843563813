import { createStyleSheet, addCssRule, removeSheetRules } from 'src/core/util/DomTools';

let cssSheet;
let appRootNode;

export const setRootContainer = (node) => {
  appRootNode = node;
};

export const getCssClassName = (property, ratio) =>
  `home-tile-${property}${String(ratio).replace('.', 'dot')}`;

export const resetSheet = () => {
  removeSheetRules(cssSheet);
};

/**
 * Return the CSS sheet used to define widths and heights
 * (Masonry does not work with inline style...)
 */
const getSheet = () => {
  if (!cssSheet) {
    cssSheet = createStyleSheet(appRootNode, null);
  }
  return cssSheet;
};

/**
 * Add CSS rules only for ratios defined in tiles configuration
 * (e.g. if only .home-tile-width1, .home-tile-width3, .home-tile-height2 are needed, only these are created)
 * @param  {array}  entries
 * @param  {number} columnWidth
 */
export const setCssRules = (entries, columnWidth) => {
  // Stores which properties have already been inserted
  // e.g. if (added.width[2] === true), it means that rule '.home-tile-width2' has already been created.
  const added = {};

  const addRule = (property, values) => {
    const ratio = values[property];

    // Init `added` for this property if needed
    if (!added[property]) {
      added[property] = {};
    }

    if (!added[property][ratio]) {
      const cssSelector = `.${getCssClassName(property, ratio)}`;

      // Insert rule
      addCssRule(getSheet(), cssSelector, `${property}: ${columnWidth * ratio}px`);
      added[property][ratio] = true;
    }
  };

  // At least .home-tile-width1 must be set as it is used as reference by Masonry (@see option `columnWidth`)
  addRule('width', { width: 1 });

  // Iterate on tiles config to add rule
  entries.forEach((entry) => {
    addRule('width', entry.options);
    addRule('height', entry.options);
  });
};
