// Libs
import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import GenericIcon from 'src/components/generic-icon/GenericIcon';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';

const GenericMenuItem = ({ entry, associatedPageKey, closeMenu, isDisabled }) => {
  // CONTAINER
  const hasContainerStyle =
    entry.style && typeof entry.style.container === 'object' && entry.style.container !== null;
  const attributes = {};
  const classes = ['content-font'];

  // css
  if (hasContainerStyle && entry.style.container.style) {
    attributes.style = entry.style.container.style;
  }
  // if (isDisabled === true) {
  //    classes.push('menu-item-disabled');
  // }
  if (hasContainerStyle && entry.style.container.className) {
    classes.push(entry.style.container.className);
  }
  attributes.className = classes.join(' ');

  // is tappable ?
  attributes.disabled = isDisabled === true;

  // onClick handler
  attributes.onClick =
    isDisabled === true
      ? null
      : () => {
          closeMenu(associatedPageKey);
          entry.action(associatedPageKey);
        };

  // ICON
  const hasIconStyle =
    entry.style && typeof entry.style.icon === 'object' && entry.style.icon !== null;
  const iconClasses = ['menu-icon'];

  if (hasIconStyle && entry.style.icon.className) {
    iconClasses.push(entry.style.icon.className);
  }

  return (
    // https://material-ui.com/api/list-item/#listitem-api
    <ListItem {...attributes}>
      {/* <ListItemIcon> */}
      <GenericIcon
        {...entry.style.icon?.svgProps}
        className={iconClasses.join(' ')}
        style={hasIconStyle && entry.style.icon.style ? entry.style.icon.style : null}
      />
      {/* </ListItemIcon> */}

      {/* <ListItemText primary={ */}
      <span className="menu-text" dangerouslySetInnerHTML={{ __html: entry.label }} />
      {/* } /> */}
    </ListItem>
  );
};

GenericMenuItem.propTypes = {
  entry: PropTypes.object.isRequired,
  closeMenu: PropTypes.func.isRequired,
  // Indicate on which page the menu instance is attached
  associatedPageKey: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

export default GenericMenuItem;
