const LOCALSTORAGE_MODAL_PRIVACY = 'modal-privacy-agreement-accepted';

/**
 * modal-privacy-agreement getter
 * @return {object}
 */
export function getIsPrivacyModalAccepted() {
  const data = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_MODAL_PRIVACY) || 'false');
  return data || false;
}

/**
 *modal-privacy-agreement setter
 * @param {object}
 */
export function setIsPrivacyModalAccepted(value) {
  window.localStorage.setItem(LOCALSTORAGE_MODAL_PRIVACY, JSON.stringify(value));
}
