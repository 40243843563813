import config from 'app-customs/config/config';
import Push from 'src/core/push';
import { getParameterByName } from 'src/core/util/JsTools';
import { getBindedActions } from 'src/store/bindedActions';

const LOG_PREF = '[Profile] ';

const QUERY_PARAMETER_KEY = 'profile';
const PROFILE_LOCALSTORAGE_KEY = 'profile';

/**
 * Get the current profile
 * @return {string}
 */
export const get = () => localStorage.getItem(PROFILE_LOCALSTORAGE_KEY);

/**
 * Set the current profile
 * @param  {string} value
 */
export function set(value) {
  if (typeof value === 'string') {
    // Check argument value
    if (config.SUPPORTED_PROFILES.indexOf(value) === -1) {
      console.error(
        `${LOG_PREF}Unsupported profile \`${value}\`, accepted values are `,
        config.SUPPORTED_PROFILES
      );
      // value = config.DEFAULT_PROFILE;
    }

    // Ignore if value is the same
    if (get() !== value) {
      _persist(value);
      Push.setTag('Profile', value);

      // During app boot sequence, store and actions are not available yet
      if (getBindedActions() && getBindedActions().profileChanged) {
        getBindedActions().profileChanged(value);
      }
    }
  }
}

function _persist(value) {
  localStorage.setItem(PROFILE_LOCALSTORAGE_KEY, value || '');
}

const isProfileSupported = (value) => config.SUPPORTED_PROFILES.indexOf(value) !== -1;

/**
 * Indicate if a (valid) profile has been set
 * @return {boolean}
 */
export function checkProfile(currentValue = get()) {
  const profilesCount = config.SUPPORTED_PROFILES.length;

  // If there is a single profile
  // or if current profile is not supported (happens a lot on webpack dev when switching projects)
  // Then set default profile
  if (profilesCount === 1) {
    set(config.DEFAULT_PROFILE);
    return true;
  }
  if (currentValue) {
    if (!isProfileSupported(currentValue)) {
      _persist(config.DEFAULT_PROFILE);
      // Set a default value, yet redirect to ChooseProfilePage
      return false;
    }
    set(currentValue);
    return true;
  }
  return false;
}

/**
 * Initialize by setting current profile
 */
export function init() {
  // Read value from query string, or localstorage, or use default
  const queryValue = getParameterByName(QUERY_PARAMETER_KEY);

  const profile = queryValue || get() || config.DEFAULT_PROFILE;

  checkProfile(profile);
  console.log(`${LOG_PREF}value: ${get()}`);
}
