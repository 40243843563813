import { DATA_TYPE_EVENTS, DATA_TYPE_SIDE_EVENTS } from 'app-customs/config/dataConfig';
import { formatGroupedItemDate } from 'app-customs/config/sortConfig';
import { DISABLE_FAVORITE_ICON } from 'app-customs/config/listConfig';

import {
  ALL_FAVORITES_DELETED,
  CLEAR_SEARCH_RESULTS,
  CONFIG_JSON_LOADED,
  CORDOVA_PAUSE,
  CORDOVA_RESUME,
  DATA_ASSETS_UPDATED,
  DOCUMENT_VISIBLE,
  FETCH_FAVORITES,
  GROUPED_ITEMS_FETCHED,
  HAS_NAVIGATED,
  KEYBOARD_TOGGLED,
  LANG_CHANGED,
  NAVIGATE,
  POLL_CONFIG_LOADED,
  PROFILE_CHANGED,
  SEARCH_PERFORMED,
  SET_SEARCH_FIELD_VISIBLE,
  TOGGLE_FAVORITE,
  TOGGLE_LOCATION_STATUS,
  TOGGLE_MENU,
  UPDATE_PAGE_STATE,
} from 'src/store/actionTypes';

import {
  configJsonLoaded,
  pollConfigLoaded,
  dataUpdated,
  getPageCommonDefaultState,
  groupedItemsFetched,
  handleSetSearchFieldVisible,
  togglePageAfterNavigation,
  langChanged,
  profileChanged,
  setAppVisible,
  setIsFavoriteFalse,
  toggleLocationStatus,
  toggleMenu,
  updateKeyboardState,
  updateObject,
  updatePageState,
  searchPerformed,
  clearSearchResults,
  fetchFavorites,
  toggleFavorite,
} from 'src/store/reducers/commons';

import { LIST_GROUPS_PAGE_KEY } from 'src/pages/pagesKeys';

const getDefaultState = () => ({
  ...getPageCommonDefaultState(LIST_GROUPS_PAGE_KEY),
  isPending: true,
  favorites: {},
  favIconDisabled: DISABLE_FAVORITE_ICON === true,
});

function _navigate(state, action) {
  if (action.pageKey === LIST_GROUPS_PAGE_KEY) {
    const shouldAutoScroll = action.historyAction !== 'NONE';
    if (JSON.stringify(action.options.input) !== JSON.stringify(state.input)) {
      return updateObject(state, {
        groupedItems: null,
        shouldFetch: true,
        shouldAutoScroll,
      });
    }
    return updateObject(state, {
      shouldAutoScroll,
    });
  }
  return state;
}

function _groupedItemsFetched(state, action) {
  const updatedState = groupedItemsFetched(state, action);

  // Determine item to autoscroll to
  if ((action.dataType === DATA_TYPE_EVENTS || action.dataType === DATA_TYPE_SIDE_EVENTS) && state.shouldAutoScroll) {
    const itemToScrollTo = getItemIdToScrollTo(action.groupedItems);
    if (itemToScrollTo) {
      updatedState.itemIdToScrollTo = itemToScrollTo.id;
    }
  } else {
    updatedState.itemIdToScrollTo = null;
  }
  return updatedState;
}

const hourMinuteRegExp = /^(\d{1,2}):(\d{2})$/;
/**
 * e.g getDate(new Date('2019-12-10 00:00'), '10:00')
 *     -> returns: Tue Dec 10 2019 10:00:00
 *
 * @param  {date} dateDayLong
 * @param  {string} stringHour
 * @return {date}
 */
function getDate(dateDayLong, stringHour) {
  if (!stringHour) {
    return null;
  }
  const regExpResult = hourMinuteRegExp.exec(stringHour);
  if (!regExpResult) {
    console.error(
      `Unexpected behaviour, stringified hour cannot be parsed, expected [H]H:MM, got: ${stringHour}`
    );
    return null;
  }
  const hours = parseInt(regExpResult[1], 10);
  const minutes = parseInt(regExpResult[2], 10);

  const date = new Date(dateDayLong);
  date.setHours(hours);
  date.setMinutes(minutes);
  return date;
}

function findEventInDay(events, now) {
  for (let i = 0; i < events.length; i++) {
    const event = events[i];
    if (!event.start_date || !event.start_time) {
      continue;
    }

    // end time is after current time
    else if (event.end_time) {
      // Patch to handle fr times
      // TODO move to ISO formated event.start_date_time and event.start_date_time as soon as it is available on the Backend.
      const end_time = event.end_time.replace('h', ':');
      const endDateTime = getDate(event.start_date, end_time);
      if (endDateTime > now) {
        return event;
      }
      continue;
    }

    // start time is after current time
    else {
      // TODO move to ISO formated event.start_date_time and event.start_date_time as soon as it is available on the Backend.
      const start_time = event.start_time.replace('h', ':');
      const startDateTime = getDate(event.start_date, start_time);
      if (startDateTime > now) {
        return event;
      }
      continue;
    }
  }
  return null;
}

/**
 * Determine the next event that will finish
 * @param  {object} groupedItems
 * @return {object}
 */
function getItemIdToScrollTo(groupedItems) {
  if (!groupedItems || Object.keys(groupedItems).length === 0) {
    return;
  }

  // To test, mock the variable `now` below. e.g  now = new Date('2020-04-01 13:45:12')
  const now = new Date(); // new Date('2020-02-08 01:00:01');

  const dates = Object.keys(groupedItems)
    .map((d) => parseInt(d, 10))
    .sort((a, b) => a - b); /* ensure that dates array is chronogically ordered */
  const dateLabels = dates.map((d) => groupedItems[d].name);

  // Is current date time before start_time of the first event ?
  const firstEvent = groupedItems[dates[0]].items[0];
  if (now < firstEvent.start_date) {
    // yes
    return firstEvent;
  }

  // Is there a group of events for current day ?
  const currentDayLabel = formatGroupedItemDate(now);
  const matchIndex = dateLabels.findIndex((label) => currentDayLabel === label);
  if (matchIndex !== -1) {
    // yes
    const date = dates[matchIndex];
    const events = groupedItems[date].items;
    const matchingEvent = findEventInDay(events, now);

    if (matchingEvent) {
      // matching event found
      return matchingEvent;
    }
    // no matching event -> scroll to the first event of the day
    return events[0];
  }
  // no
  const lastDate = new Date(dates[dates.length - 1]);
  // Is current date after start_date of the last event ?
  if (now > lastDate) {
    const lastDayEvents = groupedItems[dates[dates.length - 1]].items;
    return lastDayEvents[lastDayEvents.length - 1];
  }
  // Look on the next day containing events
  for (let i = 0; i < dates.length; i++) {
    const value = dates[i];
    const comparingDate = new Date(value);
    if (now < comparingDate) {
      return groupedItems[value].items[0];
    }
  }

  // Should not be possible but if none of the above condition match simply scroll to first event.
  return firstEvent;
}

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case CONFIG_JSON_LOADED:
      return configJsonLoaded(state);
    case CORDOVA_PAUSE:
      return setAppVisible(state, false);
    case CORDOVA_RESUME:
      return setAppVisible(state, true);
    case DOCUMENT_VISIBLE:
      return setAppVisible(state, action.value);
    case HAS_NAVIGATED:
      return togglePageAfterNavigation(state, LIST_GROUPS_PAGE_KEY);
    case POLL_CONFIG_LOADED:
      return pollConfigLoaded(state, action);
    case ALL_FAVORITES_DELETED:
      return setIsFavoriteFalse(state);
    case DATA_ASSETS_UPDATED:
      return dataUpdated(state, action, state.input ? state.input.dataType : []);
    case GROUPED_ITEMS_FETCHED:
      return _groupedItemsFetched(state, action);
    case KEYBOARD_TOGGLED:
      return updateKeyboardState(state, action);
    case LANG_CHANGED:
      return langChanged(state, action);
    case NAVIGATE:
      return _navigate(state, action);
    case PROFILE_CHANGED:
      return profileChanged(state, action, LIST_GROUPS_PAGE_KEY);
    case TOGGLE_FAVORITE:
      return state.input ? toggleFavorite(state, action, [state.input.dataType]) : state;
    case TOGGLE_LOCATION_STATUS:
      return toggleLocationStatus(state, action);
    case TOGGLE_MENU:
      return toggleMenu(state, action, LIST_GROUPS_PAGE_KEY);
    case UPDATE_PAGE_STATE:
      return updatePageState(state, action, LIST_GROUPS_PAGE_KEY);
    case SEARCH_PERFORMED:
      return searchPerformed(state, action, LIST_GROUPS_PAGE_KEY);
    case SET_SEARCH_FIELD_VISIBLE:
      return handleSetSearchFieldVisible(state, action, LIST_GROUPS_PAGE_KEY);
    case CLEAR_SEARCH_RESULTS:
      return clearSearchResults(state, action, LIST_GROUPS_PAGE_KEY);
    case FETCH_FAVORITES:
      return fetchFavorites(state, action);

    default:
      return state;
  }
};
