import React from 'react';
import PropTypes from 'prop-types';
import anime from 'animejs/lib/anime.es.js';

import './Tabs.scss';

const TAB_MIN_WIDTH = 125;
const TAB_MAX_WIDTH = 200;

const FOLLOWING_TAB_WIDTH_VISIBLE = 50; // percent

const SELECTED_TAB_CLASS = 't-tab-selected';
const DISABLED_TAB_CLASS = 't-tab-disabled';

class Tabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.tabsEls = {};
  }

  componentWillUnmount() {
    this.tabsEls = {};
  }

  setTabEl = (tabEl) => {
    if (tabEl) {
      this.tabsEls[tabEl.dataset.index] = tabEl;
    }
  };

  determineTabWidth() {
    let totalTabs = this.props.data.length;
    let clientWidth = document.documentElement.clientWidth;
    let tempTabWidth = clientWidth / totalTabs;
    let tabWidth = tempTabWidth;

    if (tempTabWidth < TAB_MIN_WIDTH) {
      let percentOfTheNextTab = FOLLOWING_TAB_WIDTH_VISIBLE / 100;
      let tempTabNumber = clientWidth / TAB_MIN_WIDTH;
      let tempTabNumberFloored = Math.floor(tempTabNumber);
      let tempTabNumberFloat = tempTabNumber - tempTabNumberFloored;
      let tabNumber = Math.max(
        1 + percentOfTheNextTab,
        Math.floor(clientWidth / TAB_MIN_WIDTH) - (1 - percentOfTheNextTab)
      );

      if (tempTabNumberFloat > percentOfTheNextTab)
        tabNumber = Math.max(
          1 + percentOfTheNextTab,
          Math.floor(clientWidth / TAB_MIN_WIDTH) + percentOfTheNextTab
        );

      tabWidth = clientWidth / tabNumber;
    } else if (tempTabWidth > TAB_MAX_WIDTH) {
      tabWidth = TAB_MAX_WIDTH;
    }
    // Update state only if necessary
    if (tabWidth !== this.state.tabWidth) {
      this.setState({ tabWidth: tabWidth });
    }
  }

  setTabsRef = (el) => {
    this.tabsEl = el;
    this.updateXMaxThreshold();
  };

  updateXMaxThreshold() {
    if (this.tabsEl) {
      if (this.tabsEl.offsetWidth < document.documentElement.clientWidth) {
        // No auto scroll when there is no x overflow
        this.xMaxThreshold = 0;
      } else {
        this.xMaxThreshold = this.tabsEl.offsetWidth - document.documentElement.clientWidth;
      }
    }
  }

  setTabsContainerRef = (el) => {
    this.tabsContainerEl = el;
  };

  handleTabClick = (event) => {
    let tabEl = event.target;
    this.requireAnimation = true;
    
    if (tabEl.classList.contains(DISABLED_TAB_CLASS)) {
      return;
    }

    const tabIndex = parseInt(tabEl.dataset.index, 10);

    this.setIndex(tabIndex);
  };
  // updatePositionTab
  updatePositionScrollTab() {
    let offsetLeft =
      this.state.tabWidth * parseInt(this.props.currentIndex);
    let scrollValue = offsetLeft - (document.documentElement.clientWidth - this.state.tabWidth) / 2;

    this.tabsContainerEl && 
    anime({
      targets: this.tabsContainerEl,
      scrollLeft: scrollValue,
      easing: 'easeInOutCirc',
      duration: this.requireAnimation ? 400 : 0,
    });
    this.requireAnimation = false;
  }

  setIndex(index) {
    this.props.onTabIndexChange(index);
  }

  tabRenderer = ({ key, getTabContent, isCurrentTab, isClickable, ...remainingProps }, index) => (
    <div
      key={key}
      ref={this.setTabEl}
      data-index={index}
      className={
        't-tab' +
        (isCurrentTab(key) ? ' ' + SELECTED_TAB_CLASS : '') +
        (typeof isClickable === 'function' && !isClickable() ? ' ' + DISABLED_TAB_CLASS : '')
      }
      style={{ width: this.state.tabWidth + 'px' }}
      onClick={this.handleTabClick}
      {...remainingProps}
    >
      <div className="t-tab-content-wrapper">{getTabContent()}</div>
    </div>
  );

  componentDidMount() {
    this.determineTabWidth();
    this.updateXMaxThreshold();
    this.updatePositionScrollTab();
  }

  componentDidUpdate() {
    this.determineTabWidth();
    this.updateXMaxThreshold();
    this.updatePositionScrollTab();
  }

  render() {
    if (!Array.isArray(this.props.data) || this.props.data.length === 0) {
      return null;
    }

    return (
      <div
        ref={this.setTabsContainerRef}
        className="t-tabs-container scrollbars-hidden"
        style={this.props.style}
      >
        {typeof this.state.tabWidth === 'number' && (
          <div
            ref={this.setTabsRef}
            className="t-tabs"
            style={{ width: this.props.data.length * this.state.tabWidth }}
          >
            {this.props.data.map(this.tabRenderer)}
          </div>
        )}
      </div>
    );
  }
}

Tabs.propTypes = {
  data: PropTypes.array.isRequired,
  onTabIndexChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  selectedTabStyle: PropTypes.object,
};

Tabs.defaultProps = {
  style: {},
  selectedTabStyle: {},
};

export default Tabs;
