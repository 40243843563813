import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Message from 'src/components/inbox/Message';

class MessageList extends Component {
  render() {
    return (
      <ul className="inbox-msg-list">
        {this.props.messages.map((message, index) => (
          <Message
            key={`${message.id}-${index}`}
            message={message}
            onClick={this.props.onClick}
            lang={this.props.lang}
          />
        ))}
      </ul>
    );
  }
}

MessageList.propTypes = {
  messages: PropTypes.array,
  onClick: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};

export default MessageList;
