import { NEWPRODUCT_PAGE_KEY } from 'src/pages/pagesKeys';

import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';
import NewProductContent from './NewProductContent';

export default {
  key: NEWPRODUCT_PAGE_KEY,
  path: '/newproduct',
  elId: DOM_ID,
  className: 'newproduct-page',
  component: GenericItemPage,
  childComponent: NewProductContent,
  relatedDataToFetch: ['exhibitor', 'exhibitors', 'store', 'places', 'newproduct_suggestions'],
};
