import { vCard } from 'src/core/util/vCard';
import { saveAndOpenbase64AsFile } from 'src/core/cordova/fileSystem';

import { getBindedActions } from 'src/store/bindedActions';

import { getUserData } from 'src/core/user-data/UserDataService';

import * as Db from 'src/core/data-and-assets/Db';

import { DATA_TYPE_CONTACTS } from 'app-customs/config/dataConfig';

import { performScan } from 'src/core/contacts/ContactsBadgeHelper';

import showConfirmModal from 'src/core/util/showConfirmModal';

import { get as getLabels } from 'src/core/Lang';

import { DEFAULT_IMAGE } from 'app-customs/config/ficheConfig';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';

// const LOG_PREF = "[Contacts] ";

// Contacts storage

function getStorageKey() {
  const userData = getUserData();
  if (userData) return `contacts-${userData.email}`;
  return 'contacts-';
}

export async function saveContacts(_contacts) {
  localStorage.setItem(getStorageKey(), JSON.stringify(_contacts));
  getBindedActions().contactsUpdated(_contacts);
}

function storeData(contacts) {
  // Add contacts data to the Db module
  // which allows generic app behaviour for search, filter, favorites, fiches, etc
  Db.appendOrUpdateSortedAndTransformedData(contacts, DATA_TYPE_CONTACTS);
}

export function clearData() {
  // Remove contacts data from local Db module
  Db.clearData(DATA_TYPE_CONTACTS);
  getBindedActions().dataAssetsUpdated([DATA_TYPE_CONTACTS]);
}

function readContacts() {
  const data = JSON.parse(localStorage.getItem(getStorageKey()));
  storeData(data || []);
  return data || [];
}

function handleScanResult(result) {
  if (result.status == 'success') {
    checkContact(result.content);
  } else if (result.status == 'cancelled') {
    getBindedActions().badgeScanWithoutResult();
  }
}

export async function scanNewContact() {
  const badgeScanDisclaimerAccepted = JSON.parse(
    localStorage.getItem('badgeScanDisclaimerAccepted')
  );
  if (!badgeScanDisclaimerAccepted) {
    showConfirmModal({
      text: getLabels().contacts.disclaimer,
      yesBtnLabel: getLabels().contacts.disclaimerOk,
      noBtnLabel: getLabels().contacts.disclaimerNOk,
      yesCb: () => {
        localStorage.setItem('badgeScanDisclaimerAccepted', true);
        performScan(handleScanResult);
      },
      noCb: global.noop,
    });
  } else {
    performScan(handleScanResult);
  }
}

// Contacts logic

export const getContacts = () => readContacts();

export const getContact = (id) => {
  const contacts = readContacts();
  return contacts.find((contact) => contact.id === id);
};

function openContactForm(content, existingContact) {
  const contact = existingContact || content;
  getBindedActions().showFormModal(
    {
      title: getLabels().contacts.form.title,
      subtitle: getLabels().contacts.form.subTitle,
      labels: {
        sendBtnLabel: getLabels().common.save,
        cancelBtnLabel: getLabels().common.cancel,
      },
      fields: [
        {
          type: 'hidden',
          subType: 'text',
          name: 'id',
          value: contact.id,
          label: '',
        },
        {
          type: 'hidden',
          subType: 'text',
          name: 'scanDate',
          value: Date.now(),
          label: '',
        },
        {
          type: 'image',
          subType: '',
          storageType: 'local',
          name: 'profilePicture',
          defaultImageUrl: getUrl(`/${DEFAULT_IMAGE[DATA_TYPE_CONTACTS]}`),
          specialPermission: {
            text: getLabels().contacts.photoPermission,
            yesBtnLabel: getLabels().common.ok,
            noBtnLabel: getLabels().common.cancel,
          },
          value: existingContact ? existingContact.profilePicture : null,
          label: getLabels().contacts.form.fields.profilePicture.label,
          mandatory: false,
          validation: {
            type: 'image',
            params: {
              checkCrop: true,
              //mime_type : ['jpeg', 'png']
            },
            validationMessage: getLabels().contacts.form.fields.profilePicture.validationMessage,
            alertMessage: getLabels().contacts.form.fields.profilePicture.alertMessage,
          },
        },
        {
          type: 'inputModal',
          subType: 'text',
          name: 'firstName',
          value: contact.firstName,
          label: getLabels().contacts.form.fields.firstName.label,
        },
        {
          type: 'inputModal',
          subType: 'text',
          name: 'lastName',
          value: contact.lastName,
          label: getLabels().contacts.form.fields.lastName.label,
        },
        {
          type: 'inputModal',
          subType: 'text',
          name: 'organizationName',
          value: contact.organizationName,
          label: getLabels().contacts.form.fields.organizationName.label,
        },
        {
          type: 'inputModal',
          subType: 'text',
          name: 'role',
          value: existingContact ? existingContact.role : '',
          label: getLabels().contacts.form.fields.role.label,
        },
        {
          type: 'inputModal',
          subType: 'email',
          name: 'email',
          value: contact.email,
          label: getLabels().contacts.form.fields.email.label,
          prefilled: !existingContact ? { suffix: contact.email } : null,
        },
        {
          type: 'inputModal',
          subType: 'text',
          name: 'phone',
          value: existingContact ? existingContact.phone : '',
          label: getLabels().contacts.form.fields.phone.label,
        },
        {
          type: 'inputModal',
          subType: 'text',
          name: 'mobilePhone',
          value: existingContact ? existingContact.mobilePhone : '',
          label: getLabels().contacts.form.fields.mobilePhone.label,
        },
      ],
    },
    saveContact,
    null
  );
}

export function checkContact(content) {
  const existingContact = getContact(content.id);

  if (existingContact) {
    showConfirmModal({
      text: getLabels().contacts.contactExist,
      yesBtnLabel: getLabels().common.yes,
      noBtnLabel: getLabels().common.no,
      yesCb: () => {
        openContactForm(content, existingContact);
      },
      noCb: global.noop,
    });
  } else {
    openContactForm(content);
  }
}

/**
 * Add or update a contact
 * @param  {object} contact
 * @param  {boolean} skipSave (optional) using false allows to batch saves and manually 'commit' at the end
 */
export function saveContact(contact, skipSave) {
  if (contact.email && contact.email.split('@') && contact.email.split('@')[0] == '')
    contact.email = '';
  const contacts = readContacts();
  const existingIndex = contacts.findIndex((_contact) => _contact.id === contact.id);

  if (existingIndex !== -1) {
    // Update contact
    contacts.splice(existingIndex, 1, contact);
  } else {
    // Push contact
    contacts.push(contact);
  }

  if (!skipSave) {
    saveContacts(contacts);
  }
}
function saveFormatedContactToDevice(formatedContact, folderpath, filename) {
  const link = vCard.getBase64(formatedContact);
  const myBase64 = link;
  const contentType = 'text/x-vcard';
  saveAndOpenbase64AsFile(folderpath, filename, myBase64, contentType);
}
export function saveContactToDevice(contact) {
  if (global.isCordovaContext !== true) return;
  const formatedContact = vCard.create(vCard.Version.THREE);
  const {
    firstName,
    lastName,
    organizationName,
    role,
    email,
    phone,
    mobilePhone,
    civility,
    profilePicture,
  } = contact;
  (firstName || lastName) && formatedContact.addName(firstName, lastName, civility);
  organizationName && formatedContact.addOrganization(organizationName);
  /* AddRole is not working (on Android at least), so use AddTitle */
  role && formatedContact.addTitle(role);
  email && formatedContact.addEmail(email, vCard.Type.WORK);
  phone && formatedContact.addPhone(phone, vCard.Type.WORK);
  mobilePhone && formatedContact.addPhone(mobilePhone, vCard.Type.CELL);

  const folderpath = cordova.file.cacheDirectory;
  const filename = firstName || lastName ? `${firstName + lastName}.vcf` : 'contact.vcf';

  let _profilePicture =
    profilePicture && typeof profilePicture === 'object' && profilePicture.value
      ? profilePicture.value
      : profilePicture;

  if (_profilePicture) {
    if (_profilePicture.indexOf('http://') > -1 || _profilePicture.indexOf('https://') > -1) {
      const fileURL = cordova.file.cacheDirectory + (firstName + '_' + lastName);
      var fileTransfer = new FileTransfer();
      var uri = encodeURI(_profilePicture);
      fileTransfer.download(uri, fileURL, function(entry) {
        cacheProfilePicture(formatedContact, folderpath, filename, entry);
      });
    } else {
      window.resolveLocalFileSystemURL(
        _profilePicture,
        function(entry) {
          cacheProfilePicture(formatedContact, folderpath, filename, entry);
        },
        function(error) {
          saveFormatedContactToDevice(formatedContact, folderpath, filename);
        }
      );
    }
  } else {
    saveFormatedContactToDevice(formatedContact, folderpath, filename);
  }
}
function cacheProfilePicture(formatedContact, folderpath, filename, entry) {
  const onErrorReadFile = function() {
    saveFormatedContactToDevice(formatedContact, folderpath, filename);
  };
  entry.file(function(file) {
    const reader = new FileReader();
    reader.onloadend = function(evt) {
      const b64ImageWithoutMeat = evt.target.result.split(',')[1];
      formatedContact.addPhoto(b64ImageWithoutMeat);
      saveFormatedContactToDevice(formatedContact, folderpath, filename);
    };
    reader.onerror = function(evt) {
      saveFormatedContactToDevice(formatedContact, folderpath, filename);
    };
    reader.readAsDataURL(file);
  }, onErrorReadFile);
}
