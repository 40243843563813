import { isCordovaContext, isAndroid } from 'src/core/util/browser';

const LOCALSTORAGE_DIALOG_KEY = 'loginToken';

/**
 * DIALOG getter
 * @return {string}
 */
export function getDialogConfig() {
  const data = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_DIALOG_KEY));
  return data && data.isOpen ? data : { isOpen: false, actionName: '' };
}

/**
 * DIALOG setter
 * @param {string} value
 */
export function setDialogConfig(value) {
  isCordovaContext() &&
    isAndroid() &&
    window.localStorage.setItem(LOCALSTORAGE_DIALOG_KEY, JSON.stringify(value));
}
