export const isTopCatId = (topCats, catId) => !!topCats.find((cat) => cat.id === catId);

export const isChecked = (state, catId, forcedTopCatId) => {
  const topCatId =
    forcedTopCatId || (isTopCatId(state.topCats, catId) ? catId : state.selectedTopCatId);

  return (
    topCatId && state.checkedCats[topCatId] && state.checkedCats[topCatId].indexOf(catId) !== -1
  );
};
