/**
 * Ugly mapping to match the 3 letters localized db files generated from ol' cake framework
 * @param { String } lang
 */
export const get3CharsLang = (lang) => {
  switch (lang) {
    case 'fr':
      return 'fre';
    case 'en':
      return 'eng';
    case 'de':
        return 'deu';
    case 'zh':
      return 'zho';
    default:
      console.error(`unsupported language: ${lang}`);
  }
};
