import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { SEARCH_TAIGA_PAGE_KEY } from 'src/pages/pagesKeys';
import config from 'app-customs/config/config';

import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import Menu from 'src/components/menu/Menu';
import SearchForm from 'src/components/search-form/SearchForm';
import Loader from 'src/components/loader/Loader';
import DummyKeyboardPlaceholder from 'src/components/dummy-keyboard-placeholder/DummyKeyboardPlaceholder';

import { getCurrentPageKey, getCurrentPageProps } from 'src/core/navigation/CurrentRoute';
import { renderList } from 'src/components/list/listUtil';
import STATUS from 'src/store/fetchStatuses';

import * as actions from 'src/store/actions';

import './SearchTaigaPage.scss';

const LOG_PREF = '[SearchTaigaPage] ';

class SearchTaigaPage extends Component {
  pageKey = SEARCH_TAIGA_PAGE_KEY;

  static defaultProps = {
    navIndex: 0,
  };

  /**
   * Update string visible in browser tab/history/favorites
   */
  setDocumentContext() {
    if (this.props.isActive(this.pageKey)) {
      this.props.setDocumentContext(this.getPageTitle());
    }
  }

  getPageTitle() {
    let dataLabels = this.props.labels.data[this.props.dataType];
    return this.props.labels.searchTaiga.title + (dataLabels ? dataLabels.plural : '');
  }

  componentDidMount() {
    this.setDocumentContext();
  }

  componentDidUpdate() {
    this.setDocumentContext();
  }

  performSearch = (fields) => {
    this.props.actions.taigaSearch({
      fields,
      dataType: this.props.dataType,
    });
  };

  toggleSearchMode = (newProps) => {
    this.props.actions.taigaSearchClear();

    // Prevent having more than 1 search mode switch in browser history
    if (this.props.navIndex > 0) {
      this.props.actions.navigateBack();
    } else {
      newProps.navIndex = this.props.navIndex + 1;
      this.props.actions.navigate(
        getCurrentPageKey(),
        Object.assign({}, getCurrentPageProps(), newProps)
      );
    }
  };

  isSearchOngoing = () =>
    !!(
      this.props.status &&
      this.props.status[this.props.dataType] &&
      this.props.status[this.props.dataType] === STATUS.PENDING
    );

  renderResults = () => {
    if (this.props.isKeyboardOpen) {
      return null;
    }

    if (this.isSearchOngoing()) {
      return <Loader labels={this.props.labels} />;
    }

    // No search performed
    if (!this.props.results) {
      return null;
    }
    let results = this.props.results[this.props.dataType];
    if (!results || !results.data) {
      return null;
    }
    // No result
    else if (Array.isArray(results.data.all) && results.data.all.length === 0) {
      return <div className="list-empty">{this.props.labels.common.noResult}</div>;
    }
    // More than config defined on SEARCH_TAIGA.WS_PARAMS.take
    else if (
      (Array.isArray(results.data.all) &&
        results.data.all.length > config.SEARCH_TAIGA.WS_PARAMS.take) ||
      results.length > config.SEARCH_TAIGA.WS_PARAMS.take
    ) {
      let moreAccurateText = this.props.labels.searchTaiga.askAccuracy.replace(
        '#number#',
        config.SEARCH_TAIGA.WS_PARAMS.take
      );
      return <div className="list-empty">{moreAccurateText}</div>;
    }
    // Show results
    else {
      return renderList({
        items: this.props.results,
        status: this.props.status,
        favIconDisabled: this.props.favIconDisabled,
        favorites: this.props.favorites,
        userData: this.props.userData,
        actions: this.props.actions,
        labels: this.props.labels,
        pageKey: this.pageKey,
      });
    }
  };

  render() {
    console.log(LOG_PREF + 'render');

    return (
      <>
        <Menu
          options={this.props.menuOptions}
          actions={this.props.actions}
          labels={this.props.labels}
          profile={this.props.profile}
          associatedPageKey={this.pageKey}
          adConfig={this.props.adConfig}
          twoColumns={this.props.twocolumns}
          isLocationEnabled={this.props.isLocationEnabled}
        />

        <AppToolbar
          labels={this.props.labels}
          isDisplayed={this.props.hasToolbar}
          actions={this.props.actions}
          title={this.getPageTitle()}
          pageKey={this.pageKey}
          profile={this.props.profile}
          hasBackButton={this.props.backButtonInToolbar}
          listToolBarButton={this.props.listToolBarButton}
          listConfigToolBarButton={this.props.listConfigToolBarButton}
          hasHomeButton={this.props.homeButtonInToolbar}
          hasFavButton={this.props.favButtonInToolbar}
          hasSearchButton={this.props.searchButtonInToolbar}
          hasMenuButton={this.props.menuButtonInToolbar}
        />

        <div className="content-below-apptoolbar">
          <div className="container-searchForm">
            {/* for scroll with new ui */}
            <SearchForm
              isAdvanced={this.props.isAdvanced}
              toggleSearchMode={this.toggleSearchMode}
              search={this.performSearch}
              isSearchOngoing={this.isSearchOngoing()}
              hasResults={!!this.props.results}
              actions={this.props.actions}
              labels={this.props.labels}
            />
            {this.renderResults()}
          </div>
          <DummyKeyboardPlaceholder keyboardHeight={this.props.keyboardHeight} />
        </div>
      </>
    );
  }
}

SearchTaigaPage.propTypes = {
  dataType: PropTypes.string,
  isAdvanced: PropTypes.bool,
  status: PropTypes.object,
  results: PropTypes.object,
  navIndex: PropTypes.number,
  // Common page props
  menuOptions: PropTypes.object.isRequired,
  profile: PropTypes.string,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  isActive: PropTypes.func.isRequired,
  queryString: PropTypes.string.isRequired,
  setDocumentContext: PropTypes.func.isRequired,
  isLocationEnabled: PropTypes.bool,
  keyboardHeight: PropTypes.number,
  userData: PropTypes.object,
  // toolbar
  hasToolbar: PropTypes.bool,
  homeButtonInToolbar: PropTypes.bool,
  backButtonInToolbar: PropTypes.bool,
  searchButtonInToolbar: PropTypes.bool,
  favButtonInToolbar: PropTypes.bool,
  menuButtonInToolbar: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => state[SEARCH_TAIGA_PAGE_KEY];
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(SearchTaigaPage);
