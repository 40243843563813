import config from 'app-customs/config/config';
import { DATA_TYPE_EXHIBITORS } from 'app-customs/config/dataConfig';

import { get as getLabels } from 'src/core/Lang';
import fetchHelper, { HEADERS } from 'src/core/util/FetchHelper';
import { isUserDataEnoughSet } from 'src/core/user-data/UserDataService';
import { getBindedActions } from 'src/store/bindedActions';

import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';

const LOCALSTORAGE_KEY = 'appointmentRequests';

/**
 * Sub-mobule responsible for persistence
 * @type {Object}
 */
const persistence = {
  get() {
    const value = localStorage.getItem(LOCALSTORAGE_KEY);
    return value ? JSON.parse(value) : null;
  },
  set(value) {
    localStorage.setItem(
      LOCALSTORAGE_KEY,
      typeof value === 'object' ? JSON.stringify(value) : value
    );
  },
};

/**
 * Persist status for an appointment request
 * @param {String} dataOriginalId
 * @param {String} dataType
 * @param {object} status
 */
function setRequestStatus(dataOriginalId, dataType, status) {
  let requests = persistence.get();

  // Initialize if needed
  if (!requests) {
    requests = {};
  }
  if (!requests[dataType]) {
    requests[dataType] = {};
  }

  requests[dataType][dataOriginalId] = status;
  persistence.set(requests);
}

export function getRequestStatus(dataOriginalId, dataType) {
  const requests = persistence.get();

  if (requests && requests[dataType]) {
    const requestStatus = requests[dataType][dataOriginalId];
    if (requestStatus) {
      return requestStatus;
    }
  }
  return null;
}

const getErrorLabel = (errorCode) => getLabels().appointment.errors[errorCode];

function onRequestSuccess(json, dataOriginalId, dataType, dataId, userEmail, userPhone) {
  const status = {};
  if (userEmail) {
    status.userEmail = userEmail;
  }
  if (userPhone) {
    status.userPhone = userPhone;
  }

  // Show notification
  if (json.error) {
    const errorLabel = getErrorLabel(json.error);
    getBindedActions().showNotification({
      message: errorLabel,
      level: NotificationLevels.WARNING,
    });
  }

  // Failure
  if (json.error && json.error !== 'ALREADY_SENT') {
    status.error = { error: json.error };
    getBindedActions().appointmentRequestSendResult(
      false,
      dataOriginalId,
      dataType,
      dataId,
      status
    );
  }
  // Success
  else {
    // Considering 'ALREADY_SENT' as not an error allows to:
    //   - update UI with the proper label
    //   - properly store that information into localstorage

    status.date = new Date(json.date);

    // Persist
    setRequestStatus(dataOriginalId, dataType, status);

    // Dispatch
    getBindedActions().appointmentRequestSendResult(true, dataOriginalId, dataType, dataId, status);
  }
}

function onRequestFailure(failReason, dataOriginalId, dataType, dataId) {
  console.error('sendRequestFailure', arguments);

  // Show failure notification
  const errorLabel = getLabels().appointment.errors.GENERIC;
  getBindedActions().showNotification({
    message: errorLabel,
    level: NotificationLevels.WARNING,
  });

  // Dispatch failure
  const status = {
    error: {
      label: errorLabel,
      reason: failReason,
    },
  };
  getBindedActions().appointmentRequestSendResult(false, dataOriginalId, dataType, dataId, status);
}

export function sendRequest(dataOriginalId, itemDataType = DATA_TYPE_EXHIBITORS, dataId, profile) {
  // Best practice: check that needed `profile` values are set before calling this function
  // The 2 tests below are just security nets
  if (!profile) {
    console.error('Cannot send appointment request because profile is missing.', profile);
    return;
  }
  if (isUserDataEnoughSet(profile) !== true) {
    console.error('Cannot send appointment request because profile is incomplete.', profile);
    return;
  }

  // Request options
  const body = {
    user: {
      civility: profile.title, // optional
      firstname: profile.firstName,
      lastname: profile.lastName,
      org: profile.organisation,
      role: profile.position,
      email: profile.email,
      tel: profile.phone, // optional
    },
  };

  if (itemDataType === DATA_TYPE_EXHIBITORS) {
    body.exhOriginalId = String(dataOriginalId);
  } else {
    console.error(
      `Cannot send appointment request because of unhandled data type: ${itemDataType}`
    );
    return;
  }

  fetchHelper(
    `${config.APPOINTMENT_REQUEST.URL}/send`,
    {
      method: 'POST',
      body: JSON.stringify(body),
      headers: [HEADERS.JSON_CONTENT_TYPE],
    },
    true, // is json
    (json) => {
      onRequestSuccess(json, dataOriginalId, itemDataType, dataId, profile.email, profile.phone);
    },
    (failReason) => {
      onRequestFailure(failReason, dataOriginalId, itemDataType, dataId);
    },
    false // no modal on error
  );
}
