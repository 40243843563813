import { get as getLabels } from 'src/core/Lang';
import { getBindedActions } from 'src/store/bindedActions';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';
import { isIOS, isIpad } from 'src/core/util/browser';
import isOnline from './isOnline';
import * as Query from 'src/core/query/Query';
import { DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EVENTS } from 'app-customs/config/dataConfig';
import moment from 'moment';

const LOG_PREF = '[JsTools] ';

/**
 * Return an array from an object
 * @param  {object} obj
 * @return {array}
 */
export const objectToArray = (obj) => {
  return Object.keys(obj).map((key) => obj[key]);
};

/**
 * Return a querystring from object properties
 * @param  {object}  obj
 * @param  {boolean} encoreUri
 * @return {string}
 */
export const serialize = (obj, encodeUri = true) => {
  if (!obj) {
    return '';
  }
  if (typeof obj !== 'object') {
    console.error(`${LOG_PREF}Cannot serialize non 'object' argument`, obj);
    return '';
  }
  let value = '';

  Object.keys(obj).forEach((optKey) => {
    const optValue = obj[optKey];
    const stringifiedValue =
      ['array', 'object'].indexOf(typeof optValue) === -1 ? optValue : JSON.stringify(optValue);
    value += `&${optKey}=${encodeUri ? encodeURI(stringifiedValue) : stringifiedValue}`;
  });
  return value;
};

/**
 * Sort an array of stringified integers
 * @param  {string} str1
 * @param  {string} str2
 * @return {boolean}
 */
export const sortNumbersAsStrings = (str1, str2) => {
  return parseInt(str1, 10) > parseInt(str2, 10);
};

/**
 * Should ensure that a url is clickable,
 * preventing <a href="www.xyz.com"...> from redirecting to something like: http://oursite/ourpage/www.xyz.com
 * @param  {string} link
 * @return {string}
 */
export const addHttpIfNeeded = (link, useHttps = false) => {
  if (!link) {
    return '';
  }
  if (link.indexOf('://') === -1) {
    return `http${useHttps ? 's' : ''}://${link}`;
  }
  return link;
};

const protocolSeparator = '://';
/**
 * Remove protocol if any (e.g "https://www.abce.fr" -> "www.abcde.fr")
 * @param  {string} link
 * @return {string}
 */
export const removeLinkProtocol = (link) => {
  if (!link) {
    return '';
  }
  const index = link.indexOf(protocolSeparator);
  if (index !== -1) {
    return link.slice(index + protocolSeparator.length);
  }
  return link;
};

/**
 * @param  {string}   href
 * @param  {function} cb (optional): receives argument true if url could be opened (network available), false instead.
 * @param  {string}   target
 * @param  {boolean}  openInInAppBrowser
 * @return {boolean}
 */
export function openUrl(href, cb, target, openInInAppBrowser) {
  if (typeof cb !== 'function') {
    cb = global.noop;
  }

  function perform() {
    // IN-87
    let _target;
    if (global.isCordovaContext) {
      // Beware!
      // TL;DR using InAppBrowser does not involve opening in InAppBrowser
      // On cordova environment, using InAppBrowser is mandatory, because on iOS the native window.open is blocked.
      // So we need to use InAppBrowser to open a link outside the app even when we don't want to open the link in InAppBrowser.
      if (openInInAppBrowser) {
        _target = '_blank';
      } else {
        _target = '_system';
      }
    } else {
      _target = target;
    }

    window.open(href, _target);
    cb(true);
  }

  if (global.isCordovaContext === true) {
    if (href.indexOf('://') === -1) {
      // Cordova + no protocol => local resource => no need to check network
      perform();
      return;
    }
    if (openInInAppBrowser) {
      isOnline((status) => {
        if (status === true) {
          // network OK
          perform();
        } else {
          // network KO
          getBindedActions().showNotification({
            message: getLabels().common.noNetworkTitle,
            level: NotificationLevels.ERROR,
          });
          cb(false);
        }
      });
    } else {
      perform();
    }
  } else {
    perform();
  }
}

/**
 * Merge two arrays and remove duplications (works for primitives only)
 * @param  {array} arr1
 * @param  {array} arr2
 * @return {array|null}
 */
export const mergeArrayWithUniqueValues = (arr1, arr2) => {
  if (Array.isArray(arr1) !== true) {
    console.error('arr1 is not an array');
    return null;
  }
  if (Array.isArray(arr2) !== true) {
    console.error('arr2 is not an array');
    return null;
  }
  const _result = arr1.concat(arr2);

  // Remove duplicates
  const testIndex = (lastIndex, i) => lastIndex !== -1 && lastIndex > i;
  for (let i = 0; i < _result.length - 1; i++) {
    let lastIndex;
    do {
      lastIndex = _result.lastIndexOf(_result[i]);
      if (testIndex(lastIndex, i)) {
        _result.splice(lastIndex, 1);
      }
    } while (testIndex(lastIndex, i));
  }
  return _result;
};

/**
 * Return the attribute value of an object from a path
 * @param {object|array} obj
 * @param {string} attr, example: "user.address.street"
 * @return {*}
 */
export const getValueFromStringAttribute = (obj, attr) => {
  if (!obj) {
    return;
  }
  if (typeof attr !== 'string') {
    throw new Error(`Invalid argument! expected 'attr' to be a string, but got ${typeof attr}`);
  }
  const path = attr.split('.');

  // Simple case
  if (path.length === 1) {
    return obj[path];
  }
  // Case with sub-levels
  let tmpValue = obj[path.shift()];
  let step = path.shift();
  while (step) {
    if (!tmpValue || tmpValue.hasOwnProperty(step) === false) {
      return null;
    }
    tmpValue = tmpValue[step];

    // iterate
    step = path.shift();
  }
  return tmpValue;
};

export const convertFileSrc = function(url) {
  let webviewServerUrl = 'http://localhost';
  if (isIOS() || isIpad()) {
    webviewServerUrl = 'ionic://localhost';
  }

  if (!url) {
    return url;
  }
  if (url.startsWith('/')) {
    return `${webviewServerUrl}/_app_file_${url}`;
  }
  if (url.startsWith('file://')) {
    return webviewServerUrl + url.replace('file://', '/_app_file_');
  }
  if (url.startsWith('content://')) {
    return webviewServerUrl + url.replace('content:/', '/_app_content_');
  }
  return url;
};

export const getRandomLetter = () => String.fromCharCode(Math.floor(Math.random() * 26) + 65);

/**
 * Get query parameter value
 * @source https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
 * @param  {string} name
 * @param  {string} url
 */
export function getParameterByName(name, url) {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[\[\]]/g, '\\$&');

  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/**
 * Cast `value` as String
 * @param {*} value
 */
export const stringify = (value) => (typeof value !== 'string' ? String(value) : value);

export function isStreamingLive(
  start_date_UTC,
  end_date_UTC,
  LAPSE_DATE_BEGIN,
  LAPSE_DATE_END
) {
  if (start_date_UTC && end_date_UTC) {

    const LocalUTCTime = new Date().toISOString();
    /* const EventStartTime = moment(start_date_UTC).add(-LAPSE_DATE_BEGIN, 'minutes').format();
    const EventEndTime = moment(end_date_UTC).add(LAPSE_DATE_END, 'minutes').format(); */
    return (LocalUTCTime > start_date_UTC  && LocalUTCTime< end_date_UTC);
  }
  return false;
}

export const getAgendaProps = () => {
  const item = Query.find(
    [(item) => item.lump && item.lump.hasSynopticAgendaButton],
    DATA_TYPE_EVENT_CATEGORIES,
    null,
    true
  );

  return item && item.id
    ? {
        agendaProps: {
          input: {
            parentId: item.id,
            parentType: DATA_TYPE_EVENT_CATEGORIES,
            dataType: DATA_TYPE_EVENTS,
          },
        },
      }
    : null;
};


function unCamelCase(str) {
  var regex = /(^[a-z]|[A-Z0-9])[a-z]*/;

  if (regex.test(str))
    return (
      str
        // insert a space between lower & upper
        .replace(/([a-z])([A-Z])/g, "$1-$2")
        // uppercase the first character
        .toLowerCase()
    );
  return str;
}
export function objToString(obj) {
  var str = "";
  for (var p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str += unCamelCase(p) + ":" + obj[p] + ";";
    }
  }
  return str;
}



global.noop = function noop() {};
