import {
  WINDOW_RESIZED,
  LANG_CHANGED,
  VALIDATE_MODAL_PRIVACY_AGREEMENT,
  SHOW_MODAL_PRIVACY_AGREEMENT,
} from 'src/store/actionTypes';

import { windowResized, langChanged } from 'src/store/reducers/commons';
import { get as getLabels, getCurrent as getLang } from 'src/core/Lang';

const getDefaultState = () => ({
  lang: getLang(),
  labels: getLabels(),
});

export const _validateModalPrivacy = () => ({
  type: VALIDATE_MODAL_PRIVACY_AGREEMENT,
});

export const _showModalPrivacy = () => ({
  type: SHOW_MODAL_PRIVACY_AGREEMENT,
});

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case SHOW_MODAL_PRIVACY_AGREEMENT:
      return _showModalPrivacy(state, action);
    case VALIDATE_MODAL_PRIVACY_AGREEMENT:
      return _validateModalPrivacy(state, action);
    case WINDOW_RESIZED:
      return windowResized(state, action);
    case LANG_CHANGED:
      return langChanged(state, action);

    default:
      return state;
  }
};
