import { SHOW_SECURED_PDF } from 'src/store/actionTypes';

import { checkCodePdf } from './pdfSecured';

export default ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case SHOW_SECURED_PDF:
        checkCodePdf(action.paperItem);
        break;
      default: // for linter
    }

    return result;
  };
