const LOCALSTORAGE_USERDATA_SSO_KEY = 'userData';

/**
 * UserData SSO getter
 * @return {object}
 */
export function getUserData() {
  const data = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_USERDATA_SSO_KEY));

  return data || null;
}

/**
 * UserData SSO setter
 * @param {object}
 */
export function setUserData(value) {
  window.localStorage.setItem(LOCALSTORAGE_USERDATA_SSO_KEY, JSON.stringify(value));
}
