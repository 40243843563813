import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as actions from 'src/store/actions';

import './Disclaimer.scss';

export const COMPONENT_KEY = 'Disclaimer';
export const CONTAINER_DOM_ID = 'disclaimer-container';

const LOCAL_STORAGE_KEY = 'disclaimer-accepted';

class Disclaimer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      accepted: window.localStorage.getItem(LOCAL_STORAGE_KEY),
    };
  }

  /**
   * User accepts the disclaimer
   */
  handleClick = () => {
    this.setState({
      style: {
        opacity: 0,
        bottom: '-100px',
      },
    });
    window.localStorage.setItem(LOCAL_STORAGE_KEY, true);

    // Remove component after transition
    window.setTimeout(
      function(component) {
        component.setState({ accepted: true });
      },
      1000,
      this
    );
  };

  render() {
    if (this.state.accepted || !this.props.showDisclaimer) {
      return null;
    } else {
      return (
        <div id="disclaimer" style={this.state.style || null} className="content-font">
          <span id="disclaimer-content">{this.props.labels.disclaimer.text}</span>
          <span id="disclaimer-button" onClick={this.handleClick}>
            OK
          </span>
        </div>
      );
    }
  }
}

Disclaimer.propTypes = {
  labels: PropTypes.object.isRequired,
  showDisclaimer: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => state[COMPONENT_KEY];
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(Disclaimer);
