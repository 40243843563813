import { STORES_PAGE_KEY } from 'src/pages/pagesKeys';

import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';
import StoresContent from './StoresContent';

export default {
  key: STORES_PAGE_KEY,
  path: '/stores',
  elId: DOM_ID,
  className: 'stores-page',
  component: GenericItemPage,
  childComponent: StoresContent,
  relatedDataToFetch: [
    'country',
    'cats',
    'newproducts',
    'places',
    'gmapplaces',
    'events',
    'aircrafts',
    'docunits',
    'stores',
  ],
};
