import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

/**
 * doc: https://material-ui.com/components/switches/#switch
 *
 * Code below is an updated copy/paste of a customized switch sample
 */

const height = 18;
const thumbPadding = 1;

const SmallSwitch = withStyles((theme) => ({
  root: {
    width: 34,
    height: 18,
    padding: '0 0 2px 0',
    display: 'flex',
  },
  switchBase: {
    padding: 1,
    color: theme.palette.common.black,
    backgroundColor: theme.palette.grey,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.black,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.grey[400],
      },
    },
  },
  thumb: {
    width: height - thumbPadding * 2,
    height: height - thumbPadding * 2,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.grey[400],
  },
  checked: {},
}))(Switch);

export default SmallSwitch;
