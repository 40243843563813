import React from 'react';
import PropTypes from 'prop-types';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

function FooterGenericButton({
  className,
  isTextButton,
  callToAction,
  callback,
  disabled,
  children,
}) {
  const classNames = [
    'klf-btn',
    isTextButton ? 'klf-text-btn' : '',
    callToAction ? 'klf-cta-btn' : '',
    className || '',
  ];

  return (
    <GenericIcon
      layout="div"
      className={classNames.join(' ')}
      onClick={!disabled && typeof callback === 'function' ? callback : null}
      disabled={typeof disabled === 'boolean' && disabled}
      onTouchMove={() => {}}
    >
      {children}
    </GenericIcon>
  );
}

FooterGenericButton.propTypes = {
  className: PropTypes.string,
  isTextButton: PropTypes.bool,
  callback: PropTypes.func,
  disabled: PropTypes.bool,
};

export default FooterGenericButton;
