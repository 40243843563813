import { ENV } from 'app-customs/config/config';

import { scan } from 'src/core/cordova/BarCodeScanner';
import {
  getTestDataBadge,
  getEscVisitorData,
} from 'src/core/webservices/klipso-leads/KlipsoLeadsWS';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';
import { getBadgeFields, getContext } from './KlipsoLeadsData';

const LOG_PREF = '[KlipsoLeadsBadgeHelper] ';

global.klMockBadge = false;

/**
 * Uncrypt the content of badge (QR code)
 * @param  {string}
 * @return {string}
 */
const uncrypt = (string) =>
  new Promise(function(resolve, reject) {
    if (!string) {
      console.warn(`${LOG_PREF}No string to uncrypt`);
      resolve(null);
      return;
    }
    if (
      !window.mobilespotBadgeScan ||
      typeof window.mobilespotBadgeScan.uncryptKlipsoBadge !== 'function'
    ) {
      console.error(`${LOG_PREF}cordova-plugin-mobilespot-app is missing`);
      reject();
      return;
    }

    window.mobilespotBadgeScan.uncryptKlipsoBadge(string, resolve, reject);
  });

/**
 * String format is expected as:
 * code&value1#value2#value3#...
 *
 * e.g: "HW744RQ86H&Ribeiro#CHRISTINE#VACATAIRE#ducat.c@live.fr#"
 *
 * see doc: https://mobile-spot.atlassian.net/wiki/spaces/KLEAD/pages/113704964/Current+App+and+testing.
 *
 * @param  {string} string
 * @return {null|object}
 */

function getStringResult(result, badgeFields, values, string) {
  const res = { ...result };
  badgeFields.forEach((badgeField) => {
    const fieldCode = badgeField.Code;
    const cabPosition = badgeField.CABPosition;
    const indexInValues = badgeField.CABPosition - 1;

    if (indexInValues > values.length - 1) {
      console.warn(`Can't read a value at CABPosition ${cabPosition} in string: ${string}`);
      return;
    }
    // console.log('Field for CABPosition '+cabPosition+' is '+fieldCode+', its value is: '+values[indexInValues]);

    res[fieldCode] = values[indexInValues];
  });
  return res;
}

function splitUncryptedStringLeni(string, badgeFields) {
  const ampersandIndex = string.indexOf('&');
  const code = string.slice(0, ampersandIndex);
  const values = string.slice(ampersandIndex + 1).split('#');
  const result = {
    code,
    guid: `${code}-${new Date().getTime()}`,
  };
  return getStringResult(result, badgeFields, values, string);
}

function splitUncryptedStringGoomeo(string, badgeFields) {
  const values = string.split(';');
  if (values[values.length - 1] === '') {
    values.pop();
  }
  const code = values[values.length - 1];
  const result = { code, guid: `${code}-${new Date().getTime()}` };
  values.pop();
  return getStringResult(result, badgeFields, values, string);
}

// For test purpose
function splitUncryptedString(string) {
  if (typeof string !== 'string' || string.length === 0) {
    return null;
  }
  const badgeFields = getBadgeFields();
  if (!badgeFields) {
    console.error(
      "Can't parse klipso badge string because of missing badge fields (FormFields where CABPosition is a number)"
    );
    return null;
  }
  const ampersandIndex = string.indexOf('&');
  const code = string.slice(0, ampersandIndex);
  const values = string.slice(ampersandIndex + 1).split('#');
  const result = { code, guid: `${code}-${new Date().getTime()}` };
  return getStringResult(result, badgeFields, values, string);
}

// For test purpose
if (ENV === 'dev') {
  global.splitUncryptedString = splitUncryptedString;
}

// eslint-disable-next-line import/prefer-default-export
export async function performScan({ actions, labels, callback }) {
  // DEV MODE
  if ((!global.isCordovaContext || global.klMockBadge === true) && ENV === 'dev') {
    const content = splitUncryptedString(getTestDataBadge());

    callback({
      result: content,
      resultFormat: 'QR_CODE',
    });
    return;
  }

  try {
    const result = await scan();
    if (!result) {
      actions.showNotification({
        message: labels.klipsoLeads.scanError,
        level: NotificationLevels.WARNING,
      });
      return;
    }

    let content;
    if (result.text && result.text.length) {
      if (['#', 'L'].includes(result.text[0])) {
        content = await uncrypt(result.text).catch((e) => {
          console.error(`Failed to uncrypt QR code: ${e}`);
        });
        if (typeof content !== 'string' || content.length === 0) {
          content = null;
        } else {
          const badgeFields = getBadgeFields();
          if (!badgeFields) {
            console.error(
              "Can't parse klipso badge string because of missing badge fields (FormFields where CABPosition is a number)"
            );
            content = null;
          } else {
            switch (result.text[0]) {
              case '#':
                content = splitUncryptedStringLeni(content, badgeFields);
                break;
              case 'L':
                if (result.text.startsWith('LG.')) {
                  content = splitUncryptedStringGoomeo(content, badgeFields);
                } else {
                  content = result.text;
                }
                break;
              default:
                content = result.text;
                break;
            }
          }
        }
      } else if (
        result.text.startsWith('https://192.168.100.100/?i=') ||
        result.text.startsWith('https://192.168.100.100?i=')
      ) {
        let code = result.text.replace('https://192.168.100.100/?i=', '');
        code = result.text.replace('https://192.168.100.100?i=', '');
        if (code.length > 32) {
          code = code.slice(32, code.length);
        }
        content = {
          code,
          guid: `${code}-${new Date().getTime()}`,
          Societe: 'CONGRESSISTE',
          Prenom: code,
        };
      } else if (result.text.split(';').length === 5 || result.text.split(';').length === 6) {
        const data = result.text.split(';') || [];
        const code = data[1];
        const guid = `${code}-${new Date().getTime()}`;
        const Nom = data[3];
        const Prenom = data[4];
        if (code) {
          content = {
            code,
            guid,
            Nom,
            Prenom,
          };
        } else {
          content = result.text;
        }
      } else if (result.text && result.text.length === 17 && result.text[7].match(/\p{L}/u)) {
        actions.klipsoLeadsSetLoadOngoing(true);
        const visitorData = await getEscVisitorData(result.text);
        const code = result.text;
        if (visitorData && visitorData.codeRetour && visitorData.codeRetour === '100') {
          content = {
            code,
            guid: `${code}-${new Date().getTime()}`,
            Prenom: visitorData.prenom,
            Nom: visitorData.nom,
            Email: visitorData.email,
          };
        } else {
          content = {
            code,
            guid: `${code}-${new Date().getTime()}`,
            Prenom: code,
          };
        }
        actions.klipsoLeadsSetLoadOngoing(false);
      } else if (
        result.text &&
        result.text.split(';').length &&
        result.text.split(';')[0] &&
        result.text.split(';')[0].trim() &&
        result.text.split(';')[0].trim().length === 9 &&
        result.text
          .split(';')[0]
          .trim()
          .match(/^[A-Z0-9]*$/gm)
      ) {
        const context = getContext();
        const eventId = context && context.event && context.event.id;
        if (
          ['6e9cb458-4dd2-42c1-930b-a85bc063bc11', '52871c40-26b7-4ed2-8242-c603189a6a84'].includes(
            eventId
          )
        ) {
          const code = result.text.split(';')[0].trim();
          content = {
            code,
            guid: `${code}-${new Date().getTime()}`,
            Prenom: code,
          };
        } else {
          content = result.text;
        }
      } else {
        content = result.text;
      }
    } else {
      content = result.text;
    }

    callback({
      result: content,
      resultFormat: result.format,
    });
  } catch (e) {
    actions.klipsoLeadsSetLoadOngoing(false);
    actions.showNotification({
      message: labels.klipsoLeads.scanDenied,
      level: NotificationLevels.WARNING,
    });
  }
}
