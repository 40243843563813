import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { USER_DATA_PAGE_KEY } from 'src/pages/pagesKeys';

import { DATA_TYPE_EVENTS, DATA_TYPE_CONTACTED_PARTICIPANTS } from 'app-customs/config/dataConfig';

import UserDataForm from './UserDataForm';
import ContactedParticipants from './ContactedParticipants';
import Ebadge from './EBadge';
import Tabs from 'src/components/tabs/Tabs';
import List from 'src/components/list/List';
import config from 'app-customs/config/config';

import { openUrl } from 'src/core/util/JsTools';
import Contacts from 'src/pages/user-data/Contacts';

import { getCurrent } from 'src/core/Lang';

// const LOG_PREF = '[UserDataPageContent] ';

export const PROFILE_TAB_KEY = 'yourProfile';
export const EVENTS_TAB_KEY = 'yourEvents';
export const MET_CONTACTS_TAB_KEY = 'metContacts';
export const CONTACTED_PARTICIPANTS_TAB_KEY = 'contactedParticipants';
export const E_BADGE_TAB_KEY = 'eBadge';
export const CONFERENCES_TAB_KEY = 'yourConferences';

class UserDataContent extends Component {
  state = {};
  tabIndexMapping = [];
  getTabKey() {
    return this.tabIndexMapping[this.props.tabIndex] || this.props.tabKey;
  }
  renderTabContent() {
    switch (this.getTabKey()) {
      case PROFILE_TAB_KEY:
        return (
          <UserDataForm
            status={this.props.formStatus}
            sections={this.props.formSections}
            userData={this.props.userData}
            tosLink={this.props.tosLink}
            externalResourceLinkOpenInInAppBrowser={
              this.props.externalResourceLinkOpenInInAppBrowser
            }
            tosLinkOpenInInAppBrowser={this.props.tosLinkOpenInInAppBrowser}
            externalResourceLinkTarget={this.props.externalResourceLinkTarget}
            logout={this.props.logout}
            keyboardHeight={this.props.keyboardHeight}
            labels={this.props.labels}
            actions={this.props.actions}
          />
        );

      case EVENTS_TAB_KEY:
        return (
          <List
            items={this.props.userData.events}
            dataType={DATA_TYPE_EVENTS}
            actions={this.props.actions}
            labels={this.props.labels}
            displayFavorites={false}
            pageKey={USER_DATA_PAGE_KEY}
          />
        );

      case CONFERENCES_TAB_KEY:
        return (
          <List
            items={this.props.userData.conferences}
            dataType={DATA_TYPE_EVENTS}
            actions={this.props.actions}
            labels={this.props.labels}
            displayFavorites={false}
            pageKey={USER_DATA_PAGE_KEY}
          />
        );

      case MET_CONTACTS_TAB_KEY:
        return (
          <Contacts
            actions={this.props.actions}
            labels={this.props.labels}
            contacts={this.props.contacts}
            searchResults={this.props.searchResults}
          />
        );

      case CONTACTED_PARTICIPANTS_TAB_KEY:
        return (
          <ContactedParticipants
            actions={this.props.actions}
            labels={this.props.labels}
            participants={this.props.userData.contactedParticipants}
            searchResults={this.props.searchResults}
            keyboardHeight={this.props.keyboardHeight}
          />
        );

      case E_BADGE_TAB_KEY:
        /*  return <Ebadge userData={this.props.userData} labels={this.props.labels} />; */
        return null;

      default:
        return null;
    }
  }

  getContactsCount = () => (Array.isArray(this.props.contacts) ? this.props.contacts.length : 0);

  getEventsCount() {
    return Array.isArray(this.props.userData.events) ? this.props.userData.events.length : 0;
  }

  getConferencesCount() {
    return Array.isArray(this.props.userData.conferences)
      ? this.props.userData.conferences.length
      : 0;
  }

  getContactedParticipantsCount() {
    return Array.isArray(this.props.userData.contactedParticipants)
      ? this.props.userData.contactedParticipants.length
      : 0;
  }

  getEbadgeLink() {
    return this.props.userData.externalResources
      ? this.props.userData.externalResources.badge
      : false;
  }

  profileTabData = {
    key: PROFILE_TAB_KEY,
    isCurrentTab: () => this.getTabKey() === PROFILE_TAB_KEY,
    getTabContent: () => this.props.labels.userData.yourProfile,
  };

  eventTabData = {
    key: EVENTS_TAB_KEY,
    isCurrentTab: () => this.getTabKey() === EVENTS_TAB_KEY,
    isClickable: () => this.getEventsCount() > 0,
    getTabContent: () => this.props.labels.userData.yourEvents,
  };

  conferenceTabData = {
    key: CONFERENCES_TAB_KEY,
    isCurrentTab: () => this.getTabKey() === CONFERENCES_TAB_KEY,
    isClickable: () => this.getConferencesCount() > 0,
    getTabContent: () => this.props.labels.userData.yourConferences,
  };

  metContactsTabData = {
    key: MET_CONTACTS_TAB_KEY,
    isCurrentTab: (key) => this.getTabKey() === MET_CONTACTS_TAB_KEY,
    isClickable: () => this.getContactsCount() > 0,
    getTabContent: () => this.props.labels.userData.metContacts,
  };

  contactedParticipantsTabData = {
    key: CONTACTED_PARTICIPANTS_TAB_KEY,
    isCurrentTab: () => this.getTabKey() === CONTACTED_PARTICIPANTS_TAB_KEY,
    isClickable: () => this.getContactedParticipantsCount() > 0,
    getTabContent: () => this.props.labels.data[DATA_TYPE_CONTACTED_PARTICIPANTS].plural,
  };

  eBadgeTabData = {
    key: E_BADGE_TAB_KEY,
    isCurrentTab: () => this.getTabKey() === E_BADGE_TAB_KEY,
    isClickable: () => this.getEbadgeLink(),
    getTabContent: () => this.props.labels.userData.badge,
  };

  onTabClick = (tabIndex) => {
    const extRes = this.props.userData.externalResources;
    const url = extRes.badge[getCurrent()] || extRes.badge;
    if (this.tabIndexMapping[tabIndex] === E_BADGE_TAB_KEY) {
      if (openUrl(url, null, config.TOU_LINK_TARGET, config.TOU_LINK_OPENININAPPBROWSER)) {
        this.props.actions.linkClicked(url);
      }
    } else {
      this.props.actions.navigate(USER_DATA_PAGE_KEY, {
        tabIndex,
      });
    }
    // this.props.actions.userDataTabIndexUpdate(tabIndex);
  };

  render() {
    let hasContactsFeature = true;
    if (config.CONTACTS.FEATURE_ENABLED !== true || !global.isCordovaContext) {
      hasContactsFeature = false;
    }

    if (Array.isArray(config.CONTACTS.RESTRICTIONS)) {
      let restriction = config.CONTACTS.RESTRICTIONS.find(
        (restr) => restr.pageKey === USER_DATA_PAGE_KEY
      );
      if (restriction) {
        // A restriction has been defined for this page
        if (restriction.for.indexOf(this.props.profile) !== -1) {
          // Restriction applies for this profile, so skip rendering share button
          hasContactsFeature = false;
        }
      }
    }

    let hasEvents = this.getEventsCount() > 0,
      hasConferences = this.getConferencesCount() > 0,
      hasContacts = this.getContactsCount() > 0 && hasContactsFeature,
      hasContactedParticipants = this.getContactedParticipantsCount() > 0,
      hasEbadge = this.getEbadgeLink();

    let tabsData = [this.profileTabData];
    this.tabIndexMapping = [PROFILE_TAB_KEY];
    if (hasEbadge) {
      tabsData.push(this.eBadgeTabData);
      this.tabIndexMapping.push(E_BADGE_TAB_KEY);
    }
    if (hasEvents) {
      tabsData.push(this.eventTabData);
      this.tabIndexMapping.push(EVENTS_TAB_KEY);
    }
    if (hasConferences) {
      tabsData.push(this.conferenceTabData);
      this.tabIndexMapping.push(CONFERENCES_TAB_KEY);
    }
    if (hasContacts) {
      if (
        config.CONTACTS.SAVE_CONTACT_TO_DEVICE.FEATURE_ENABLED === true &&
        global.isCordovaContext
      ) {
        let hasContactsFeature = true;
        if (Array.isArray(config.CONTACTS.SAVE_CONTACT_TO_DEVICE.RESTRICTIONS)) {
          const restriction = config.CONTACTS.SAVE_CONTACT_TO_DEVICE.RESTRICTIONS.find(
            (restr) => restr.pageKey === USER_DATA_PAGE_KEY
          );
          if (restriction) {
            // A restriction has been defined for this page
            if (restriction.for.indexOf(this.props.profile) !== -1) {
              // Restriction applies for this profile, so skip rendering share button
              hasContactsFeature = false;
            }
          }
        }
      }
      if (hasContactsFeature) {
        tabsData.push(this.metContactsTabData);
        this.tabIndexMapping.push(MET_CONTACTS_TAB_KEY);
      }
    }
    if (hasContactedParticipants) {
      tabsData.push(this.contactedParticipantsTabData);
      this.tabIndexMapping.push(CONTACTED_PARTICIPANTS_TAB_KEY);
    }

    let multipleTabs =
      hasEvents || hasConferences || hasContacts || hasContactedParticipants || hasEbadge;

    return (
      <div id="ud-content-container" className="content-below-apptoolbar">
        {multipleTabs && (
          <Tabs
            currentIndex={
              this.props.tabIndex !== undefined
                ? this.props.tabIndex
                : this.tabIndexMapping.indexOf(this.props.tabKey)
            }
            onTabIndexChange={this.onTabClick}
            data={tabsData}
          />
        )}
        {this.renderTabContent()}
      </div>
    );
  }
}

UserDataContent.propTypes = {
  userData: PropTypes.object,
  tabIndex: PropTypes.number,

  contacts: PropTypes.array,
  contactedParticipants: PropTypes.array,
  searchResults: PropTypes.object,

  formStatus: PropTypes.object,
  formSections: PropTypes.array.isRequired,

  tosLink: PropTypes.string,
  logout: PropTypes.func.isRequired,
  keyboardHeight: PropTypes.number,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  profile: PropTypes.string.isRequired,
};

export default UserDataContent;
