import React from 'react';
import PropTypes from 'prop-types';

class YoutubeVideo extends React.PureComponent {
  render() {
    const { id } = this.props;
    const videoSrc = `https://www.youtube.com/embed/${id}?rel=0&modestbranding=1`;

    return (
      <div className="media-youtube-post">
        <iframe
          title="id"
          className="player"
          type="text/html"
          width="100%"
          height="100%"
          style={{
            maxWidth: '500px',
            height: 'auto',
            minHeight: '380px',
          }}
          src={videoSrc}
          frameBorder="0"
        />
      </div>
    );
  }
}

YoutubeVideo.propTypes = {
  id: PropTypes.string.isRequired,
};

export default YoutubeVideo;
