import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import config from 'app-customs/config/config';
import projectConfiguration from '../../../../legacy-app-controller/config.js';
import { formatDateTime } from 'src/core/Lang';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';
import {
  hasWritePermission,
  requestWritePermission,
  onCreateCalendarEventWithOptions,
} from 'src/core/calendar/calendar';
import { isAndroid } from 'src/core/util/browser';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

import './ExportEventButton.scss';

const LOG_PREF = '[ExportEvent] ';

class ExportEventButton extends PureComponent {
  /**
   * request permission to create event in the calendar of the user
   */
  async requestWritePermissionEvent() {
    const { actions, labels } = this.props;
    console.info(`${LOG_PREF} requestWritePermissionEvent`);
    var _this = this;
    await requestWritePermission(
      function requestSuccess(request) {
        console.info(`${LOG_PREF} request Write PermissionEvent Success`, request);
        _this.onCheckEventButtonClick();
      },
      function requestFail(error) {
        console.error(`${LOG_PREF} request Write PermissionEvent Fail`, error);
        actions.showNotification({
          message: labels.exportEvent.requestFail,
          level: NotificationLevels.SUCCESS,
        });
      }
    );
  }

  /**
   * create an event
   */
  createEvent() {
    const { id, title, start_date, end_time, start_time, location, queryString, actions, labels } =
      this.props;
    var _this = this;
    onCreateCalendarEventWithOptions(
      id,
      title,
      location,
      '',
      formatDateTime(start_date, start_time),
      formatDateTime(start_date, end_time),
      { url: queryString ? 'https:/' + projectConfiguration.web.url + queryString : '' },
      actions,
      labels,
      NotificationLevels
    );
  }

  /**
   * When main button is clicked, create an event in the default calendar choosing by the user
   */
  onExportEventButtonClick = () => {
    var _this = this;
    hasWritePermission(
      function hasWritePermissionSuccess(permission) {
        console.info(`${LOG_PREF} has Write Permission on first check`, permission);
        if (permission || isAndroid()) {
          _this.createEvent();
        } else {
          _this.requestWritePermissionEvent();
        }
      },
      function hasWritePermissionFail(error) {
        console.error(`${LOG_PREF} has Write Permission Fail on first check`, error);
      }
    );
  };

  /**
   * When request is fired, create an event in the default calendar choosing by the user
   */
  onCheckEventButtonClick() {
    var _this = this;
    hasWritePermission(
      function hasWritePermissionSuccess(permission) {
        console.info(`${LOG_PREF} has Write Permission on second check`, permission);
        if (permission) {
          _this.createEvent();
        }
      },
      function hasWritePermissionFail(error) {
        console.error(`${LOG_PREF} has Write Permission Fail on second check`, error);
      }
    );
  }

  render() {
    if (config.EXPORT_EVENT.FEATURE_ENABLED !== true) {
      return null;
    }

    return (
      <GenericIcon
        layout="div"
        className="export-event-btn calendar-icon"
        title={this.props.labels.common.exportEvent}
        onClick={this.onExportEventButtonClick}
      />
    );
  }
}

ExportEventButton.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string.isRequired,
  queryString: PropTypes.string.isRequired,
  start_time: PropTypes.string,
  start_date: PropTypes.number.isRequired,
  end_time: PropTypes.string,
  location: PropTypes.string,

  // common props
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default ExportEventButton;
