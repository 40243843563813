import { LANG_CHANGED, SHOW_FULL_LOADER, HIDE_FULL_LOADER } from 'src/store/actionTypes';

import { get as getLabels } from 'src/core/Lang';

import { langChanged, updateObject } from 'src/store/reducers/commons';

const THEMES = {
  DARK: 'dark',
  LIGHT: 'light',
};

// const getTheme = pageKey => (
//     !pageKey || [ CHOOSE_PROFILE_PAGE_KEY, HOME_PAGE_KEY ].indexOf(pageKey) !== -1
//         ? THEMES.DARK
//         : THEMES.LIGHT
// );

const getDefaultState = () => ({
  labels: getLabels(),
  isDisplayed: false,
  theme: THEMES.LIGHT,
});

const _toggleLoader = (state, action, value) => {
  return updateObject(state, { isDisplayed: value });
};

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case LANG_CHANGED:
      return langChanged(state, action);
    case SHOW_FULL_LOADER:
      return _toggleLoader(state, action, true);
    case HIDE_FULL_LOADER:
      return _toggleLoader(state, action, false);

    default:
      return state;
  }
};
