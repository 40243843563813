import * as localforage from 'localforage';

import { getCurrent as getCurrentLang } from 'src/core/Lang';
import { get3CharsLang } from './utils';

const STORAGE_TYPE_LABEL = 'localforage';

const LOG_PREF = '[DbPersistence] ';

const DB_KEY_PREFIX = 'db_';
const getDbKey = () => DB_KEY_PREFIX + get3CharsLang(getCurrentLang());

function _logError(msg, err) {
  console.error(LOG_PREF + msg, err.name, err.message, err);
}

/**
 * Set/Update persisted data
 * @param {object}   value
 * @param {Function} next
 */
export function set(value, next) {
  localforage
    .setItem(getDbKey(), value)
    .then(function dbPersistenceSetSuccessHandler() {
      next(null);
    })
    .catch(function dbPersistenceSetErrorHandler(err) {
      _logError(`Was unable to persist data to ${STORAGE_TYPE_LABEL}`, err);
      next(err);
    });
}

/**
 * Retrieve persisted data
 * @param  {Function} next
 */
export function get(next) {
  localforage
    .getItem(getDbKey())
    .then(function dbPersistenceGetSuccessHandler(data) {
      next(null, data);
    })
    .catch(function dbPersistenceSetErrorHandler(err) {
      _logError(`Was unable to read data from ${STORAGE_TYPE_LABEL}`, err);
      next(err);
    });
}

/**
 * Remove persisted data (remove all entries whose key starts with `DB_KEY_PREFIX` value)
 * @param {Function} next
 */
export function clear(next) {
  // recursive
  const removeEntries = (keys) => {
    if (keys.length === 0) {
      next();
      return;
    }
    const keyToDelete = keys[0];

    localforage
      .removeItem(keyToDelete)
      .then(function dbPersistenceRemoveSuccessHandler() {
        console.log(`${LOG_PREF}Removed ${keyToDelete} from ${STORAGE_TYPE_LABEL}`);
        removeEntries(keys.slice(1));
      })
      .catch(function dbPersistenceRemoveErrorHandler(err) {
        _logError(`Was unable to remove ${keyToDelete} from ${STORAGE_TYPE_LABEL}`, err);
        next(err);
      });
  };

  localforage
    .keys()
    .then(function dbPersistenceIterateSuccessHandler(keys) {
      const keysToRemove = keys.filter((key) => {
        return key.startsWith(DB_KEY_PREFIX);
      });
      removeEntries(keysToRemove);
    })
    .catch(function dbPersistenceIterateErrorHandler(err) {
      _logError(`Was unable to list entries from ${STORAGE_TYPE_LABEL}`, err);
      next(err);
    });
}
