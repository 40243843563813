// conf
import {
  USE_TRANSITION,
  TRANSITION_DURATION as DURATION,
  TYPE,
  getStartStyle,
  getEndStyle,
  getResetStyle,
} from 'app-customs/config/transitionConfig';

// app modules
import Pages from 'src/pages/Pages';
import { getKeysOfPagesUsingGenericItem } from 'src/pages/generic-item-page/helpers';
import { applyStyle } from 'src/core/util/DomTools';
import { queue, performQueuedActions } from './TransitionQueuedActions';

const LOG_PREF = '[PageTransition] ';

const applyBeforeStyle = (el) => {
  el.style.transition = `left ${DURATION / 1000}s ease-out, top ${DURATION /
    1000}s ease-out, opacity ${DURATION / 1000}s ease-out`;
  el.style.willChange = 'transform';
  el.style.zIndex = 1;
};

const applyAfterStyle = (el) => {
  el.style.transition = 'none';
  el.style.willChange = 'auto';
  el.style.zIndex = 0;
};

let transitionActive = false;
const isTransitionRunning = () => transitionActive;

export { isTransitionRunning };

/**
 * Visually perform navigation (show new page with or without transition, hide previous page...)
 * @param  {string}   pageKey          page to display
 * @param  {string}   previousPageKey  currently displayed page
 * @param  {function} callback         (optional)
 * @param  {string}   type             see transitionConfig/TYPE
 */
export const perform = (pageKey, previousPageKey, callback, type) => {
  // Check arguments
  if (!pageKey) {
    console.error(`${LOG_PREF}Missing transition argument \`pageKey\``);
    return;
  }
  if (!Pages[pageKey]) {
    console.error(`${LOG_PREF}Invalid transition argument \`pageKey\``, pageKey);
    return;
  }
  if (previousPageKey && !Pages[previousPageKey]) {
    console.error(`${LOG_PREF}Invalid transition argument \`previousPageKey\``, previousPageKey);
    return;
  }
  // if (typeof TYPE[type] === 'undefined') {
  //     console.error(LOG_PREF + 'Invalid transition argument `type`', type);
  //     return;
  // }

  if (
    previousPageKey &&
    getKeysOfPagesUsingGenericItem().indexOf(previousPageKey) !== -1 &&
    getKeysOfPagesUsingGenericItem().indexOf(pageKey) !== -1
  ) {
    // sort-of-a-hack: Skip transition for GenericItemPage because the same component mounted on the same DOM node is used
    return;
  }

  // Proceed
  const pageEl = Pages[pageKey].getElement();

  // Without transitions
  if (!USE_TRANSITION || !type) {
    requestAnimationFrame(() => {
      // Set style on new page
      applyStyle(pageEl, getStartStyle(type || TYPE.forward, Pages[pageKey].toggleMode));
      applyStyle(pageEl, getEndStyle(Pages[pageKey].toggleMode));

      // Set style for previous page
      if (previousPageKey) {
        applyStyle(
          Pages[previousPageKey].getElement(),
          getResetStyle(Pages[previousPageKey].toggleMode)
        );
      }
    });

    // With transition
  } else {
    requestAnimationFrame(() => {
      // Set start properties
      applyAfterStyle(pageEl);
      applyStyle(pageEl, getStartStyle(type, Pages[pageKey].toggleMode));

      requestAnimationFrame(() => {
        // Set destination properties
        applyBeforeStyle(pageEl);
        transitionActive = true;
        applyStyle(pageEl, getEndStyle(Pages[pageKey].toggleMode));

        // When translation is done:
        setTimeout(() => {
          requestAnimationFrame(() => {
            if (previousPageKey) {
              applyStyle(
                Pages[previousPageKey].getElement(),
                getResetStyle(Pages[previousPageKey].toggleMode)
              );
            }
            // remove transition property on current page
            applyAfterStyle(pageEl);
            transitionActive = false;

            if (typeof callback === 'function') {
              callback();
            }

            // If actions have been queued while running the transition, execute them
            requestAnimationFrame(performQueuedActions);
          });
        }, DURATION);
      });
    });
  }
};

/**
 * If a transition is running, then the callback argument will be executed once transition has completed.
 * If no transition is running, then the callback argument is executed immediately.
 * @param {Function} callback
 */
export const performActionWhenNoTransition = (callback) => {
  if (!callback) {
    console.error(`${LOG_PREF}Missing argument \`callback\``);
    return;
  }
  if (typeof callback !== 'function') {
    console.error(`${LOG_PREF}Invalid argument \`callback\``, callback);
    return;
  }

  if (isTransitionRunning()) {
    queue(callback);
  } else {
    // Execute immediately if no transition is running
    callback();
  }
};
