// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from 'src/components/dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

// app modules
import { MESSAGE_DISMISSED, MESSAGE_ACTIONED } from 'src/pages/inbox/inboxReducer';

import './InboxAlert.scss';

export const COMPONENT_KEY = 'InboxAlert';
export const CONTAINER_DOM_ID = 'inbox-alert-container';
function InboxAlert(props) {

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onCancel} // important, to update `open` value in all case
    >
      {props.message && props.message.title && (
        <DialogTitle className="title-font modal-title">{props.message.title}</DialogTitle>
      )}
      <div className="generic-modal-content content-font notif-dialog">
        <p>{props.message ? props.message.content : null}</p>

        <div className="generic-btn-container">
          <div className="generic-btn-cancel" onClick={props.onCancel}>
            {props.labels.inbox.dismiss}
          </div>
          <div className="generic-btn cta-modal-btn" onClick={props.onAccept}>
            {props.labels.inbox.confirmNotif}
          </div>
        </div>
      </div>
    </Dialog>
  );
}

InboxAlert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  cancelable: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { notif } = state.Inbox;
  let message = null;
  if (notif !== null) {
    message = state.Inbox.byDate.filter((message) => message.id === notif)[0];
  }

  return {
    isOpen: state.Inbox.notif !== null && typeof message === 'object' && message !== null,
    cancelable: true,
    message,
    labels: state.Inbox.labels,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCancel: () =>
      dispatch({
        type: MESSAGE_DISMISSED,
      }),
    onAccept: () =>
      dispatch({
        type: MESSAGE_ACTIONED,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InboxAlert);
