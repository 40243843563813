module.exports = {
  basicDisplay: false,
  default: {
    instagram: {
      // link: 'https://www.instagram.com/sommetelevage/?hl=fr',
      socialId: '105681381984292',
      logo: '/files/project/socialmedia/Instagram.svg',
      color: '#f39200'
    },
    facebook2: {
      // link: 'https://fr-fr.facebook.com/SommetElevage/',
      // socialId: '105681381984292',
      src: 'https://www.facebook.com/MobileSpot-Master-integration-105681381984292',
      logo: '/files/project/socialmedia/Facebook.svg',
      color: '#f39200'
    },
    facebook: {
      // link: 'https://fr-fr.facebook.com/SommetElevage/',
      socialId: '105681381984292',
      // src: 'https://www.facebook.com/MCB.MondialCoiffureBeaute',
      logo: '/files/project/socialmedia/Facebook.svg',
      color: '#f39200'
    },
    twitter: {
      socialId: '239333557',
      // link: 'https://twitter.com/SommetElevage',
      logo: '/files/project/socialmedia/Twitter.svg',
      color: '#f39200'
    },
    youtube: {
      // link: 'https://www.youtube.com/channel/UCyR5ge03rZJD93hNXsegJSA',
      socialId: 'UC8H7P_DMBIPryxpVWc4zVaw',
      // 'UCdQucFEzDrpLfA2oVR-pNew',
      logo: '/files/project/socialmedia/Youtube.svg',
      color: '#f39200'
    },
    linkedin: {
      link: 'https://fr.linkedin.com/company/sommet-de-l-elevage',
      logo: '/files/project/socialmedia/Linkedin.svg',
      color: '#f39200',
      target: '_self'
    },
    tiktok: {
      link: 'https://www.tiktok.com/@sommetelevage?',
      logo: '/files/project/socialmedia/Tiktok.svg',
      color: '#f39200'
    }
  }
};