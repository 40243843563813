import config from 'app-customs/config/config';

const LOCALSTORAGE_MODE_SYNC_KEY = 'modeSync';

/**
 * MODE SYNC FAV getter
 * @return {string}
 */
export function getModeSynchFav() {
  const data = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_MODE_SYNC_KEY));

  return data || config.SYNCHRONIZED_FAVORITES.MODE;
}

/**
 * MODE SYNC FAV setter
 * @param {object}
 */
export function setModeSynchFav(value) {
  window.localStorage.setItem(LOCALSTORAGE_MODE_SYNC_KEY, JSON.stringify(value));
}
