import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  DATA_TYPE_AIRCRAFTS,
  DATA_TYPE_EVENTS,
  DATA_TYPE_EXHIBITOR_CATEGORIES,
  DATA_TYPE_EXHIBITORS,
  DATA_TYPE_GMAP_PLACES,
  DATA_TYPE_PARTICIPANTS,
  DATA_TYPE_SIDE_EVENTS,
} from 'app-customs/config/dataConfig';

import config from 'app-customs/config/config';

import { PRESS_PROFILE } from 'app-customs/config/profiles';

import { EXHIBITOR_PAGE_KEY, LIST_PAGE_KEY, DOCUNIT_PAGE_KEY } from 'src/pages/pagesKeys';

import DetailCountry from 'src/components/detail-country/DetailCountry';
// import DetailAircrafts    from 'src/components/detail-aircrafts/DetailAircrafts';
import DetailDataList from 'src/components/detail-data-list/DetailDataList';
// import DetailEvents       from 'src/components/detail-events/DetailEvents';
import DetailFixedTitle from 'src/components/detail-fixed-title/DetailFixedTitle';
import DetailNewProducts from 'src/components/detail-newproducts/DetailNewProducts';
import DetailJobOffers from 'src/components/detail-joboffers/DetailJobOffers';
import DetailPlaces from 'src/components/detail-places/DetailPlaces';
import DetailSocial from 'src/components/detail-social/DetailSocial';
import Description from 'src/components/fiche/Description';
import PhoneRow from 'src/components/fiche/PhoneRow';
import EmailRow from 'src/components/fiche/EmailRow';
import WebsiteRow from 'src/components/fiche/WebsiteRow';
import FullNameRow from 'src/components/fiche/FullNameRow';
// import LinkButton         from 'src/components/link-button/LinkButton';
import ShareButton from 'src/components/share-button/ShareButton';
import NoteButton from 'src/components/notes/NoteButton';

import Url from 'src/components/url/Url';
import CTAButton from 'src/components/cta-button/CTAButton';
import STATUS from 'src/store/fetchStatuses';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

import '../common-fiche.scss';
import './ExhibitorPage.scss';

class ExhibitorContent extends PureComponent {
  setScrollableContentEl = (el) => {
    this.contentContainerEl = el;
  };
  scrollTo = (value) => {
    if (this.contentContainerEl) {
      this.contentContainerEl.scrollTop = value;
    }
  };

  onClickOnFavIcon = () => {
    this.props.actions.toggleFavorite(
      this.props.item.id,
      DATA_TYPE_EXHIBITORS,
      this.props.isFavorite
    );
  };

  showEvents = () => {
    this.props.actions.navigateToItems(
      this.props.item.references[DATA_TYPE_EVENTS],
      DATA_TYPE_EVENTS,
      this.props.item.id,
      DATA_TYPE_EXHIBITORS
    );
  };

  showAircrafts = () => {
    this.props.actions.navigateToItems(
      this.props.item.references[DATA_TYPE_AIRCRAFTS],
      DATA_TYPE_AIRCRAFTS,
      this.props.item.id,
      DATA_TYPE_EXHIBITORS
    );
  };

  onClickOnCategory = (cat) => {
    if (Array.isArray(cat.exhibitors) && cat.exhibitors.length > 0) {
      // Display content
      this.props.actions.navigate(LIST_PAGE_KEY, {
        inputs: [
          {
            parentId: cat.id,
            parentType: DATA_TYPE_EXHIBITOR_CATEGORIES,
            dataType: DATA_TYPE_EXHIBITORS,
          },
        ],
      });
    } else {
      // Display sub-categories
      this.props.actions.navigate(LIST_PAGE_KEY, {
        inputs: [
          {
            id: cat.id,
            dataType: DATA_TYPE_EXHIBITOR_CATEGORIES,
          },
        ],
      });
    }
  };

  sendAppointmentRequest = () => {
    this.props.actions.sendAppointmentRequest(
      this.props.item.id,
      DATA_TYPE_EXHIBITORS,
      this.props.item.original_id
    );
  };

  renderCategory = (cat, level) => (
    <Fragment key={cat.id}>
      <div
        onClick={() => {
          this.onClickOnCategory(cat);
        }}
        className={`activities-content colored-link cat-level-${level}`}
      >
        <span>{cat.title}</span>
        <span className="list-el-counter">
          <span>{cat.counter}</span>
        </span>

        <div className="prop-right-fleche">
          <GenericIcon
            st0Class="chevron-right-icon-st0"
            src="icons-svg/control/chevron-droit-solid.svg"
            className="chevron-right-icon fleche"
          />
        </div>
      </div>

      {Array.isArray(cat.cats) && cat.cats?.map((subCat) => this.renderCategory(subCat, level + 1))}
    </Fragment>
  );

  render() {
    let lump = this.props.item.lump;
    const title = this.props.item.title,
      logoUrl = this.props.item.logo_file_name,
      description =
        global.isCordovaContext && this.props.item.description_alt
          ? this.props.item.description_alt
          : this.props.item.description,
      //description = this.props.item.description,
      references = this.props.item.references,
      hasAddress =
        this.props.item.address ||
        this.props.item.postal_code ||
        this.props.item.city ||
        this.props.item.references.country,
      hasSocial = lump.social && (lump.social.tw || lump.social.fb || lump.social.ln || lump.social.pt || lump.social.ig || lump.social.yt || lump.social.tk || lump.social.wb),
      hasDocunits =
        lump.docunits &&
        lump.docunits.length > 0 &&
        references.docunits &&
        references.docunits.length > 0 &&
        this.props.profile === PRESS_PROFILE,
      catHierarchy =
        this.props.item.references && this.props.item.references.catHierarchy
          ? this.props.item.references.catHierarchy
          : null,
      /* categories =
        this.props.item.references && this.props.item.references.categories
          ? this.props.item.references.categories
          : null, */
      hasContactsBlock =
        (lump.contacts && lump.contacts.length > 0) ||
        this.props.item.phone ||
        this.props.item.email ||
        this.props.item.website ||
        hasAddress ||
        hasSocial ||
        (this.props.appointmentRequestStatus.isEnabled && lump.networking),
      participantsData = this.props.item.references
        ? this.props.item.references[DATA_TYPE_PARTICIPANTS]
        : null;

    return (
      <div className="fiche exhibitor content-font content-below-apptoolbar">
        {/* title */}
        <DetailFixedTitle
          image={lump && lump.focus !== undefined ? lump.focus : null}
          image2={lump && lump.focus2 !== undefined ? lump.focus2 : null}
          image3={lump && lump.focus3 !== undefined ? lump.focus3 : null}
          title={title}
          dataType={DATA_TYPE_EXHIBITORS}
          isFav={this.props.isFavorite}
          onFavClick={this.onClickOnFavIcon}
          labels={this.props.labels}
        >
          <NoteButton
            itemTitle={this.props.item.title}
            itemId={this.props.item.id}
            dataType={DATA_TYPE_EXHIBITORS}
            hasNote={this.props.hasNote}
            labels={this.props.labels}
            actions={this.props.actions}
          />
          <ShareButton
            name={this.props.pageTitle}
            queryString={this.props.queryString}
            description={description}
            image={logoUrl}
            labels={this.props.labels}
            actions={this.props.actions}
            profile={this.props.profile}
            pageKey={EXHIBITOR_PAGE_KEY}
          />
        </DetailFixedTitle>

        <div className="content" ref={this.setScrollableContentEl}>
          <div className="all-informations content-font">
            <Description
              logoFileName={logoUrl}
              dataType={DATA_TYPE_EXHIBITORS}
              description={description}
              isLogoFullWidth={this.props.isLogoFullWidth}
            />
            {/* Counters */}
            <div>
              {config.MAP &&
                config.MAP.GOOGLE_MAP?.FEATURE_ENABLED &&
                this.props.item.references.gmapplaces &&
                this.props.item.references.gmapplaces.length !== 0 && (
                  /* gmapplaces */
                  <DetailPlaces
                    labels={this.props.labels}
                    actions={this.props.actions}
                    id={this.props.item.id}
                    originalId={this.props.item.original_id}
                    entityType={DATA_TYPE_GMAP_PLACES}
                    places={this.props.item.references.gmapplaces}
                    defaultLabel={this.props.item.event_place_text}
                  />
                )}
              {config.MAP && config.MAP.MOBIGEO.FEATURE_ENABLED && (
                /* places */
                <DetailPlaces
                  labels={this.props.labels}
                  actions={this.props.actions}
                  id={this.props.item.id}
                  originalId={this.props.item.original_id}
                  entityType={DATA_TYPE_EXHIBITORS}
                  places={this.props.item.references.places}
                  textPlaces={this.props.item.exhibitor_place_text}
                />
              )}
            </div>
            {/* newproducts */}
            <DetailNewProducts
              items={this.props.item.references.newproducts}
              actions={this.props.actions}
              labels={this.props.labels}
              pageKey={EXHIBITOR_PAGE_KEY}
            />
            {/* joboffers */}
            <DetailJobOffers
              items={this.props.item.references.joboffers}
              actions={this.props.actions}
              labels={this.props.labels}
              pageKey={EXHIBITOR_PAGE_KEY}
            />
            {/* events */}
            <DetailDataList
              dataType={DATA_TYPE_EVENTS}
              items={this.props.item.references.events}
              actions={this.props.actions}
              labels={this.props.labels}
              pageKey={EXHIBITOR_PAGE_KEY}
            />
            {/* side events */}
            <DetailDataList
              dataType={DATA_TYPE_SIDE_EVENTS}
              items={this.props.item.references.sideevents}
              actions={this.props.actions}
              labels={this.props.labels}
              pageKey={EXHIBITOR_PAGE_KEY}
            />
            {/* aircrafts */}
            <DetailDataList
              dataType={DATA_TYPE_AIRCRAFTS}
              items={this.props.item.references.aircrafts}
              actions={this.props.actions}
              labels={this.props.labels}
              pageKey={EXHIBITOR_PAGE_KEY}
            />
            {/* contacts */}
            {hasContactsBlock && (
              <div>
                <div className="fiche-separator">{this.props.labels.exhibitor.contacts}</div>

                {/*   Appointment request - SEEMS LIKE DEAD CODE (networking v1) */}
                {lump.networking &&
                  this.props.appointmentRequestStatus.isEnabled &&
                  !this.props.appointmentRequestStatus.date && (
                    <CTAButton
                      isEnabled={this.props.appointmentRequestStatus.ongoing !== true}
                      label={this.props.labels.appointment.button}
                      action={this.sendAppointmentRequest}
                    />
                  )}
                {lump.networking &&
                  this.props.appointmentRequestStatus.isEnabled &&
                  this.props.appointmentRequestStatus.date && (
                    <div className="appointment-request-sent">
                      {this.props.labels.appointment.sent(
                        formatDateAndTime(this.props.appointmentRequestStatus.date),
                        this.props.appointmentRequestStatus.userEmail,
                        this.props.appointmentRequestStatus.userPhone
                      )}
                    </div>
                  )}

                <DetailSocial data={lump.social} actions={this.props.actions} />

                {lump.contacts && lump.contacts.length > 0 && (
                  <div className="free-row">
                    <div className="prop-img">
                      <div className="prop-left">
                        <div className="fiche-contact-icon">
                          <GenericIcon className="user-icon  user-fiche-icon" />
                        </div>
                      </div>
                      <div className="prop-right">
                        {lump.contacts.map((entry, index) => (
                          <div key={index}>
                            <span
                              className="contactName"
                              dangerouslySetInnerHTML={{ __html: entry.n }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

                <FullNameRow
                  fullName={this.props.item.contact_name}
                  civility={this.props.item.civility}
                  lastname={this.props.item.lastname}
                  firstname={this.props.item.firstname}
                />

                <PhoneRow phone={this.props.item.phone} />

                <WebsiteRow website={this.props.item.website} actions={this.props.actions} />

                <EmailRow email={this.props.item.email} />

                {/* address */}
                {hasAddress && (
                  <div className="free-row">
                    <div className="prop-img">
                      <div className="prop-left">
                        <div className="fiche-contact-icon">
                          <GenericIcon
                            src={'icons-svg/fiche/university-solid.svg'}
                            className="university-icon fiche-icon"
                          />
                        </div>
                      </div>
                      <div className="prop-right">
                        {this.props.item.address && (
                          <div dangerouslySetInnerHTML={{ __html: this.props.item.address }} />
                        )}
                        {this.props.item.postal_code && (
                          <div dangerouslySetInnerHTML={{ __html: this.props.item.postal_code }} />
                        )}
                        {this.props.item.city && (
                          <div dangerouslySetInnerHTML={{ __html: this.props.item.city }} />
                        )}
                        {this.props.item.references.country && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.props.item.references.country.title,
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {/* end contacts */}
            {/* Related contacts (using Calypso) */}
            {config.NETWORKING.FEATURE_ENABLED && participantsData && (
              <DetailDataList
                dataType={DATA_TYPE_PARTICIPANTS}
                items={participantsData.items || []}
                isPending={participantsData.status === STATUS.PENDING}
                actions={this.props.actions}
                label={this.props.labels.exhibitor.relatedContacts}
                labels={this.props.labels}
                pageKey={EXHIBITOR_PAGE_KEY}
              />
            )}
            {/* entities */}
            {lump.entities && lump.entities.length > 0 && (
              <div>
                <div className="fiche-separator">{this.props.labels.exhibitor.entities}</div>
                <div>
                  <div className="prop-img">
                    <div className="prop-left">
                      <div className="fiche-contact-icon">
                        <GenericIcon
                          src={'icons-svg/fiche/university-solid.svg'}
                          className="university-icon fiche-icon"
                        />
                      </div>
                    </div>
                    <div className="prop-right">
                      {lump.entities.map((entry, index) => (
                        <div key={index} className="entites-content">
                          {entry.title && <div className="addressName">{entry.title}</div>}
                          {entry.type && <div className="typeName">{entry.type}</div>}
                          {entry.address && <div className="addressName">{entry.address}</div>}
                          {entry.phone && (
                            <div className="phoneName">
                              <a className="link colored-link" href={'tel:' + entry.phone}>
                                {entry.phone}
                              </a>
                            </div>
                          )}
                          {entry.email && (
                            <div className="emailName">
                              <a className="link colored-link" href={'mailto:' + entry.email}>
                                {entry.email}
                              </a>
                            </div>
                          )}
                          {entry.website && (
                            <div className="websiteName">
                              <Url
                                callback={this.props.actions.linkClicked}
                                href={entry.website}
                                label={entry.website}
                              />
                            </div>
                          )}
                          <div className="separatorEnt"></div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* <div className="fiche-separator">{this.props.labels.exhibitor.agentCountries}</div> */}
            <DetailCountry country={lump.agent_countries} />
            {/* brands */}
            {this.props.item.brands && (
              <div>
                <div className="fiche-separator">{this.props.labels.exhibitor.brands}</div>
                <div className="free-row">
                  <div className="prop-img">
                    <div className="prop-left">
                      <div className="fiche-contact-icon">
                        <GenericIcon className="file-icon fiche-icon" />
                      </div>
                    </div>
                    <div className="prop-right">
                      <span>{this.props.item.brands}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* Categories */}
            {Array.isArray(catHierarchy) &&
              catHierarchy.map((catSys) => (
                <div key={catSys.id}>
                  <div className="fiche-separator">{catSys.title}</div>
                  {catSys.cats && (
                    <div className="activity free-row">
                      <div className="prop-img">
                        <div className="prop-left">
                          <div className="fiche-contact-icon">
                            <GenericIcon className="tag-icon fiche-icon" />
                          </div>
                        </div>
                        <div className="prop-right activites flex-ellipsis">
                          {catSys.cats.map((cat) => this.renderCategory(cat, 2))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}

            {/* categories */}
            {/* {categories && categories?.length > 0 && (
              <div>
                <div className="fiche-separator">{this.props.labels.exhibitor.categories}</div>
                <div className="activity free-row">
                  <div className="prop-img">
                    <div className="prop-left">
                      <div className="fiche-contact-icon">
                        <GenericIcon className="tag-icon fiche-icon" />
                      </div>
                    </div>
                    <div className="prop-right cats flex-ellipsis">
                      {categories?.map((exhibitorCat) => (
                        <div
                          key={exhibitorCat?.id}
                          data-cat-id={exhibitorCat?.id}
                          onClick={() => {
                            this.onClickOnCategory(exhibitorCat);
                          }}
                          className="activities-content colored-link"
                        >
                          <span>{exhibitorCat?.title}</span>

                          <div className="prop-right-fleche">
                            <GenericIcon
                              st0Class="chevron-right-icon-st0"
                              src="icons-svg/control/chevron-droit-solid.svg"
                              className="chevron-right-icon fleche"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )} */}
            {/* Documents */}
            {hasDocunits && (
              <div>
                <div className="fiche-separator">{this.props.labels.docunit.presskit}</div>
                {references.docunits.map((docunit) => (
                  <div key={docunit.id} className="activity free-row">
                    <div className="prop-img">
                      <div className="prop-left">
                        <div className="fiche-contact-icon">
                          <GenericIcon className="newspaper-icon fiche-icon" />
                        </div>
                      </div>
                      <div className="prop-right cats flex-ellipsis">
                        <div
                          onClick={() => {
                            this.props.actions.navigate(DOCUNIT_PAGE_KEY, { id: docunit.id });
                          }}
                          className="activities-content colored-link"
                        >
                          <span>{docunit.title}</span>
                          <div className="prop-right-fleche">
                            <GenericIcon
                              st0Class="chevron-right-icon-st0"
                              src="icons-svg/control/chevron-droit-solid.svg"
                              className="chevron-right-icon fleche"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          {/* end of .allInformations */}
        </div>
      </div>
    );
  }
}

ExhibitorContent.propTypes = {
  item: PropTypes.object,
  isFavorite: PropTypes.bool,
  appointmentRequestStatus: PropTypes.object.isRequired,
  isLogoFullWidth: PropTypes.bool,
  // Common
  queryString: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default ExhibitorContent;
