import { isIOS } from 'src/core/util/browser';

const LOG_PREF = '[DomTools] ';

export const getOrientation = () => {
  if (global.isCordovaContext && isIOS()) {
    return document.documentElement.clientHeight > document.documentElement.clientWidth
      ? 'portrait'
      : 'landscape';
  }
  return window.innerHeight > window.innerWidth ? 'portrait' : 'landscape';
};

const cssSnakeCaseStylePattern = /-./;

/**
 * Camelize a css property name.
 * ex: -webkit-transform-origin -> webkitTransformOrigin
 * ex: background-color -> backgroundColor
 * @param  {string} str
 * @return {string}
 */
function camelizeCssPropertyName(str) {
  let match;
  let to;
  do {
    match = cssSnakeCaseStylePattern.exec(str);
    if (match && match.length) {
      // Camelize only if not at the beginning of the property (ex: -webkit...)
      to = match[0].charAt(1);
      if (match.index > 0) {
        to = to.toUpperCase();
      }
      str = str.replace(match[0], to);
    } else {
      break;
    }
  } while (true);
  return str;
}

/**
 * Apply a bunch of style properties to a DOM element
 * @param {DOM element} element
 * @param {object} style
 * @param {DOM element} the updated element
 */
export function applyStyle(element, style) {
  if (!element) {
    console.error(`${LOG_PREF}Missing argument \`element\``);
    return;
  }
  if (!style) {
    console.error(`${LOG_PREF}Missing argument \`style\``);
    return;
  }
  Object.keys(style).forEach((key) => {
    // Apply property
    element.style[camelizeCssPropertyName(key)] = style[key];
  });
  return element;
}

/**
 * @param  {object}  obj
 * @return {boolean}
 */
export function isDOMElement(obj) {
  return (
    obj !== null &&
    typeof obj === 'object' &&
    obj.nodeType === 1 &&
    typeof obj.style === 'object' &&
    typeof obj.ownerDocument === 'object'
  );
}

/**
 * Create an empty <style> element and add it to the DOM
 * @param  {DOM element} parent
 * @param  {object} attrs (optional)
 * @return {CSSStyleSheet}
 */
export function createStyleSheet(parent, attrs) {
  if (isDOMElement(parent) !== true) {
    console.error('Invalid parent', parent);
    return;
  }

  const styleEl = document.createElement('style');

  // Set attributes
  if (attrs) {
    if (typeof attrs === 'object') {
      for (const attrName in attrs) {
        if (attrs.hasOwnProperty(attrName) !== true) {
          continue;
        }
        styleEl.setAttribute(attrName, attrs[attrName]);
      }
    } else {
      console.error('Unexpected attributes type ', attrs);
    }
  }

  // WebKit hack
  styleEl.appendChild(document.createTextNode(''));

  // Add to DOM
  parent.appendChild(styleEl);

  return styleEl.sheet;
}

/**
 * Dynamically add a CSS rule
 * @param {CSSStyleSheet} sheet
 * @param {string} selector
 * @param {string} rule
 */
export function addCssRule(sheet, selector, rule) {
  if (typeof sheet.insertRule === 'function') {
    sheet.insertRule(`${selector}{${rule}}`, 0);
  }
  // Internet Explorer - pre v9
  else if (typeof sheet.addRule === 'function') {
    sheet.addRule(selector, rule, 0);
  } else {
    console.error('Your browser does not support stylesheets');
  }
}

/**
 * Remove every rule contained in the sheet
 * @param  {CSSStyleSheet} cssSheet
 */
export function removeSheetRules(cssSheet) {
  if (cssSheet) {
    while (cssSheet.cssRules.length) {
      if (typeof cssSheet.deleteRule === 'function') {
        // w3c way
        cssSheet.deleteRule(0);
      } else {
        // microsoft way
        cssSheet.removeRule(0);
      }
    }
  }
}

/**
 * Recursively look for a parent node matching criteria
 * @param {DOM node} el
 * @param {function} matcher : a function returning true when criterias are met.
 *                             e.g: el => el.classList.contains('my-button')
 *                             e.g: el => el.tagName === 'LI'
 */
export function findParentNode(el, matcher) {
  if (el.nodeName === 'BODY') {
    // Reached to top of DOM and element has not been found
    return;
  }
  return matcher(el) ? el : findParentNode(el.parentNode, matcher);
}

/**
 * Recursively look for a parent node with specified css class
 * @param {DOM node} el
 * @param {string}   classname (e.g. 'menu-item')
 */
export function hasParentWithClass(el, classname) {
  if (!el || el.nodeName === 'BODY') {
    // Stop search
    return false;
  }
  if (el.classList.contains(classname) === true) {
    return true;
  }
  return hasParentWithClass(el.parentNode, classname);
}

const SAFARI_STATUSBAR_HEIGHT = 16;
/**
 * Calculate page height on runtime due to safari status bar in cordova app
 * @return {number}
 */
export function getPageHeight() {
  if (global.isCordovaContext && isIOS()) {
    const { clientHeight } = document.documentElement;
    const { innerHeight } = window;
    const height = clientHeight > innerHeight ? clientHeight : innerHeight;
    return height - SAFARI_STATUSBAR_HEIGHT;
  }

  return window.innerHeight;
}

export function removeHtml(string) {
  const tmp = document.createElement('div');
  tmp.innerHTML = string;
  return tmp.innerText;
}

export function getSiblingsHeight(el) {
  let height = 0;
  const { children } = el.parentNode;

  for (let i = 0; i < children.length; i++) {
    const child = children.item(i);

    if (child !== el) {
      height += child.offsetHeight;
    }
  }

  return height;
}
