const LOG_PREF = '[Buffer] ';

export default (FileSystemHelper) => {
  let list = null;
  let currentDownload = null;

  const init = () => {
    console.log(`${LOG_PREF}init`);
    list = {};
    FileSystemHelper.deleteFolder('buffer');
  };

  const downloadRemoteFile = (file, file_datas, win, fail) => {
    console.log(`${LOG_PREF}downloadRemoteFile: ${file}`);
    currentDownload = FileSystemHelper.downloadFile(
      file,
      `buffer/${file}`,
      (entry) => {
        list[file] = file_datas;
        currentDownload = null;
        win();
      },
      (error, status) => {
        console.error(`${LOG_PREF}Download failed for ${file}`, error);
        currentDownload = null;
        fail(error, status);
      }
    );
  };

  const commitBuffer = (version, callback) => {
    console.log(`${LOG_PREF}commitBuffer`);
    FileSystemHelper.deleteFolder(`V${version}`, () => {
      FileSystemHelper.renameFolder('buffer/', `V${version}`, callback);
    });
  };

  const clearList = () => {
    list = {};
  };

  const abort = () => {
    console.log(`${LOG_PREF}abort`);
    if (currentDownload) {
      currentDownload.abort();
    }
  };

  return {
    get list() {
      return list;
    },
    get currentDownload() {
      return currentDownload;
    },

    init,
    downloadRemoteFile,
    commitBuffer,
    abort,
    clearList,
  };
};
