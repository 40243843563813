import config from 'app-customs/config/config';

import { isIOS, isAndroid } from 'src/core/util/browser';
import { get as getLabels } from 'src/core/Lang';

function generateMeta(attrs) {
  const meta = document.createElement('meta');
  meta.name = attrs.name;
  meta.setAttribute('content', attrs.content);
  document.getElementsByTagName('head')[0].appendChild(meta);
}

export function injectSmartBanners() {
  // No smartbanner in cordova mode
  if (global.isCordovaContext) {
    return;
  }

  const _isIOS = isIOS();
  const _isAndroid = isAndroid();

  // Smartbanners are only for iOS and android
  if (!_isIOS && !_isAndroid) {
    return;
  }

  // Skip if name or author is missing
  if (!config.name) {
    return;
  }

  // Skip if store url is not set
  if ((_isIOS && !config.iosAppStoreUrl) || (_isAndroid && !config.playStoreUrl)) {
    return;
  }

  /*  see: https://github.com/ain/smartbanner.js

        <meta name="smartbanner:title" content="'+projectConfig.native.name.default+'">
        <meta name="smartbanner:author" content="'+projectConfig.native.author+'">
        <meta name="smartbanner:price" content=" ">
        <meta name="smartbanner:price-suffix-apple" content=" ">
        <meta name="smartbanner:price-suffix-google" content=" ">
        <meta name="smartbanner:icon-apple" content="./lib/img/apple-app-store.png">
        <meta name="smartbanner:icon-google" content="./lib/img/google-play.png">
        <meta name="smartbanner:button" content="VIEW">
        <meta name="smartbanner:button-url-apple" content="'+projectConfig.native.iosAppStoreUrl+'">
        <meta name="smartbanner:button-url-google" content="'+projectConfig.native.playStoreUrl+'">
        <meta name="smartbanner:enabled-platforms" content="android,ios">

        <link rel="stylesheet" href="./lib/smartbanner.min.css">
        <script src="./lib/smartbanner.min.js"></script>
    */

  generateMeta({ name: 'smartbanner:title', content: config.name });
  generateMeta({ name: 'smartbanner:author', content: config.author || ' ' });
  generateMeta({ name: 'smartbanner:price', content: ' ' });

  if (_isIOS) {
    generateMeta({ name: 'smartbanner:price-suffix-apple', content: ' ' });
    generateMeta({ name: 'smartbanner:icon-apple', content: './lib/img/apple-app-store.png' });
    generateMeta({ name: 'smartbanner:button-url-apple', content: config.iosAppStoreUrl });
    generateMeta({ name: 'smartbanner:enabled-platforms', content: 'ios' });
  } else {
    generateMeta({ name: 'smartbanner:price-suffix-google', content: ' ' });
    generateMeta({ name: 'smartbanner:icon-google', content: './lib/img/google-play.png' });
    generateMeta({ name: 'smartbanner:button-url-google', content: config.playStoreUrl });
    generateMeta({ name: 'smartbanner:enabled-platforms', content: 'android' });
  }

  generateMeta({ name: 'smartbanner:button', content: getLabels().common.smartbannerViewButton });

  const linkElement = document.createElement('link');
  linkElement.setAttribute('rel', 'stylesheet');
  linkElement.setAttribute('href', './lib/smartbanner.min.css');
  document.getElementsByTagName('head')[0].appendChild(linkElement);

  const script = document.createElement('script');
  script.setAttribute('src', './lib/smartbanner.min.js');
  script.setAttribute('type', 'text/javascript');
  document.getElementsByTagName('body')[0].appendChild(script);
}
