import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dialog from 'src/components/dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import { iosHackScrollTo0 } from 'src/core/util/browser';

import * as actions from 'src/store/actions';

import { getBindedActions } from 'src/store/bindedActions';

import './InputModal.scss';

export const COMPONENT_KEY = 'Form';

class InputModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.isValueModified = this.isValueModified.bind(this);
    this.submit = this.submit.bind(this);
    this.onCancelButtonClick = this.onCancelButtonClick.bind(this);
  }

  showModal(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.setState({ showModal: true });
    getBindedActions().showInputModaldDialog();
  }

  hideModal(evt) {
    this.setState({ showModal: false });
    getBindedActions().hideInputModaldDialog();
  }

  updateValue(newValue) {
    this.setState({ value: newValue });
  }

  onCancelButtonClick() {
    let onCancelReturnedValue;
    if (typeof this.props.onCancel === 'function') {
      onCancelReturnedValue = this.props.onCancel();
    }
    if (onCancelReturnedValue !== false) {
      this.hideModal();
      this.setState({ value: this.props.initialValue });
    }
  }

  submit(evt) {
    const trimedValue =
      typeof this.state.value === 'string' ? this.state.value.trim() : this.state.value;
    if (this.state.value !== trimedValue) {
      this.setState({ value: trimedValue });
    }
    if (this.props.type === 'text-modal') {
      this.props.submit(trimedValue);
    } else if (this.props.type === 'checkbox-modal') {
      this.props.submit(this.state.value);
    } else {
      this.props.submit(this.props.name, trimedValue);
    }

    if (this.props.hideOnSubmit) {
      this.hideModal();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.showModal && nextProps.isOpenInputModalDialog === false) {
      this.hideModal();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps &&
      prevProps.requestStatus &&
      prevProps.requestStatus.saving &&
      this.props.requestStatus &&
      !this.props.requestStatus.saving &&
      !this.props.requestStatus.error
    ) {
      // Was saving and is now saved
      this.hideModal();
    }
  }

  isValueModified() {
    return (
      this.state.value !== undefined &&
      JSON.stringify(this.state.value) !== JSON.stringify(this.props.initialValue)
    );
  }

  renderInput(isClickable) {
    const {
      disabled,
      fieldClassName,
      label,
      name,
      placeHolder,
      readOnly,
      required,
      type,
      initialValue,
      displayLabel,
      setFieldVisiblity,
      isCleared,
      setCleared,
    } = this.props;

    if (isCleared && isCleared[this.props.name]) {
      this.setState({ value: '' });
      setCleared && setCleared();
    }

    const inputValue = isClickable
      ? initialValue
      : this.isValueModified()
      ? this.state.value
      : initialValue;

    const inputProps = {
      name,
      className: `generic-input ${fieldClassName || ''}${!readOnly ? ' edit' : ''}`,
      placeholder: !readOnly
        ? placeHolder
          ? placeHolder + (required ? this.props.labels.common.mandatoryInPlaceholder || '' : '')
          : null
        : this.props.labels.common.emptyReadOnlyField,
      displayLabel,
      type,
      label: isClickable
        ? label + (required ? (!readOnly ? this.props.labels.common.mandatory || '' : '') : '')
        : null,
      value: inputValue,
      onBlur: iosHackScrollTo0,
      readOnly: isClickable ? true : !!readOnly,
      disabled: !!disabled,
      required: !!required,
      onClick: !readOnly && !disabled && isClickable ? this.showModal : null,
      onChange: !isClickable ? this.updateValue : null,
      setFieldVisiblity,
    };

    return isClickable
      ? this.props.renderClickableInput(inputProps)
      : this.props.renderModalInput(inputProps);
  }

  getModalButtons() {
    const { requestStatus, cancelButtonLabel, okButtonLabel, labels } = this.props;

    const buttons = [
      <div key={0} className="generic-btn" onClick={this.onCancelButtonClick}>
        {cancelButtonLabel || labels.common.cancel}
      </div>,
    ];
    if (this.isValueModified()) {
      buttons.push(
        <div
          key={1}
          className={`generic-btn cta-modal-btn${
            requestStatus && requestStatus.saving ? ' modal-btn-disabled' : ''
          }`}
          onClick={this.submit}
        >
          {okButtonLabel || labels.common.save}
        </div>
      );
    }
    return buttons;
  }

  render() {
    const dialogStyle = {
      maxHeight:
        typeof this.props.maxHeight === 'number'
          ? this.props.maxHeight
          : document.documentElement.clientHeight,
    };

    return (
      <>
        {this.renderInput(true)}

        <Dialog
          className={`input-modal ${this.props.className || ''}`}
          open={this.state.showModal}
          onClose={this.hideModal}
          style={dialogStyle}
        >
          {this.props.label && (
            <DialogTitle className="title-font modal-title">{this.props.label}</DialogTitle>
          )}
          <div className="input-modal-inner">
            {this.renderInput(false)}

            <div className="generic-btn-container">{this.getModalButtons()}</div>
          </div>
        </Dialog>
      </>
    );
  }
}

InputModal.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  // initialValue
  placeHolder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  fieldClassName: PropTypes.string,
  renderClickableInput: PropTypes.func.isRequired,
  renderModalInput: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  hideOnSubmit: PropTypes.bool,
  requestStatus: PropTypes.object,
  className: PropTypes.string,
  okButtonLabel: PropTypes.string, // optional (to override)
  cancelButtonLabel: PropTypes.string, // optional (to override)
  onCancel: PropTypes.func, // optional callback when cancel button is actioned
  labels: PropTypes.object.isRequired,
  maxHeight: PropTypes.number,
  displayLabel: PropTypes.bool, // optional display label
  isOpenInputModalDialog: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => state[COMPONENT_KEY];
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(InputModal);
