// Libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// App modules
import * as actions from 'src/store/actions';
import { getPlatform } from 'src/core/util/browser';
import config from 'app-customs/config/config';
import { getIsPrivacyModalAccepted, setIsPrivacyModalAccepted } from './ModalPrivacyPersistence';
import './ModalPrivacy.scss';

export const COMPONENT_KEY = 'ModalPrivacy';
export const CONTAINER_DOM_ID = 'modalPrivacy-container';

const LOG_PREF = '[ModalPrivacyAgreement] ';
const safetyMargin = 10;

class ModalPrivacyAgreement extends Component {
  state = {
    isBottom: false,
  };

  componentDidMount() {
    this.trackBody();
  }

  handleScroll = (e) => {
    const isBottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + safetyMargin;
    if (this.state.isBottom !== true) {
      this.setState({ isBottom: isBottom });
    }
  };

  trackBody = () => {
    const element = document.getElementById('modal-privacy-body');
    const isBottom = element.scrollHeight - element.scrollTop <= element.clientHeight + safetyMargin;
    if (this.state.isBottom !== true) {
      this.setState({ isBottom: isBottom });
    }
  };

  validateModalPrivacyAgreement = () => {
    setIsPrivacyModalAccepted(true);
    this.props.actions.validateModalPrivacyAgreement();
  };

  render() {
    const { isBottom } = this.state;
    console.log(`${LOG_PREF}render`);
    
    if (
      global.isCordovaContext !== true ||
      config.MODAL_PRIVACY_AGREEMENT.ENABLED_PLATFORM_FEATURE === undefined ||
      getIsPrivacyModalAccepted() ||
      config.MODAL_PRIVACY_AGREEMENT.ENABLED_PLATFORM_FEATURE.includes(getPlatform()) !== true
    ) {
      return null;
    }

    return (
      <div className="modal-privacy-content">
        <div
          id="modal-privacy-body"
          className="modal-privacy-body"
          dangerouslySetInnerHTML={{ __html: this.props.labels.modalPrivacyAgreement.body }}
          onScroll={this.handleScroll}
        ></div>
        <p
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            fontSize: 14,
            padding: '2%',
            color: '#939393',
          }}
        >
          {this.props.labels.modalPrivacyAgreement.hint}
        </p>
        {isBottom ? (
          <button
            style={{
              cursor: 'pointer',
              backgroundColor: '#257985',
              color: 'white',
              alignItems: 'center',
              justifyContent: 'center',
              width: '90%',
              margin: '5%',
              padding: '5%',
              marginTop: '0%',
              borderRadius: 10,
              borderWidth: 1,
              borderColor: '#257985',
            }}
            onClick={() => {
              this.validateModalPrivacyAgreement();
            }}
          >
            {this.props.labels.modalPrivacyAgreement.acceptButton}
          </button>
        ) : (
          <button
            disabled
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              width: '90%',
              margin: '5%',
              padding: '5%',
              marginTop: '0%',
              borderRadius: 10,
              borderWidth: 1,
            }}
          >
            {this.props.labels.modalPrivacyAgreement.acceptButton}
          </button>
        )}
      </div>
    );
  }
}

ModalPrivacyAgreement.propTypes = {
  labels: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => state[COMPONENT_KEY];
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(ModalPrivacyAgreement);
