import { EXPORT_NOTES } from 'src/store/actionTypes';

import { get as getLabels } from 'src/core/Lang';

import config from 'app-customs/config/config';

import { addHttpIfNeeded } from 'src/core/util/JsTools';

import { DATA_TYPE_SPEAKERS, DATA_TYPE_PAPERS, getSpeakerTitle } from 'app-customs/config/dataConfig';

import { DATA_TYPE_TO_PAGE_KEY } from 'src/pages/dataToPageMapping';

import { get } from 'src/core//query/Query';

import Pages from 'src/pages/Pages';

import { encodeProps } from 'src/core/navigation/History';

import { showFormModal } from 'src/store/actions';

import { sendMail } from './sendExportedNotesMailService';

import { getAccount } from '../login/LoginPersistence';
import { isSessionValid } from '../login/LoginService';

export default ({ dispatch, getState }) => (next) => (action) => {
  const result = next(action);

  switch (action.type) {
    case EXPORT_NOTES:
      const mailContent = {
        sections: [],
      };

      if (action.notes) {
        Object.keys(action.notes).forEach((dataType) => {
          if (action.notes[dataType].length) {
            const section = {
              notes: [],
            };
            const notes = action.notes.data[dataType];
            notes.forEach((ele, index) => {
              const id = parseInt(action.notes[dataType][index], 10);

              const item = get(id, dataType);

              if (!item) {
                console.error(`Unable to find item id:${id} dataType:${dataType}`);
                return;
              }

              const page = Pages[DATA_TYPE_TO_PAGE_KEY[dataType]];
              const encodedOptions = encodeProps(
                {
                  id,
                },
                page.key
              );

              const queryString = config.ROUTE_SEPARATOR + page.path + encodedOptions;

              const url = addHttpIfNeeded(`${config.WEB_URL}/${queryString}`, true);

              let itemTitle;
              // TODO: clean up. declare an object in configuration to get item title depending on data type?
              if (dataType === DATA_TYPE_SPEAKERS) {
                itemTitle = getSpeakerTitle(item);
              } else if (dataType === DATA_TYPE_PAPERS) {
                itemTitle = item.lump.code || item.title;
              } else {
                // default case
                itemTitle = item.title;
              }
              section.notes.push({
                itemTitle,
                url,
                note: ele,
              });
            });
            section.title = getLabels().data[dataType].plural;
            mailContent.sections.push(section);
          }
        });
      }
      const name = getLabels().notes.pageTitle;
      mailContent.subject =
        getLabels().common.appTitle + (name ? config.APP_TITLE_SEPARATOR + name : '');
      dispatch(
        showFormModal(
          {
            title: getLabels().notes.exportForm.title,
            subtitle: getLabels().notes.exportForm.subtitle,
            fields: [
              {
                type: 'input',
                subType: 'email',
                name: 'email',
                value: isSessionValid() ? getAccount().username : '',
                label: getLabels().notes.exportForm.fields.email.label,
                validation: {
                  type: 'email',
                  validationMessage: getLabels().notes.exportForm.fields.email.validationMessage,
                },
              },
            ],
          },
          sendMail,
          mailContent
        )
      );
      // sendMail(mail);
      // let mailStringified = encodeURIComponent(JSON.stringify(mail));
      break;

    default:
  }

  return result;
};
