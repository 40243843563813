import config from 'app-customs/config/config';

// Simple module avoiding a circular dependency
// app-customs/config/pagesConfig.js
//   -> src/pages/mobigeo/MobigeoPage.js
//     -> src/pages/mobigeo/mobigeoMiddleware.js
//       -> src/core/navigation/Router.js
//         -> src/pages/Pages.js -> app-customs/config/pagesConfig.js

const STATUS = {
  notLoaded: 'not_loaded',
  loading: 'loading',
  loaded: 'loaded',
  restarting: 'restarting',
};

let status;

function setStatus(value) {
  console.info(`Mobigeo status: ${value}`);
  status = value;
}

setStatus(STATUS.notLoaded);

export default {
  setStatusNotLoaded: (value) => {
    setStatus(STATUS.notLoaded);
  },
  setStatusLoading: (value) => {
    setStatus(STATUS.loading);
  },
  setStatusLoaded: (value) => {
    setStatus(STATUS.loaded);
  },
  setStatusRestarting: (value) => {
    setStatus(STATUS.restarting);
  },

  isNotLoaded: (value) => status === STATUS.notLoaded,
  isLoading: (value) => status === STATUS.loading,
  isLoaded: (value) => status === STATUS.loaded,
  isRestarting: (value) => status === STATUS.restarting,
};

if (config.ENV === 'dev') {
  global.getMobigeoContextStatus = () => status;
}
