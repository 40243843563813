import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { FORCE_ONLINE_ASSETS } from 'app-customs/config/dataConfig';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';

import './Description.scss';
import YoutubePlayer from 'src/components/youtube/YoutubePlayer';

class Description extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      opened: false,
    };
  }

  render() {
    const {
      description,
      logoFileName,
      video,
      preview,
      isLogoFullWidth,
      labels,
      onClick,
      className,
      dataType,
    } = this.props;
    const { opened } = this.state;
    const hasDescription = typeof description === 'string' && description.length > 0;
    const imgElement = logoFileName ? (
      <img
        src={getUrl(`/${logoFileName}`, FORCE_ONLINE_ASSETS?.includes(dataType) ? true : undefined)}
        alt="logo"
      />
    ) : null;

    const shouldPreview = preview && hasDescription && description.length > 400;
    const previewDescription = !opened && shouldPreview;
    const click = onClick && typeof onClick === 'function' ? onClick : () => {};

    return (
      <div className={`fiche-description ${className || ''}`} onClick={click}>
        {(isLogoFullWidth || !hasDescription) && imgElement && (
          <div className="logo img-full-width">{imgElement}</div>
        )}
        {video ? <YoutubePlayer embedId={video} height={315} width={560}/> : null}

        {hasDescription && (
          <div className="prop-name">
            {previewDescription && <div className="bottom-shadow" />}
            <div className={`prop-description ${previewDescription ? 'preview' : ''}`}>
              {!isLogoFullWidth && imgElement && <div className="logo img-left">{imgElement}</div>}

              {hasDescription && <p dangerouslySetInnerHTML={{ __html: description }} />}
            </div>
            {previewDescription && (
              <div className="btn-expand" onClick={() => this.setState({ opened: true })}>
                {labels.common.seeMore}
              </div>
            )}
            {!previewDescription && shouldPreview && (
              <div className="btn-expand" onClick={() => this.setState({ opened: false })}>
                {labels.common.seeLess}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

Description.propTypes = {
  dataType: PropTypes.string,
  labels: PropTypes.object,
  onClick: PropTypes.func,
  logoFileName: PropTypes.string,
  description: PropTypes.string,
  isLogoFullWidth: PropTypes.bool,
  preview: PropTypes.bool,
};

export default Description;
