import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { debounce } from 'lodash-custom';

import { USER_DATA_PAGE_KEY } from 'src/pages/pagesKeys';
import { DATA_TYPE_USERPROJECTS } from 'app-customs/config/dataConfig';

import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import Menu from 'src/components/menu/Menu';
import UserDataContent from './UserDataContent';
import Loader from 'src/components/loader/Loader';

import * as actions from 'src/store/actions';

import './UserDataPage.scss';

const LOG_PREF = '[UserDataPage]';

class UserDataPage extends Component {
  pageKey = USER_DATA_PAGE_KEY;

  fetchLists = debounce((inputs) => {
    this.props.actions.fetchLists(inputs);
  }, 25);

  /**
   * Fetch data if :
   *  - data has been updated (shouldFetch: true)
   *  - inputs has changed (type/ids couples)
   *  - or if we have no data (!== data empty) and request is neither pending nor without result
   */
  fetchIfNeeded(props) {
    if (
      props.shouldFetch === true ||
      JSON.stringify(this.props.inputs) !== JSON.stringify(props.inputs) ||
      typeof props.userProjectsItems === 'undefined'
    ) {
      this.fetchLists(props.inputs || [{ dataType: DATA_TYPE_USERPROJECTS }]);
    }
  }

  /**
   * Update string visible in browser tab/history/favorites
   */
  setDocumentContext() {
    if (this.props.isActive(this.pageKey)) {
      this.props.setDocumentContext(this.props.labels.userData.title);
    }
  }

  componentDidMount() {
    this.fetchIfNeeded(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.fetchIfNeeded(nextProps);
  }

  getCurrentTabIndex() {
    if (this.props.tabIndex !== undefined) {
      return this.props.tabIndex;
    } else {
      if (this.props.tabKey) {
        return undefined;
      } else {
        return 0;
      }
    }
  }

  getCurrentTabKey() {
    if (this.props.tabKey) {
      return this.props.tabKey;
    } else {
      return null;
    }
  }

  render() {
    console.log(LOG_PREF + 'render');
    // if (!this.props.isVisible) return null;

    return (
      <>
        <Menu
          options={this.props.menuOptions}
          actions={this.props.actions}
          labels={this.props.labels}
          profile={this.props.profile}
          associatedPageKey={this.pageKey}
          adConfig={this.props.adConfig}
          twoColumns={this.props.twocolumns}
          isLocationEnabled={this.props.isLocationEnabled}
        />

        <AppToolbar
          labels={this.props.labels}
          isDisplayed={this.props.hasToolbar}
          actions={this.props.actions}
          title={this.props.labels.userData.title}
          pageKey={this.pageKey}
          profile={this.props.profile}
          hasBackButton={this.props.backButtonInToolbar}
          listToolBarButton={this.props.listToolBarButton}
          listConfigToolBarButton={this.props.listConfigToolBarButton}
          hasHomeButton={this.props.homeButtonInToolbar}
          hasFavButton={this.props.favButtonInToolbar}
          hasSearchButton={this.props.searchButtonInToolbar}
          hasMenuButton={this.props.menuButtonInToolbar}
        />

        {!this.props.userData ? (
          <Loader labels={this.props.labels} />
        ) : (
          <UserDataContent
            userData={this.props.userData}
            contacts={this.props.contacts}
            contactedParticipants={this.props.contactedParticipants}
            searchResults={this.props.searchResults}
            profile={this.props.profile}
            tabIndex={this.getCurrentTabIndex()}
            tabKey={this.getCurrentTabKey()}
            status={this.props.status}
            sections={this.props.sections}
            formStatus={this.props.formStatus}
            formSections={this.props.formSections}
            tosLink={this.props.tosLink}
            tosLinkTarget={this.props.tosLinkTarget}
            externalResourceLinkTarget={this.props.externalResourceLinkTarget}
            externalResourceLinkOpenInInAppBrowser={
              this.props.externalResourceLinkOpenInInAppBrowser
            }
            tosLinkOpenInInAppBrowser={this.props.tosLinkOpenInInAppBrowser}
            logout={this.props.logout}
            keyboardHeight={this.props.keyboardHeight}
            labels={this.props.labels}
            actions={this.props.actions}
            userProjectsItems={this.props.userProjectsItems}
            isFilterVisible={this.props.isFilterVisible}
            isDataFiltered={this.props.filterEnabled && this.props.filterCatsCount > 0}
          />
        )}
      </>
    );
  }
}

UserDataPage.propTypes = {
  userData: PropTypes.object,
  tabIndex: PropTypes.number,

  contacts: PropTypes.array,
  contactedParticipants: PropTypes.array,
  searchResults: PropTypes.object,

  userProjectsItems: PropTypes.object,

  formSections: PropTypes.array.isRequired,
  formStatus: PropTypes.object,
  tosLink: PropTypes.string,
  logout: PropTypes.func.isRequired,

  // Common
  keyboardHeight: PropTypes.number,
  adConfig: PropTypes.object,
  twocolumns: PropTypes.bool,
  menuOptions: PropTypes.object.isRequired,
  profile: PropTypes.string,
  isActive: PropTypes.func.isRequired,
  setDocumentContext: PropTypes.func.isRequired,
  isLocationEnabled: PropTypes.bool,
  isVisible: PropTypes.bool, // set by togglePageAfterNavigation common reducer function
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => state[USER_DATA_PAGE_KEY];
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(UserDataPage);
