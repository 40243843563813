import React from 'react';
import PropTypes from 'prop-types';

import Url from 'src/components/url/Url';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

const WebsiteRow = (props) =>
  !props.website ? null : (
    <div className="free-row">
      <div className="prop-img">
        <div className="prop-left">
          <GenericIcon
            src={'icons-svg/fiche/lien.svg'}
            st1Class="row-st0"
            st0Class="row-st1"
            className="link-row"
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
          className="prop-right "
        >
          <Url callback={props.actions.linkClicked} href={props.website}>
            {new URL(props?.website)?.host}
          </Url>
        </div>
      </div>
    </div>
  );

WebsiteRow.propTypes = {
  website: PropTypes.string,
  actions: PropTypes.object.isRequired,
};

export default WebsiteRow;
