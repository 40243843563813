import { START_LOCATION_ON_APP_BOOT } from 'app-customs/config/mobigeoConfig';

const LOG_PREF = '[LocationHelper] ';

/**
 * Persist the status of geolocation has the user set it
 */
export const StoredManualLocationStatus = (function() {
  const LOCAL_STORAGE_KEY = 'manually-turned-off-location';

  const STATUS = {
    ENABLED: 'enabled',
    DISABLED: 'disabled',
  };

  function setEnabled() {
    _set(STATUS.ENABLED);
  }
  function setDisabled() {
    _set(STATUS.DISABLED);
  }
  function _set(value) {
    localStorage.setItem(LOCAL_STORAGE_KEY, value);
  }

  function hasBeenDisabled() {
    return localStorage.getItem(LOCAL_STORAGE_KEY) === STATUS.DISABLED;
  }

  return { setEnabled, setDisabled, hasBeenDisabled };
})();

/**
 * Persist the result of the disclaimer popup (accepted/denied)
 */
export const StoredDisclaimerValue = (function() {
  const LOCAL_STORAGE_KEY = 'location-disclaimer';

  const DISCLAIMER_VALUES = {
    accepted: 'accepted',
    denied: 'denied',
  };

  function isAccepted() {
    return localStorage.getItem(LOCAL_STORAGE_KEY) === DISCLAIMER_VALUES.accepted;
  }

  function isDenied() {
    return localStorage.getItem(LOCAL_STORAGE_KEY) === DISCLAIMER_VALUES.denied;
  }

  function setAccepted() {
    localStorage.setItem(LOCAL_STORAGE_KEY, DISCLAIMER_VALUES.accepted);
  }

  function setDenied() {
    localStorage.setItem(LOCAL_STORAGE_KEY, DISCLAIMER_VALUES.denied);
  }

  return { isAccepted, isDenied, setAccepted, setDenied };
})();

export function shouldAutoStart() {
  const disclaimerNotDenied = StoredDisclaimerValue.isDenied() !== true;
  const locationNotManuallyStopped = StoredManualLocationStatus.hasBeenDisabled() !== true;

  console.log(
    `${LOG_PREF}Start on app boot: ${START_LOCATION_ON_APP_BOOT} # Disclaimer not denied: ${disclaimerNotDenied} # Location not manually stoppped: ${locationNotManuallyStopped}`
  );

  return (
    START_LOCATION_ON_APP_BOOT &&
    disclaimerNotDenied &&
    locationNotManuallyStopped &&
    global.isCordovaContext
  );
}
