import { FAVORITES_PAGE_KEY } from 'src/pages/pagesKeys';

import FavoritesPage from './FavoritesPage';
import reducer from './favoritesPageReducer';

export default {
  key: FAVORITES_PAGE_KEY,
  path: '/favorites',
  component: FavoritesPage,
  elId: 'favorites-page',
  getReduxReducer: (state, action) => reducer(state[FAVORITES_PAGE_KEY], action),
};
