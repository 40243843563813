/**
 * doc: https://github.com/apache/cordova-plugin-camera
 */

import { get as getLabels } from 'src/core/Lang';

const BLOB_MIME_TYPE = 'image/jpeg';

const getOptions = () => ({
  destinationType: navigator.camera.DestinationType.FILE_URI,
  encodingType: navigator.camera.EncodingType.JPEG,
  mediaType: navigator.camera.MediaType.PICTURE,
  cameraDirection: navigator.camera.Direction.FRONT, // has no effect
  correctOrientation: true,
});

export function getPictureFromGallery(options, onSuccess, onError) {
  const _options = {
    sourceType: navigator.camera.PictureSourceType.SAVEDPHOTOALBUM,
    ...getOptions(),
    ...options,
  };
  getPicture(_options, onSuccess, onError);
}

export function getPictureFromCamera(options, onSuccess, onError) {
  const _options = {
    sourceType: navigator.camera.PictureSourceType.CAMERA,
    ...getOptions(),
    ...options,
  };
  // Request capture permission before scan to handle specific permissions behaviors
  // Very basic for now. No callBack handle
  window.PermissionManagement &&
    window.PermissionManagement.requestCapturePermission(
      {
        goSettingModalTitle: getLabels().permissions.goSettingModalTitle,
        goSettingModalMessage: getLabels().permissions.goSettingModalMessage,
        goSettingModalOk: getLabels().permissions.goSettingModalOk,
        goSettingModalCancel: getLabels().permissions.goSettingModalCancel,
      },
      () => {},
      () => {}
    );
  getPicture(_options, onSuccess, onError);
}

function getPicture(options, onSuccess, onError) {
  if (!navigator.camera || !navigator.camera.getPicture) {
    console.error('Missing cordova-plugin-camera');
    onError();
    return;
  }

  // 1 - get picture
  navigator.camera.getPicture(
    // Success
    function getPictureSuccess(result) {
      if (!result) {
        console.error('camera.getPicture returned empty value');
        onError();
        return;
      }
      // 2 - String -> FileEntry
      window.resolveLocalFileSystemURL(
        result,
        async function success(fileEntry) {
          // 3 - FileEntry -> Blob
          const blob = await getBlob(fileEntry);
          if (!blob) {
            onError();
          } else {
            onSuccess(blob);
          }
        },
        function error() {
          console.error('Failed to resolve local fs url for picture', result);
          onError();
        }
      );
    },
    // Error
    function getPictureFailure(errorMessage) {
      // The user may have simply cancelled the action, so ignore this callback
      // console.error('Failed to get picture: '+errorMessage);
      // onError();
    },
    // Options
    options
  );
}

function getFile(fileEntry) {
  return new Promise((resolve, reject) => {
    fileEntry.file(
      function success(file) {
        resolve(file);
      },
      function error(fileError) {
        console.error(
          `Failed to get file object from fileEntry object: ${fileError.message}`,
          fileError
        );
        reject(null);
      }
    );
  });
}

function getBlob(fileEntry) {
  return new Promise(async function(resolve, reject) {
    try {
      const file = await getFile(fileEntry);
      const blob = await readFile(file);
      resolve(blob);
    } catch (e) {
      console.error('Failed to get blob from FileEntry', e);
      reject();
    }
  });
}

function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = (event) => {
      const blob = new Blob([event.target.result], {
        type: BLOB_MIME_TYPE,
      });
      blob.name = file.name;
      resolve(blob);
    };

    reader.onerror = (e) => {
      console.error(`Failed read file ${file.name}`, e);
      reject();
    };

    reader.readAsArrayBuffer(file);
  });
}
