import { GMAP_PLACES_PAGE_KEY } from 'src/pages/pagesKeys';

import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';
import GmapPlacesContent from './GmapPlacesContent';

export default {
  key: GMAP_PLACES_PAGE_KEY,
  path: '/gmapplaces',
  elId: DOM_ID,
  className: 'gmap-places-page',
  component: GenericItemPage,
  childComponent: GmapPlacesContent,
  relatedDataToFetch: ['exhibitors', 'stores'],
};
