import React from 'react';
import PropTypes from 'prop-types';

import { getCurrent } from 'src/core/Lang';

import './EBadge.scss';

class Ebadge extends React.Component {
  constructor(props) {
    super(props);
  }

  getIframelyHtml(title, badge, height, width) {
    // If you use embed code from API
    return {
      __html:
        '<iframe title="' +
        title +
        '" src="' +
        badge +
        '" sandbox="' +
        'allow-scripts allow-forms' +
        '" allow="' +
        'encrypted-media' +
        '" scrolling="' +
        'yes' +
        '" frameBorder="' +
        '0' +
        '"/>',
    };
  }

  render() {
    const { userData, width, height, labels } = this.props;
    let extRes = userData.externalResources;

    if (extRes && extRes.badge)
      return (
        <div
          className="ud-eBadge"
          dangerouslySetInnerHTML={this.getIframelyHtml(
            labels.userData.badge,
            extRes.badge[getCurrent()] || extRes.badge,
            height,
            width
          )}
        />
      );
    return null;
  }
}

Ebadge.propTypes = {
  userData: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
};

Ebadge.defaultProps = {
  userData: null,
  labels: '',
  height: document.documentElement.clientHeight - 200 + ' px',
  width: document.documentElement.clientWidth + ' px',
};

export default Ebadge;
