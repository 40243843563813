import fetchHelper from 'src/core/util/FetchHelper';
import { get3CharsLang } from './utils';

const LOG_PREF = '[Assets] ';

export default function(FileSystemHelper, getAssetsDefinitionFileUrl, getCurrentLang) {
  let list = null;
  let version = 1;
  let listVersion = {};

  function save() {
    console.log(`${LOG_PREF}save`);
    window.localStorage.setItem('Assets.version', version);
    window.localStorage.setItem('Assets.listVersion', JSON.stringify(listVersion));
  }

  function saveList() {
    console.log(`${LOG_PREF}Saving assets list into the APP LOCAL Storage: assets_list.json`);
    function setFileContentWin() {
      console.log(`${LOG_PREF}setFileContentWin`);
    }
    function setFileContentFail() {
      console.log('setFileContentFail');
      console.error(
        `${LOG_PREF}setFileContentFail error: Could not write APP LOCAL file: assets_list.json`
      );
    }
    FileSystemHelper.setFileContent(
      'assets_list.json',
      JSON.stringify(list),
      setFileContentWin,
      setFileContentFail
    );
  }

  function loadFile(callback) {
    console.log(
      `${LOG_PREF}loadFile (reset the assets with the REMOTE SERVER latest assets files)`
    );

    fetchHelper(
      getAssetsDefinitionFileUrl(),
      null, // options
      true, // is json
      // on success:
      function(datas) {
        // MA: add checks on the format returned
        list = datas;
        version = 1;
        listVersion = { initial: list.version };
        save();
        saveList();
        callback();
      },
      // on failure:
      function() {
        console.error(
          `${LOG_PREF}Error while fetching assets list json file. This means a failed build.`
        );
      },
      false // no error message
    );
  }

  function init(callback) {
    console.log(`${LOG_PREF}init`);

    version = parseInt(window.localStorage.getItem('Assets.version'), 10);
    console.log(`${LOG_PREF}localStorage assets version: `, version);

    if (isNaN(version) === true) {
      console.log(`${LOG_PREF}first time launch!`);
      loadFile(callback);
    } else {
      console.log(`${LOG_PREF}not first time launch! Now read the APP LOCAL assets definition`);

      function getFileContentWin(data) {
        console.log(`${LOG_PREF}getFileContentWin`);
        console.log(`${LOG_PREF}Load APP LOCAL assets.`);
        if (typeof data === 'string') {
          data = JSON.parse(data);
        }
        list = data;
        listVersion = JSON.parse(window.localStorage.getItem('Assets.listVersion'));
        if (version > 1) {
          FileSystemHelper.getDirectory(
            `V${version - 1}`,
            // Success
            () => {
              console.log(`${LOG_PREF}OK: LOCAL APP assets directory exist... Continue!`);
              callback();
            },
            // Failure
            () => {
              console.error(
                `${LOG_PREF}Should not come here.... Means the APP LOCAL folder does not exist anymore...`,
                `V${version - 1}`
              );
              loadFile(callback);
            }
          );
        } else {
          console.log(`${LOG_PREF}OK: LOCAL APP assets directory V1 exist... Continue!`);
          callback();
        }
      }
      function getFileContentFail() {
        console.log(`${LOG_PREF}getFileContentFail`);
        console.error(
          `${LOG_PREF}Should not come here.... Means the APP LOCAL assets_list.json could not get read OR write.`
        );
        loadFile(callback);
      }
      FileSystemHelper.getFileContent('assets_list.json', getFileContentWin, getFileContentFail);
    }
  }

  function debug() {
    console.log('**********   ASSETS  ************');
    console.log('----------- VARIABLES -----------');
    console.log('version: ', version);
    console.log('listVersion: ', listVersion);
    console.log('list: ', list);
    console.log('');
    console.log('----------- LOCALSTORAGE -----------');
    console.log('version:', window.localStorage.getItem('Assets.version'));
    console.log('listVersion:', window.localStorage.getItem('Assets.listVersion'));
    console.log('*********************************');
  }

  function updateAssets(buffer) {
    console.log(`${LOG_PREF}updateAssets`);

    for (const file in buffer.list) {
      if (buffer.list.hasOwnProperty(file) === false) {
        continue;
      }
      let lang = 'all';
      if (buffer.list[file].lang) {
        lang = buffer.list[file].lang;
      }
      if (list[lang][file] && list[lang][file].version) {
        FileSystemHelper.deleteFile(`V${list[lang][file].version}${file}`);
      }
      list[lang][file] = buffer.list[file];
      list[lang][file].version = version;
    }

    version++;
    save();
    saveList();
  }

  function getItems() {
    console.log(`${LOG_PREF}getItems`);
    const allItems = {};
    let file;
    const threeCharsLang = get3CharsLang(getCurrentLang());

    for (file in list.all) {
      if (list.all.hasOwnProperty(file) === false) {
        continue;
      }
      allItems[file] = list.all[file];
    }
    for (file in list[threeCharsLang]) {
      if (list[threeCharsLang].hasOwnProperty(file) === false) {
        continue;
      }
      allItems[file] = list[threeCharsLang][file];
    }
    return allItems;
  }

  function getItem(file) {
    let item;
    const threeCharsLang = get3CharsLang(getCurrentLang());

    // Search in list[lang]
    if (threeCharsLang && list[threeCharsLang]) {
      item = list[threeCharsLang][file];
      if (item) {
        return item;
      }
    }
    // Search in list.all
    item = list.all[file];
    if (item) {
      return item;
    }
    // default: return null
    return null;
  }

  function updateVersion(version) {
    console.log(`${LOG_PREF}updateVersion`);
    listVersion[get3CharsLang(getCurrentLang())] = version;
    save();
  }

  function getListVersion() {
    console.log(`${LOG_PREF}getListVersion`);
    const threeCharsLang = get3CharsLang(getCurrentLang());
    return listVersion[threeCharsLang] ? listVersion[threeCharsLang] : listVersion.initial;
  }

  /**
   * Returns assets path for current lang whose version if superior than argument
   * @param  {number} thresholdVersion
   * @return {array}
   */
  function getAssetsWhoseVersionNumberIsAbove(thresholdVersion) {
    const TIMER_KEY = `filtering assets whose version is > ${thresholdVersion}`;
    console.time(TIMER_KEY);

    // HARDCODED because actually everything is in 'all'
    const threeCharsLang = /* get3CharsLang(getCurrentLang()); */ 'all';

    let filteredAssets = [];

    if (threeCharsLang && list[threeCharsLang]) {
      filteredAssets = Object.keys(list[threeCharsLang]).filter(
        (asset) => (list[threeCharsLang][asset].version || 0) > thresholdVersion
      );
    }

    console.timeEnd(TIMER_KEY);
    return filteredAssets;
  }

  return {
    get version() {
      return version;
    },
    get listVersion() {
      return listVersion;
    },

    init,
    getItem,
    getItems,
    debug,
    getListVersion,
    updateVersion,
    updateAssets,
    getAssetsWhoseVersionNumberIsAbove,
  };
}
