import Pages from 'src/pages/Pages';
import { SYNOPTIC_AGENDA_PAGE_KEY } from 'src/pages/pagesKeys';
import { getIosVersion } from 'src/core/util/browser';
import { isMounted } from 'src/core/navigation/Router';

// import {
//     FAVORITES_PAGE_KEY,
//     LIST_PAGE_KEY,
//     LIST_GROUPS_PAGE_KEY,
//     SEARCH_PAGE_KEY } from 'src/pages/pagesKeys';

import { HAS_NAVIGATED } from 'src/store/actionTypes';

// const PERFORM_FOR_PAGES = [

//     FAVORITES_PAGE_KEY,
//     LIST_PAGE_KEY,
//     LIST_GROUPS_PAGE_KEY,
//     SEARCH_PAGE_KEY,

// ];

const DEFAULT_DELAY = 400;

function getDelay(previousPageKey) {
  if (previousPageKey && previousPageKey === SYNOPTIC_AGENDA_PAGE_KEY && getIosVersion() < 13) {
    return 800;
  }
  return DEFAULT_DELAY;
}

/**
 * On iOS there is a nasty bug on some pages (search, list, agenda) that makes all the content disappear on touch.
 * Yet you can interact with elements, type text in the input but nothing is visible...
 *
 * No clean fix could be found. A hack is to toggle visibility of the DOM container.
 */

// commented code is guillaume previous' version, now is applied to all the pages.

function performHack(pageKey, delay = DEFAULT_DELAY) {
  if (isMounted(pageKey) === true) {
    const container = Pages[pageKey].getElement();
    if (container) {
      // console.log('ios white page hack delay: '+delay);

      // container.style.visibility = 'hidden';
      container.style.overflow = 'visible';

      window.setTimeout(function() {
        // container.style.visibility = 'visible';
        container.style.overflow = 'hidden';
      }, delay);
    }
  }
}
export default performHack;

export const middleware = ({ dispatch, getState }) => (next) => (action) => {
  const result = next(action);

  switch (action.type) {
    case HAS_NAVIGATED:
      performHack(action.pageKey, getDelay(action.previousPageKey));
      break;

    default: // for linter
  }
  return result;
};
