import { SIDE_EVENT_PAGE_KEY } from 'src/pages/pagesKeys';

import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';
import SideEventContent from './SideEventContent';

export default {
  key: SIDE_EVENT_PAGE_KEY,
  path: '/side-event',
  elId: DOM_ID,
  className: 'side-event-page',
  component: GenericItemPage,
  childComponent: SideEventContent,
  relatedDataToFetch: ['exhibitor', 'store', 'places', 'speakers', 'moderators', 'presidents', 'directors', 'coordinators', 'experts', 'coautors', 'orators', 'events',  'sideevents','papers', 'gmapplaces'],
};
