import Swal from 'sweetalert2';
import { getBindedActions } from 'src/store/bindedActions';
import { getDialogConfig } from 'src/core/dialog/DialogPersistence';
import * as dialogsConfig from 'app-customs/config/dialogsConfig';
import { getTimeOfLastScanWithoutResult } from 'src/core/cordova/cordovaReducerAndMiddleware';
import { isCordovaContext, isAndroid } from 'src/core/util/browser';

export default function CloseDialogOnBackClick() {
  if (
    isCordovaContext() &&
    isAndroid() &&
    dialogsConfig.disablePhysicalBackAndroid === false &&
    getDialogConfig().isOpen &&
    getDialogConfig().actionName
  ) {
    getBindedActions()[getDialogConfig().actionName]();
  } else if (
    isAndroid() &&
    global.isCordovaContext &&
    Swal.isVisible() &&
    dialogsConfig.disablePhysicalBackAndroid === false
  ) {
    Swal.close();
  } else {
    // see SAN-17
    if (
      getTimeOfLastScanWithoutResult() &&
      new Date().getTime() - getTimeOfLastScanWithoutResult() < 1500
    ) {
      console.info(`[CordovaHelper] ignoring back button event`);
      return;
    }
    console.info(`[CordovaHelper] Back button triggered`);
    getBindedActions().navigateBack();
  }
}
