import { MOBIGEO_PAGE_KEY } from 'src/pages/pagesKeys';

import config from 'app-customs/config/config';

import MobigeoPage from './MobigeoPage';
import reducer from './MobigeoPageReducer';

export default {
  mountOnce: true,
  key: MOBIGEO_PAGE_KEY,
  path: '/mobigeo',
  component: MobigeoPage,
  elId: 'mobigeo-page',
  getReduxReducer: (state, action) => reducer(state[MOBIGEO_PAGE_KEY], action),
  toggleMode: 'visibility',
  relatedDataToFetch: ['events', 'sideevents', 'events_categorie'],
  isGranted: () => config.MAP.MOBIGEO.FEATURE_ENABLED,
};
