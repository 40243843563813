import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

/**
 * Common component to display events or flights
 */
class DetailEvents extends PureComponent {
  render() {
    const length = Array.isArray(this.props.items) ? this.props.items.length : 0;

    // Skip component display
    if (length === 0) {
      return null;
    }

    const label = this.props.labels.data.events[length > 1 ? 'plural' : 'singular'];

    return (
      <div className="fiche-related-data clickable" onClick={this.props.onClick}>
        <div className="prop-img">
          <div className="prop-left">
            <div className="icon-font">
              <GenericIcon
                src="icons-svg/appToolBar/calendar-alt-regular.svg"
                className="link-icon-font-icon"
                st0Class="row-st0"
              ></GenericIcon>
            </div>
          </div>
          <div className="prop-right evenements colored-link">
            <div>
              <span className="count">{length}</span>
              <span>{label}</span>
            </div>
          </div>
          <div className="prop-right-fleche">
            <GenericIcon
              st0Class="chevron-right-icon-st0"
              src="icons-svg/control/chevron-droit-solid.svg"
              className="fleche chevron-right-icon"
            />
          </div>
        </div>
      </div>
    );
  }
}

DetailEvents.propTypes = {
  items: PropTypes.array,
  labels: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};
export default DetailEvents;
