import { PERFORM_LOGIN_KLIPSO_SSO, SET_LOGIN_SSO_STATUS } from 'src/store/actionTypes';

import { loginSSO, onUserLogInSSO } from './KlipsoSsoData';

let isLoggedInKlipsoSSo = false;

export default ({ dispatch, getState }) => (next) => (action) => {
  const result = next(action);

  switch (action.type) {
    case SET_LOGIN_SSO_STATUS:
      if (isLoggedInKlipsoSSo !== true && action.loggedInSSO) {
        onUserLogInSSO(action.sso);
      }
      isLoggedInKlipsoSSo = action.loggedInSSO;
      break;
    case PERFORM_LOGIN_KLIPSO_SSO:
      loginSSO();
      break;
    default: // for linter
  }

  return result;
};
