import React from 'react';
import PropTypes from 'prop-types';

import YouTube from 'react-youtube';
import './YoutubePlayer.scss';

const checkEmbedId = (_string) => {
  const string = typeof _string === 'string' ? _string : String(_string);
  if (string.indexOf('watch?v=') > -1) {
    const id = string.split('watch?v=')[1].split('&')[0];
    // return `https://www.youtube.com/embed/${id}?rel=0&modestbranding=1&autoplay=1`;
    return id;
  } else if (string.indexOf('embed') > -1) {
    return string.split('embed/')[1].split('?')[0];
  }
  return string;
};

/* function validVideoId(id) {
  var img = new Image();
  img.src = "http://img.youtube.com/vi/" + id + "/mqdefault.jpg";
  img.onload = function () {
    checkThumbnail(this.width);
  }
}

function checkThumbnail(width) {
  //HACK a mq thumbnail has width of 320.
  //if the video does not exist(therefore thumbnail don't exist), a default thumbnail of 120 width is returned.
  if (width === 120) {
   
  }
} */

const YoutubePlayer = ({ embedId, height, width, autoplay }) => {
  if (!embedId) {
    console.error('missing embedId for YouTube player');
    return;
  }
  const id = checkEmbedId(embedId);

  if (!id) {
    console.error('check embedId for YouTube player failed');
    return;
  }
  // const iframeCustom = () => (
  //   <div className="video_container">
  //     <iframe
  //       width={width}
  //       height={height}
  //       src={url}
  //       frameBorder="0"
  //       allow="autoplay; fullscreen"
  //       allowFullScreen
  //       title="Embedded youtube"
  //     />
  //   </div>
  // );
  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    // event.target.playVideo();
  };
  const opts = {
    height,
    width,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  return (
    <div className="video_container">
      <YouTube videoId={id} opts={opts} onReady={_onReady} />
    </div>
  );
};

YoutubePlayer.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubePlayer;
