import config from 'app-customs/config/config';

import { SHOW_MOBILESPOT_BUTTON } from 'app-customs/config/mobigeoConfig';

import {
  CONFIG_JSON_LOADED,
  POLL_CONFIG_LOADED,
  DATA_ASSETS_UPDATED,
  HAS_NAVIGATED,
  LANG_CHANGED,
  MOBIGEO_IS_RESTARTING,
  MOBIGEO_LOADED,
  MOBIGEO_RELOAD,
  PROFILE_CHANGED,
  TOGGLE_LOCATION_STATUS,
  TOGGLE_LOCATION_CAPABILITY_STATUS,
  TOGGLE_PMR_STATUS,
  TOGGLE_MENU,
  UPDATE_PAGE_STATE,
  WINDOW_RESIZED,
} from 'src/store/actionTypes';

import {
  configJsonLoaded,
  togglePageAfterNavigation,
  pollConfigLoaded,
  getPageCommonDefaultState,
  langChanged,
  profileChanged,
  toggleLocationStatus,
  toggleLocationCapabilityStatus,
  togglePMRStatus,
  toggleMenu,
  updateObject,
  updatePageState,
  windowResized,
} from 'src/store/reducers/commons';

import { MOBIGEO_PAGE_KEY } from 'src/pages/pagesKeys';

import MobigeoContext from './MobigeoContext';

const getDefaultState = () => ({
  ...getPageCommonDefaultState(MOBIGEO_PAGE_KEY),
  hasMobileSpotButton: SHOW_MOBILESPOT_BUTTON === true,
  mobigeoApiKey: config.MAP.MOBIGEO.API_KEY,
  searchButtonInToolbar: false, // override
});

/**
 * Mobigeo initialization:
 *   - App is ready for map loading when data is loaded
 *
 * Mobigeo restart if:
 *   - data has been updated OR at least an asset whose path contains 'files/maps/' has been updated
 *   - map is not currently displayed
 *
 * @param {boolean} value
 */
const _mapShouldRestart = (state, action, value) =>
  updateObject(state, { shouldRestart: MobigeoContext.isNotLoaded() ? false : value });


const _dataUpdated = (state, action) => {
  // Detect when app has initialized (meaning data and assets are ready for MobiGeo)
  if (state.isDataReady !== true) {
    return updateObject(state, { isDataReady: true });
  }
  return state;
};

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case CONFIG_JSON_LOADED:
      return configJsonLoaded(state);
    case HAS_NAVIGATED:
      return togglePageAfterNavigation(state, MOBIGEO_PAGE_KEY);
    case POLL_CONFIG_LOADED:
      return pollConfigLoaded(state, action);
    case DATA_ASSETS_UPDATED:
      return _dataUpdated(state, action);
    case LANG_CHANGED:
      return langChanged(state, action);
    case MOBIGEO_IS_RESTARTING:
      return _mapShouldRestart(state, action, false);
    case MOBIGEO_LOADED:
      return _mapShouldRestart(state, action, false);
    case MOBIGEO_RELOAD:
      return _mapShouldRestart(state, action, true);
    case PROFILE_CHANGED:
      return profileChanged(state, action, MOBIGEO_PAGE_KEY);
    case TOGGLE_LOCATION_STATUS:
      return toggleLocationStatus(state, action);
    case TOGGLE_LOCATION_CAPABILITY_STATUS:
      return toggleLocationCapabilityStatus(state, action);
    case TOGGLE_PMR_STATUS:
      return togglePMRStatus(state, action);
    case TOGGLE_MENU:
      return toggleMenu(state, action, MOBIGEO_PAGE_KEY); // TODO: menuOptions.disableSwipeToOpen: true ?
    case UPDATE_PAGE_STATE:
      return updatePageState(state, action, MOBIGEO_PAGE_KEY);
    case WINDOW_RESIZED:
      return windowResized(state, action);

    default:
      return state;
  }
};
