import { CHOOSE_PROFILE_PAGE_KEY } from 'src/pages/pagesKeys';

import ChooseProfilePage from './ChooseProfilePage';
import reducer from './chooseProfilePageReducer';

export default {
  key: CHOOSE_PROFILE_PAGE_KEY,
  path: '/choose-profile',
  component: ChooseProfilePage,
  elId: 'choose-profile-page',
  getReduxReducer: (state, action) => reducer(state[CHOOSE_PROFILE_PAGE_KEY], action),
  toggleMode: 'visibility', // NB: if HomePage `toggleMode` is set to display:none Masonry doesn't work great
};
