/**
 * This components is used to display ad banners defined in the asset app-react/app-customs/public/files/project/config.json
 *
 * Every entry in this config file has a unique key. e.g 'header', 'home', 'events'...
 *
 * These keys are related to pages (e.g 'events' describes the ad to display on the events categories list page)
 * By default the key is determined from the context (see props `associatedPageKey` and `listInputs`),
 */

// Libs
import React from 'react';
import PropTypes from 'prop-types';
import Measure from 'react-measure';
import { debounce } from 'lodash-custom';

// App modules
import Url from 'src/components/url/Url';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { getBestRessource } from 'src/core/config-json/ConfigJsonManager';
import { isAndroid, isIOS } from 'src/core/util/browser';

import './AdBanner.scss';

// const LOG_PREF = '[AdBanner] ';

class AdBanner extends React.PureComponent {
  state = {};

  onDimensionsUpdate = debounce((measure) => {
    this.setState({
      size: {
        width: measure.client.width - 2,
        height: measure.client.height,
      },
    });
  }, 50);

  calcHeightDevice(resourceH, resourceW, widthD) {
    if (isAndroid() || isIOS()) return (resourceH * widthD) / resourceW;
    return resourceH;
  }

  /**
   * The point is to create a new object only if value is different,
   * to avoid useless renders
   * @return {object}
   */
  getStyle(resource, availableSize) {
    let width;
    // To fit container, we allow a small deformation if below 12%
    if (availableSize.width / resource.width < 1.12) {
      width = availableSize.width;
    } else {
      width = resource.width;
    }

    if (!this._style || this._style.width !== width || this._style.height !== resource.height) {
      this._style = {
        width: width,
        height: this.calcHeightDevice(resource.height, resource.width, this.state.size.width),
      };
    }
    return this._style;
  }

  /**
   * Parse ad configuration, determine image, then wrap it with a Url component or handle internal redirection
   * @return {object} react element
   */
  wrapImage() {
    let ad = this.props.ad,
      lang = this.props.currentLang,
      style = {},
      image;

    if (ad) {
      /*
                x bgcolor: "#7D8A99"
                x lang   : Array(2)
                x link_en: "https://www.ontario.ca/page/government-ontario"
                x link_fr: "https://www.ontario.ca/fr/page/gouvernement-de-lontario"
                x name   : "Ontario"
                - target : "_blank"
            */
      if (ad.bgcolor) {
        style.backgroundColor = ad.bgcolor;
      }

      // File
      if (this.state.size && this.props.adFiles) {
        let resource = getBestRessource(
          this.props.adFiles[lang] ? this.props.adFiles[lang] : this.props.adFiles,
          this.state.size.width,
          this.state.size.height
        );
        if (resource) {
          style.height = this.calcHeightDevice(resource.height, resource.width, this.state.size.width);
          image = (
            <img
              src={getUrl(resource.file)}
              alt=""
              style={this.getStyle(resource, this.state.size)}
              target="_system"
            />
          );
        }
      }
    }

    if (!image) {
      return null;
    }

    // Handle internal redirection
    if (ad && ad.redirect !== null && typeof ad.redirect === 'object') {
      return (
        <div
          className="ad-banner ad-redirect"
          style={style}
          onClick={(e) => {
            this.props.actions.applyRedirect(ad.redirect, this.props.actions);
            this.props.actions.adClicked(ad, window.history.state.queryString);
          }}
        >
          {image}
        </div>
      );
    }

    // Open external url
    return (
      <Url
        callback={(url) => {
          this.props.actions.adClicked(ad, url);
        }}
        className="ad-banner"
        href={ad ? ad[`link_${lang}`] : null}
        style={style}
        target={ad.target}
        openInInAppBrowser={ad.openInInAppBrowser}
      >
        {image}
      </Url>
    );
  }

  render() {
    let content = this.wrapImage();
    return (
      <Measure client onResize={this.onDimensionsUpdate}>
        {({ measureRef }) => (
          <div ref={measureRef} className="ad-banner-container">
            {content}
          </div>
        )}
      </Measure>
    );
  }
}

AdBanner.propTypes = {
  ad: PropTypes.object,
  adFiles: PropTypes.object,
  currentLang: PropTypes.string,
  size: PropTypes.object,
  actions: PropTypes.object.isRequired,
};

export default AdBanner;
