import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Measure from 'react-measure';

import { LIST_GROUPS_PAGE_KEY } from 'src/pages/pagesKeys';

import Loader from 'src/components/loader/Loader';
import NoResult from 'src/components/no-result/NoResult';
import AdSwap from 'src/components/ad-swap/AdSwap';
import { parseListOfGroups } from 'src/components/list/listOfGroupsUtil';
import { isCordovaContext, isIphoneX } from 'src/core/util/browser';

const marginIphoneX = isCordovaContext() && isIphoneX() && 16;
class ListGroupsContent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      containerHeight : 0
    }
    this.refContainer = React.createRef();
  }
  componentDidMount(){
    const height = this.refContainer && this.refContainer.current.clientHeight
    this.setState({containerHeight: height})
  }

  componentDidUpdate(prevProps) {
    const groupedItemsHasChanged = prevProps.groupedItems !== this.props.groupedItems;
    const pageVisibilityHasChanged = this.props.isPageVisible && !prevProps.isPageVisible;

    // Autoscroll to an item
    if (
      (groupedItemsHasChanged || pageVisibilityHasChanged) &&
      this.props.itemIdToScrollTo &&
      typeof this.scrollToItemId === 'function'
    ) {
      this.scrollToItemId(this.props.itemIdToScrollTo);
    }
  }

  renderContent = () => {
    if (!this.props.groupedItems) {
      // No result
      if (this.props.itemNotFound === true) {
        return <NoResult labels={this.props.labels} />;

        // Pending request
      }
      if (this.props.isPending === true) {
        return <Loader labels={this.props.labels} />;
      }
      console.error(
        'Unexpected state (no events, and request is neither pending nor without result)'
      );
      return null;
    }

    const { content, sideIndex, scrollTo, scrollToItemId } = parseListOfGroups({
      groupedItems: this.props.groupedItems,
      dataType: this.props.dataType,
      favIconDisabled: this.props.favIconDisabled,
      isPending: this.props.isPending,
      favorites: this.props.favorites,
      pageKey: LIST_GROUPS_PAGE_KEY,
      actions: this.props.actions,
      labels: this.props.labels,
    });

    this.scrollTo = scrollTo;
    this.scrollToItemId = scrollToItemId;

    return (
      <div
        style={{
          height:
          this.state.containerHeight - (this.state.adHeight + marginIphoneX),
        }}
        className="list-groups-container content-font"
      >
        {content}
        {sideIndex}
      </div>
    );
  };

  onDimensionsUpdate = (measure, state) => {
    this.setState({ [state]: measure.client.height });
  };

  render() {
    return (
      <div
        ref={this.refContainer}
        className={'content-below-apptoolbar ' + (this.props.className || '')}
      >
        {this.renderContent()}
        <Measure
          client
          onResize={(messureAdHeight) => this.onDimensionsUpdate(messureAdHeight, 'adHeight')}
        >
          {({ measureRef: measureRefAd }) => (
            <div ref={measureRefAd}>
              <AdSwap
                adBundleAttributionKey={this.props.adBundleAttributionKey}
                isPageVisible={this.props.isPageVisible}
                isAppVisible={this.props.isAppVisible}
              />
            </div>
          )}
        </Measure>
      </div>
    );
  }
}

ListGroupsContent.propTypes = {
  isPending: PropTypes.bool,
  itemNotFound: PropTypes.bool,
  groupedItems: PropTypes.object,
  dataType: PropTypes.string,
  favorites: PropTypes.object,
  itemIdToScrollTo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  favIconDisabled: PropTypes.bool,
  adBundleAttributionKey: PropTypes.string,
  ad: PropTypes.object,
  isPageVisible: PropTypes.bool,
  isAppVisible: PropTypes.bool,
  // Common
  queryString: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default ListGroupsContent;
