import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { throttle } from 'lodash-custom';

import { DATA_TYPE_EVENTS } from 'app-customs/config/dataConfig';
import { SYNOPTIC_AGENDA_PAGE_KEY } from 'src/pages/pagesKeys';

import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import Menu from 'src/components/menu/Menu';
import ContextualSearch from 'src/components/contextual-search/ContextualSearch';
import SynopticAgendaContent from './SynopticAgendaContent';
import { isIOS, isFirefox } from 'src/core/util/browser';
import scrollHack from 'src/core/util/scrollHack';

import * as actions from 'src/store/actions';

import './SynopticAgendaPage.scss';

const LOG_PREF = '[SynopticAgendaPage] ';

class SynopticAgendaPage extends Component {
  pageKey = SYNOPTIC_AGENDA_PAGE_KEY;

  getPageTitle() {
    if (this.props.labels.data[DATA_TYPE_EVENTS]) {
      return this.props.labels.data[DATA_TYPE_EVENTS].plural;
    }
    return '';
  }

  /**
   * Update string visible in browser tab/history/favorites
   */
  setDocumentContext() {
    if (this.props.isActive(this.pageKey)) {
      this.props.setDocumentContext(this.getPageTitle());
    }
  }

  componentDidMount() {
    this.fetchIfNeeded(this.props);
  }
  componentDidUpdate() {
    this.setDocumentContext();
    this.fetchIfNeeded(this.props);
  }

  setContainerEl = (el) => {
    if (el) {
      this.eventsContainer = el;
      if (isIOS() || isFirefox()) {
        this.scroller = scrollHack(this.eventsContainer);
      }
    }
  };

  scrollTo = throttle((offset, indexKey) => {
    if (this.scroller) {
      this.scroller(offset);
    } else if (this.eventsContainer) {
      this.eventsContainer.scrollTop = offset;
    }
  }, 80);

  /**
   * Fetch data if :
   *  - data has been updated (shouldFetch: true)
   *  - or if we have no data and request is neither pending nor without result
   */
  fetchIfNeeded(props) {
    if (!props.groupedItems) {
      props.actions.fetchGroupedItems(
        this.props.agendaProps ? this.props.agendaProps.input : { dataType: DATA_TYPE_EVENTS },
        ['eventType']
      );
    }
  }

  isPageVisible = () => this.props.isVisible;

  renderChildren = () => {
    if (this.props.searchResults) {
      return (
        <ContextualSearch
          items={this.props.searchResults}
          favorites={this.props.favorites}
          keyboardHeight={this.props.keyboardHeight}
          labels={this.props.labels}
          actions={this.props.actions}
          pageKey={this.pageKey}
        />
      );
    }

    return (
      <SynopticAgendaContent
        // Fetch status props
        isPending={this.props.isPending}
        itemNotFound={this.props.itemNotFound}
        index={this.props.index}
        synoConfig={this.props.synoConfig}
        tabsData={this.props.tabsData}
        // Content props
        groupedItems={this.props.groupedItems}
        eventsByDayByPlaces={this.props.eventsByDayByPlaces}
        // Common
        labels={this.props.labels}
        actions={this.props.actions}
        queryString={this.props.queryString}
        isPageVisible={this.isPageVisible}
      />
    );
  };

  render() {
    console.log(LOG_PREF + 'render');
    // Keep UI exactly how the user left it
    // if (!this.props.isVisible) return null;

    return (
      <>
        <Menu
          options={this.props.menuOptions}
          actions={this.props.actions}
          labels={this.props.labels}
          profile={this.props.profile}
          associatedPageKey={this.pageKey}
          adConfig={this.props.adConfig}
          twoColumns={this.props.twocolumns}
          isLocationEnabled={this.props.isLocationEnabled}
        />

        <AppToolbar
          labels={this.props.labels}
          isDisplayed={this.props.hasToolbar}
          actions={this.props.actions}
          title={this.getPageTitle()}
          contextualTitle={this.props.contextualTitle}
          contextualDataTypes={[DATA_TYPE_EVENTS]}
          searchFieldVisible={this.props.searchFieldVisible}
          searchResults={this.props.searchResults}
          pageKey={this.pageKey}
          profile={this.props.profile}
          hasBackButton={this.props.backButtonInToolbar}
          listToolBarButton={this.props.listToolBarButton}
          listConfigToolBarButton={this.props.listConfigToolBarButton}
          hasHomeButton={this.props.homeButtonInToolbar}
          hasFavButton={this.props.favButtonInToolbar}
          hasSearchButton={this.props.searchButtonInToolbar}
          hasMenuButton={this.props.menuButtonInToolbar}
          hasBackToAgendaButton={true}
          agendaProps={this.props.agendaProps}
          theme="dark"
        />

        {this.renderChildren()}
      </>
    );
  }
}

SynopticAgendaPage.propTypes = {
  config: PropTypes.object,
  input: PropTypes.object,
  groupedItems: PropTypes.object,
  eventsByDayByPlaces: PropTypes.object,
  tabsData: PropTypes.array,
  isPending: PropTypes.bool,
  itemNotFound: PropTypes.bool,
  // Common page props
  queryString: PropTypes.string.isRequired,
  menuOptions: PropTypes.object.isRequired,
  profile: PropTypes.string,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  isActive: PropTypes.func.isRequired,
  contextualTitle: PropTypes.string,
  setDocumentContext: PropTypes.func.isRequired,
  adConfig: PropTypes.object,
  isLocationEnabled: PropTypes.bool,
  searchFieldVisible: PropTypes.bool,
  searchResults: PropTypes.object,
  keyboardHeight: PropTypes.number,
  isVisible: PropTypes.bool, // set by togglePageAfterNavigation common reducer function
  // toolbar
  hasToolbar: PropTypes.bool,
  homeButtonInToolbar: PropTypes.bool,
  backButtonInToolbar: PropTypes.bool,
  searchButtonInToolbar: PropTypes.bool,
  favButtonInToolbar: PropTypes.bool,
  menuButtonInToolbar: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => state[SYNOPTIC_AGENDA_PAGE_KEY];
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(SynopticAgendaPage);
