import { EXHIBITOR_PAGE_KEY } from 'src/pages/pagesKeys';

import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';
import ExhibitorContent from './ExhibitorContent';

export default {
  key: EXHIBITOR_PAGE_KEY,
  path: '/exhibitor',
  elId: DOM_ID,
  className: 'exhibitor-page',
  component: GenericItemPage,
  childComponent: ExhibitorContent,
  relatedDataToFetch: [
    'country',
    'cats',
    'newproducts',
    'places',
    'gmapplaces',
    'events',
    'aircrafts',
    'docunits',
    'joboffers',
  ],
};
