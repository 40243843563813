import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { DATA_TYPE_NEWPRODUCTS, DATA_TYPE_EXHIBITORS } from 'app-customs/config/dataConfig';
import { NEWPRODUCT_PAGE_KEY } from 'src/pages/pagesKeys';

import List from 'src/components/list/List';
import DetailNewProducts from 'src/components/detail-newproducts/DetailNewProducts';
import DetailPlaces from 'src/components/detail-places/DetailPlaces';
import DetailPdfs from 'src/components/detail-pdfs/DetailPdfs';
import DetailSocialButton from 'src/components/detail-social-button/DetailSocialButton';
import DetailExhibitor from 'src/components/detail-exhibitor/DetailExhibitor';
import DetailFixedTitle from 'src/components/detail-fixed-title/DetailFixedTitle';
import Description from 'src/components/fiche/Description';
import WebsiteRow from 'src/components/fiche/WebsiteRow';
import ShareButton from 'src/components/share-button/ShareButton';
import NoteButton from 'src/components/notes/NoteButton';

import '../common-fiche.scss';
import './NewProductPage.scss';

class NewProductContent extends PureComponent {
  setScrollableContentEl = (el) => {
    this.contentContainerEl = el;
  };
  scrollTo = (value) => {
    if (this.contentContainerEl) {
      this.contentContainerEl.scrollTop = value;
    }
  };

  onClickOnFavIcon = () => {
    this.props.actions.toggleFavorite(
      this.props.item.id,
      DATA_TYPE_NEWPRODUCTS,
      this.props.isFavorite
    );
  };

  render() {
    const title = this.props.item.title,
      logoUrl = this.props.item.photo_file_name,
      description = this.props.item.description;
    const hasPlaces =
      Array.isArray(this.props.item.references.places) &&
      this.props.item.references.places.length > 0;
    return (
      <div className="fiche newproduct content-font content-below-apptoolbar">
        {/* title */}
        <DetailFixedTitle
          title={title}
          dataType={DATA_TYPE_NEWPRODUCTS}
          isFav={this.props.isFavorite}
          onFavClick={this.onClickOnFavIcon}
          labels={this.props.labels}
        >
          <NoteButton
            itemTitle={this.props.pageTitle}
            itemId={this.props.item.id}
            dataType={DATA_TYPE_NEWPRODUCTS}
            hasNote={this.props.hasNote}
            labels={this.props.labels}
            actions={this.props.actions}
          />
          <ShareButton
            name={this.props.pageTitle}
            queryString={this.props.queryString}
            description={description}
            image={logoUrl}
            labels={this.props.labels}
            actions={this.props.actions}
            profile={this.props.profile}
            pageKey={NEWPRODUCT_PAGE_KEY}
          />
        </DetailFixedTitle>

        <div className="content" ref={this.setScrollableContentEl}>
          <div className="all-informations content-font">
            <Description
              logoFileName={logoUrl}
              video={this.props.item?.lump?.video}
              description={description}
              dataType={DATA_TYPE_NEWPRODUCTS}
              isLogoFullWidth={this.props.isLogoFullWidth}
            />

            {/* Exhibitor*/}
            <DetailExhibitor
              exhibitor={this.props.item.references.exhibitor}
              actions={this.props.actions}
              labels={this.props.labels}
            />

            {this.props.item.references && this.props.item.references?.exhibitors && (
              <div>
                <div className="fiche-separator">{this.props.labels.data.exhibitors.plural}</div>
                <List
                  items={this.props.item?.references?.exhibitors}
                  dataType={DATA_TYPE_EXHIBITORS}
                  actions={this.props.actions}
                  labels={this.props.labels}
                  displayFavorites={false}
                  pageKey={NEWPRODUCT_PAGE_KEY}
                />
              </div>
            )}

            {/* Market date */}
            {this.props.item.lump.date_market && (
              <div className="free-row">
                <div className="prop-img">
                  <div className="prop-left label">
                    <div className="color-grey-dark">
                      <span>{this.props.labels.newproducts.launchDate}</span>
                    </div>
                  </div>
                  <div className="prop-right name-label">
                    <div className="color-grey-dark ">
                      <span>{this.props.item.lump.date_market}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {hasPlaces && (
              <div>
                <DetailPlaces
                  labels={this.props.labels}
                  actions={this.props.actions}
                  id={this.props.item.id}
                  originalId={this.props.item.original_id}
                  entityType={DATA_TYPE_NEWPRODUCTS}
                  places={
                    this.props.item?.lump?.oneMapButton ? [] : this.props.item.references.places
                  }
                />
              </div>
            )}

            <WebsiteRow website={this.props.item.website} actions={this.props.actions} />

            {this.props.item.lump?.pdfs && this.props.item.lump?.pdfs?.length > 0 && (
              <>
                <div className="fiche-separator">{this.props.labels.pdfs.title}</div>
                <DetailPdfs
                  data={this.props.item.lump.pdfs}
                  actions={this.props.actions}
                  labels={this.props.labels}
                />
              </>
            )}

            <DetailSocialButton
              data={this.props.item.lump.social_button}
              actions={this.props.actions}
              labels={this.props.labels}
            />

            {/*suggestions newproducts */}
            <DetailNewProducts
              items={this.props.item.references.newproduct_suggestions}
              actions={this.props.actions}
              labels={this.props.labels}
              pageKey={NEWPRODUCT_PAGE_KEY}
            />
          </div>
        </div>
      </div>
    );
  }
}

NewProductContent.propTypes = {
  item: PropTypes.object,
  isFavorite: PropTypes.bool,
  isLogoFullWidth: PropTypes.bool,
  // Common
  queryString: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default NewProductContent;
