import { SHOW_FORM_MODAL, HIDE_FORM_MODAL, KEYBOARD_TOGGLED } from 'src/store/actionTypes';
import { langChanged, updateObject, updateKeyboardState } from 'src/store/reducers/commons';

import { setDialogConfig } from 'src/core/dialog/DialogPersistence';

import { get as getLabels } from 'src/core/Lang';

const getDefaultState = () => ({
  labels: getLabels(),
});

const _toggleModal = (state, action, value) => {
  setDialogConfig({ isOpen: value, actionName: value ? 'hideFormModal' : '' });

  return updateObject(state, {
    isOpen: value,
    form: action.form,
    formAction: action.formAction,
    formActionPayload: action.formActionPayload,
    liEl: action.liEl,
    labels: getLabels(),
  });
};

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case SHOW_FORM_MODAL:
      return _toggleModal(state, action, true);
    case HIDE_FORM_MODAL:
      return _toggleModal(state, action, false);
    case KEYBOARD_TOGGLED:
      return updateKeyboardState(state, action);
    default:
      return state;
  }
};
