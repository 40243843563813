import React from 'react';
import PropTypes from 'prop-types';
import IphonexFlexMargin from 'src/components/iphonex-flex-margin/IphonexFlexMargin';

import './Footer.scss';

function Footer({ children, onClick, justifyContent }) {
  return (
    <>
      <div
        className="klipso-leads-footer content-font"
        onClick={onClick}
        style={typeof justifyContent === 'string' ? { justifyContent } : null}
      >
        {children}
      </div>
      <IphonexFlexMargin />
    </>
  );
}

Footer.propTypes = {
  onClick: PropTypes.func,
  justifyContent: PropTypes.string, // ability to override default justify-content
};

export default Footer;
