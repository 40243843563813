import React from 'react';

import './Content.scss';

const Content = ({ children }) => (
  <div className="kl-content-wrapper">
    <div className="kl-content content-font">{children}</div>
  </div>
);

export default Content;
