import { MEDIAS_PAGE_KEY } from 'src/pages/pagesKeys';

import MediasPage from './MediasPage';
import reducer from './mediasPageReducer';

export default {
  key: MEDIAS_PAGE_KEY,
  path: '/medias',
  component: MediasPage,
  elId: 'medias-page',
  getReduxReducer: (state, action) => reducer(state[MEDIAS_PAGE_KEY], action),
};
