import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';
import { getBindedActions } from 'src/store/bindedActions';

const LINKEDIN_SHARE_API_URL = '/people/~/shares?format=json';

/**
 * Handle LINKEDIN success
 * @param  {object} labels    labels for current lang
 */
function displaySuccess(labels) {
  getBindedActions().showNotification({
    message: labels.share.linkedinShareDone,
  });
}

/**
 * Handle LINKEDIN error
 * @param  {object} e
 * @param  {object} labels    labels for current lang
 */
function displayError(e, labels) {
  console.error('Failed to share on linkedin', e);

  getBindedActions().showNotification({
    message: labels.share.linkedinShareFail + (e.message ? ` (${e.message})` : ''),
    level: NotificationLevels.ERROR,
  });
}

/**
 * Share on LINKEDIN
 *
 * @param  {string} name
 * @param  {string} description
 * @param  {string} url
 * @param  {string} image
 * @param  {object} labels      labels for current lang
 */
function share(name, description, url, image, labels) {
  if (!window.IN) {
    console.error('Cannot share. Missing LinkedIn Javascript SDK');
    return;
  }

  if (window.IN.User.isAuthorized() !== true) {
    window.IN.User.authorize(
      // Then call again the function with the same arguments
      share(name, description, url, image, labels)
    );
  } else {
    window.IN.API.Raw(LINKEDIN_SHARE_API_URL)
      .method('POST')
      .body(
        JSON.stringify({
          comment: name,
          content: {
            title: name,
            description,
            'submitted-url': url,
            'submitted-image-url': getUrl(image),
          },
          visibility: {
            code: 'anyone',
          },
        })
      )
      .result(() => {
        displaySuccess(labels);
      })
      .error((e) => {
        displayError(e, labels);
      });
  }
}

export default share;
