import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dialog from 'src/components/dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import config from 'app-customs/config/config';

import fbShare from 'src/core/share/FacebookWebShare';
import liShare from 'src/core/share/LinkedInWebShare';

import * as actions from 'src/store/actions';

import './ShareDialog.scss';

import { isIOS, isAndroid } from 'src/core/util/browser';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

export const COMPONENT_KEY = 'ShareDialog';
export const CONTAINER_DOM_ID = 'share-dialog-container';

class ShareDialog extends PureComponent {
  state = {
    isDialogOpen: false,
  };

  shareOnFacebook = () => {
    fbShare(
      this.props.name,
      this.props.description,
      this.props.url,
      this.props.image,
      this.props.labels
    );
  };
  shareOnLinkedIn = () => {
    liShare(
      this.props.name,
      this.props.description,
      this.props.url,
      this.props.image,
      this.props.labels
    );
  };
  openLink(url, target) {
    window.open(url, target);
  }

  render() {
    if (!this.props.name || !this.props.url) {
      return null;
    }

    let encodedName = encodeURIComponent(this.props.name),
      encodedUrl = encodeURIComponent(this.props.url);

    let smsEncodedUrl = isIOS() ? encodedUrl : encodeURIComponent(encodedUrl);
    let varSeparator = isIOS() ? '&' : '?';

    let target = global.isCordovaContext ? '_system' : '_blank';

    return (
      <Dialog
        open={this.props.isOpen} 
        onClose={this.props.actions.hideShareDialog}  // important, to update `open` value in all cases
      >
        <DialogTitle className="title-font modal-title">
          {this.props.labels.common.share}
        </DialogTitle>

        <div className="generic-modal-content share-buttons">
          {(isIOS() || isAndroid()) && (
            <GenericIcon 
              layout='a'
              href={`sms:${varSeparator}body=${encodedName}%0A${smsEncodedUrl}%0A${this.props.description}`}
              className="share-btn-sms sms-icon"
              title={this.props.labels.share.sms}
            >
            </GenericIcon>
          )}

          {/* Email */}
          {/* beware of `mailto:` length limitation (that's why description is at the end, it can be truncated) */}
          <GenericIcon
            layout='a'
            onClick={() =>
              this.openLink(
                `mailto:?subject=${encodedName}&body=${encodedName}%0A${encodedUrl}%0A${this.props.description}`,
                target
              )
            }
            className="share-btn-mail envelope-square-icon"
            title={this.props.labels.share.mail}
          >
          </GenericIcon>

          {/* Facebook */}
          {config.FACEBOOK_APP_ID && (
            <GenericIcon
              layout='a'
              onClick={() => this.shareOnFacebook}
              className="share-btn-facebook facebook-icon"
              title={this.props.labels.share.facebook}
            >
            </GenericIcon>
          )}

          {/* LinkedIn */}
          {config.LINKEDIN_API_KEY && (
            <GenericIcon
              layout='a'
              onClick={() => this.shareOnLinkedIn}
              className="share-btn-linkedin linkedin-icon"
              title={this.props.labels.share.linkedin}
            >
            </GenericIcon>
          )}

          {/* Twitter */}
          <GenericIcon
            layout='a'
            onClick={() =>
              this.openLink(
                `https://twitter.com/share?url=${encodedUrl}&text=${encodedName}&hashtags=`,
                '_system'
              )
            }
            rel="noopener noreferrer"
            className="share-btn-twitter twitter-icon"
            title={this.props.labels.share.twitter}
          >
          </GenericIcon>
        </div>
      </Dialog>
    );
  }
}

ShareDialog.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => state[COMPONENT_KEY];
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(ShareDialog);
