import { WINDOW_RESIZED, KEYBOARD_TOGGLED } from 'src/store/actionTypes';

import { windowResized, updateKeyboardState } from 'src/store/reducers/commons';

const getDefaultState = () => ({});

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case WINDOW_RESIZED:
      return windowResized(state, action);
    case KEYBOARD_TOGGLED:
      return updateKeyboardState(state, action);
    default:
      return state;
  }
};
