import { CONTACT_PAGE_KEY } from 'src/pages/pagesKeys';

import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';

import { isSessionValid } from 'src/core/login/LoginService';
import ContactContent from './ContactContent';

export default {
  key: CONTACT_PAGE_KEY,
  path: '/contact',
  elId: DOM_ID,
  className: 'contact-page',
  component: GenericItemPage,
  childComponent: ContactContent,
  relatedDataToFetch: [],
  isGranted: () => isSessionValid(),
};
