import React from 'react';
import PropTypes from 'prop-types';

import { VALID_DATA_TYPES, DATA_TYPE_GMAP_PLACES } from 'app-customs/config/dataConfig';
import { hashCode } from 'src/core/util/StringUtil';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';

import { isCordovaContext } from 'src/core/util/browser';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

const renderMapIcon = () => {
  return (
    <GenericIcon
      src={'icons-svg/fiche/map.svg'}
      st1Class="row-st0"
      st0Class="row-st0"
      className="map-row"
    />
  );
};

/**
 * Common component to display places
 */
const DetailPlaces = ({
  id,
  originalId,
  entityType,
  places,
  textPlaces,
  labels,
  actions,
  defaultLabel,
}) => {
  if ((Array.isArray(places) !== true || places.length === 0) && !defaultLabel) {
    if (textPlaces)
      return (
        <div className="fiche-related-data">
          <div className="prop-img">
            <div className="prop-left">{renderMapIcon()}</div>
            <div className="prop-right location">
              <span>{textPlaces}</span>
            </div>
          </div>
        </div>
      );
    return null;
  }

  const placesPerLabel = {};

  places.forEach((place) => {
    // Skip invalid items
    if (!place) {
      return;
    }

    const label = place.label || labels.common.defaultPlaceLabel;
    let index = label;
    const { info } = place;

    if (info) {
      index += hashCode(info);
    }

    if (!placesPerLabel[index]) {
      placesPerLabel[index] = [];
    }
    placesPerLabel[index].push({
      id: place.id,
      info: place.info,
      label: entityType === DATA_TYPE_GMAP_PLACES ? place.address : label,
    });
  });

  return Object.keys(placesPerLabel).length === 0 ? (
    <div className="fiche-related-data">
      <div className="prop-img">
        <div className="prop-left">{renderMapIcon()}</div>
        <div className="prop-right location">
          <span dangerouslySetInnerHTML={{ __html: defaultLabel }}></span> {/** PE2-12 */}
        </div>
      </div>
    </div>
  ) : (
    <div className="fiche-related-data">
      <div className="prop-img">
        <div className="prop-left">
          {entityType === DATA_TYPE_GMAP_PLACES && isCordovaContext() ? (
            <div className="icon-font">
              <img src={getUrl('files/project/misc/Map-Pin.svg')} alt="marker" />
            </div>
          ) : (
            renderMapIcon()
          )}
        </div>
        <div className="prop-right">
          <div>
            <ul className="localisations">
              {Object.keys(placesPerLabel).map((index) => {
                return (
                  <li
                    key={index}
                    className={
                      entityType === DATA_TYPE_GMAP_PLACES && !isCordovaContext() ? '' : 'clickable'
                    }
                    onClick={() => {
                      const POI = {
                        id,
                        originalId,
                        type: entityType,
                      };
                      if (placesPerLabel[index].length === 1) {
                        POI.placeId = placesPerLabel[index][0].id;
                      } else {
                        // FIXME:
                        // generate as much POI objects as there are places
                        // let pois = [{id: originalId, type: entityType, placeId: placeId1}, {id: originalId, type: entityType, placeId: placeId2}, ...]
                        // finally call actions.showAllPoisOnMobigeo(pois);
                      }
                      if (entityType === DATA_TYPE_GMAP_PLACES && !isCordovaContext()) {
                      } else if (entityType === DATA_TYPE_GMAP_PLACES && isCordovaContext()) {
                        actions.showOnePoiOnGoogleMap(POI);
                      } else {
                        actions.showOnePoiOnMobigeoWithoutNavigation(POI);
                      }
                    }}
                  >
                    <span
                      className="colored-link"
                      dangerouslySetInnerHTML={{ __html: placesPerLabel[index][0].label }}
                    ></span>
                    {placesPerLabel[index][0].info && (
                      <span className="info">{placesPerLabel[index][0].info}</span>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

DetailPlaces.propTypes = {
  originalId: PropTypes.string.isRequired,
  entityType: PropTypes.oneOf(VALID_DATA_TYPES).isRequired,
  places: PropTypes.array,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  defaultLabel: PropTypes.string,
  textPlaces: PropTypes.string,
};

export default DetailPlaces;
