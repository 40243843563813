import { getStyle } from 'app-customs/config/homeConfig';
import { get as getConfig } from 'app-customs/config/profileChooseConfig';
import { CHOOSE_PROFILE_PAGE_KEY } from 'src/pages/pagesKeys';
import { getProfileCodes } from 'src/core/config-json/ConfigJsonManager';

import {
  CONFIG_JSON_LOADED,
  CORDOVA_PAUSE,
  CORDOVA_RESUME,
  DOCUMENT_VISIBLE,
  HAS_NAVIGATED,
  LANG_CHANGED,
  PROFILE_CHANGED,
  UPDATE_PAGE_STATE,
  WINDOW_RESIZED,
} from 'src/store/actionTypes';

import {
  getPageCommonDefaultState,
  togglePageAfterNavigation,
  langChanged,
  profileChanged,
  setAppVisible,
  updateObject,
  updatePageState,
  windowResized,
} from 'src/store/reducers/commons';

const getDefaultState = () => ({
  ...getPageCommonDefaultState(CHOOSE_PROFILE_PAGE_KEY),
  pageStyle: getStyle(),
  configGetter: getConfig,
});

function _configJsonLoaded(state) {
  return updateObject(state, {
    profilecodes: getProfileCodes(),
  });
}

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case CONFIG_JSON_LOADED:
      return _configJsonLoaded(state);
    case CORDOVA_PAUSE:
      return setAppVisible(state, false);
    case CORDOVA_RESUME:
      return setAppVisible(state, true);
    case DOCUMENT_VISIBLE:
      return setAppVisible(state, action.value);
    case HAS_NAVIGATED:
      return togglePageAfterNavigation(state, CHOOSE_PROFILE_PAGE_KEY);
    case LANG_CHANGED:
      return langChanged(state, action);
    case PROFILE_CHANGED:
      return profileChanged(state, action);
    case UPDATE_PAGE_STATE:
      return updatePageState(state, action, CHOOSE_PROFILE_PAGE_KEY);
    case WINDOW_RESIZED:
      return windowResized(state, action);

    default:
      return state;
  }
};
