import React from 'react';
import PropTypes from 'prop-types';

import Url from 'src/components/url/Url';

import AdSwap from 'src/components/ad-swap/AdSwap';
import { getAdBundleAttributionKeyForPage } from 'src/core/config-json/ConfigJsonManager';
import { MEDIAS_PAGE_KEY } from 'src/pages/pagesKeys';
import IphonexFlexMargin from 'src/components/iphonex-flex-margin/IphonexFlexMargin';

import './MediasPageContentBasic.scss';

class MediasPageContent extends React.PureComponent {
  render() {
    return (
      <div className="media-content-container-basic content-font content-below-apptoolbar">
        <div className="media-content-label">{this.props.labels.medias.label}</div>

        <div className="media-buttons-container">
          {this.props.mediaButtons &&
            this.props.mediaButtons.map((btn) => (
              <Url
                callback={this.props.actions.linkClicked}
                key={btn.key}
                href={btn.link}
                target={global.isCordovaContext === true ? '_system' : btn.target || '_system'}
                className="basic-media-button"
              >
                <div style={{ backgroundImage: `url(${btn.image})` }} />
              </Url>
            ))}
        </div>

        <AdSwap
          adBundleAttributionKey={getAdBundleAttributionKeyForPage(MEDIAS_PAGE_KEY)}
          isPageVisible={this.props.isPageVisible}
          isAppVisible={this.props.isAppVisible}
        />
        <IphonexFlexMargin />
      </div>
    );
  }
}

MediasPageContent.propTypes = {
  mediaButtons: PropTypes.object,
  adConfig: PropTypes.object,
  actions: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
};

export default MediasPageContent;
