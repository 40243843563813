// Libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// App modules
import AdSwap from 'src/components/ad-swap/AdSwap';
import ChooseLangInline from 'src/components/choose-lang-inline/ChooseLangInline';
import ChooseProfileButtons from './ChooseProfileButtons';
import { CHOOSE_PROFILE_PAGE_KEY } from 'src/pages/pagesKeys';
import { getPageHeight } from 'src/core/util/DomTools';
import { isIphoneX } from 'src/core/util/browser';
import { getAdBundleAttributionKeyForPage } from 'src/core/config-json/ConfigJsonManager';

import * as actions from 'src/store/actions';

import './ChooseProfilePage.scss';

const LOG_PREF = '[ChooseProfilePage] ';

class ChooseProfilePage extends Component {
  pageKey = CHOOSE_PROFILE_PAGE_KEY;

  /**
   * Update string visible in browser tab/history/favorites
   */
  setDocumentContext() {
    if (this.props.isActive(this.pageKey)) {
      this.props.setDocumentContext(this.props.labels.profile.title);
    }
  }

  /**
   * String displayed in app toolbar
   * @return {string}
   */
  getPageTitle() {
    return this.props.labels.profile.title;
  }

  componentDidMount() {
    this.setDocumentContext();
  }

  componentDidUpdate() {
    this.setDocumentContext();
  }

  render() {
    console.log(LOG_PREF + 'render');

    // Keep rendering (ad render 'glitch')
    // if (!this.props.isVisible) return null;

    return (
      <div
        id="profile-page-content"
        className="content-font"
        style={this.props.pageStyle}
        onTouchStart={this.onTouchStartHandler}
      >
        <AdSwap
          adBundleAttributionKey={getAdBundleAttributionKeyForPage(this.pageKey)}
          isPageVisible={this.props.isVisible}
          isAppVisible={this.props.isAppVisible}
        />

        <ChooseLangInline labels={this.props.labels} actions={this.props.actions} />

        <div className="profile-select-label">
          <span>{this.props.labels.profile.mainLabel}</span>
        </div>

        <ChooseProfileButtons
          config={this.props.configGetter()}
          actions={this.props.actions}
          labels={this.props.labels}
          thenNavigateTo={this.props.thenNavigateTo}
          profilecodes={this.props.profilecodes}
        />
      </div>
    );
  }
}

ChooseProfilePage.propTypes = {
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  configGetter: PropTypes.func.isRequired,
  adConfig: PropTypes.object,
  profilecodes: PropTypes.object,
  pageStyle: PropTypes.object,
  isActive: PropTypes.func.isRequired,
  isVisible: PropTypes.bool, // set by togglePageAfterNavigation common reducer function
  isAppVisible: PropTypes.bool,
  thenNavigateTo: PropTypes.object,
  setDocumentContext: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => state[CHOOSE_PROFILE_PAGE_KEY];
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(ChooseProfilePage);
