import {
  HIDE_IMAGE_UPLOAD_DIALOG,
  SHOW_IMAGE_UPLOAD_DIALOG,
  HIDE_INPUT_MODAL_DIALOG,
  SHOW_INPUT_MODAL_DIALOG,
} from 'src/store/actionTypes';

import { setDialogConfig } from 'src/core/dialog/DialogPersistence';

import { updateObject } from 'src/store/reducers/commons';

const getDefaultState = () => ({
  isOpenImageUploadDialog: false,
  isOpenInputModalDialog: false,
});

const _toggleDialogImageUpload = (state, action, value) => {
  setDialogConfig({ isOpen: value, actionName: value ? 'hideImageUploadDialog' : '' });
  return updateObject(state, { isOpenImageUploadDialog: value });
};

const _toggleDialogInputModal = (state, action, value) => {
  setDialogConfig({ isOpen: value, actionName: value ? 'hideInputModaldDialog' : '' });
  return updateObject(state, { isOpenInputModalDialog: value });
};

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case HIDE_IMAGE_UPLOAD_DIALOG:
      return _toggleDialogImageUpload(state, action, false);
    case SHOW_IMAGE_UPLOAD_DIALOG:
      return _toggleDialogImageUpload(state, action, true);

    case HIDE_INPUT_MODAL_DIALOG:
      return _toggleDialogInputModal(state, action, false);
    case SHOW_INPUT_MODAL_DIALOG:
      return _toggleDialogInputModal(state, action, true);

    default:
      return state;
  }
};
