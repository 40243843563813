import React from 'react';
import PropTypes from 'prop-types';

import CTAButton from 'src/components/cta-button/CTAButton';

const AllOnMapButton = (props) =>
  props.data !== undefined ||
  props.data === null ||
  typeof props.data !== 'object' ||
  Object.keys(props.data).length === 0 ? null : (
    <CTAButton
      action={() => {
        props.actions.showAllPoisOnMobigeo(props.data);
      }}
      label={props.labels.common.showAll}
    />
  );

AllOnMapButton.propTypes = {
  data: PropTypes.object,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default AllOnMapButton;
