const EFFECT_CSS_CLASS = 'animate';

// Warning, transition duration is already defined in css (@see tile-click-effect.scss)
const EFFECT_DURATION = 200; // ms

let appRootContainer;

export const setRootContainer = (el) => {
  appRootContainer = el;
};

/**
 * Apply a visual effect on the button
 * @param {DOM node} el
 * @param {string}   parentClassName: parent on which `EFFECT_CSS_CLASS` must be applied
 * @param {Function} next
 */
export const apply = (eventTarget, parentClassName, next) => {
  let el = eventTarget;

  // event.target is often one of the elements inside the button (e.g. label)
  // if app root container is reached, failed to find the element
  while (el.classList.contains(parentClassName) !== true && el.id !== appRootContainer.id) {
    el = el.parentNode;
  }

  el.classList.add(EFFECT_CSS_CLASS);
  window.setTimeout(() => {
    el.classList.remove(EFFECT_CSS_CLASS);

    if (typeof next === 'function') {
      next();
    }
  }, EFFECT_DURATION);
};
