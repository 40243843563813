import { SERVICE_PAGE_KEY } from 'src/pages/pagesKeys';

import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';
import ServiceContent from './ServiceContent';

export default {
  key: SERVICE_PAGE_KEY,
  path: '/service',
  elId: DOM_ID,
  className: 'service-page',
  component: GenericItemPage,
  childComponent: ServiceContent,
  relatedDataToFetch: ['places'],
};
