import {
  FETCH_CONTRIBUTIONS_FEED,
  CONTRIBUTIONS_FEED_LOADED,
  SET_CONTRIBUTIONS_FEED_ERROR,
} from 'src/store/actionTypes';

import { showNotification } from 'src/store/actions';

import { fetch, persistence } from 'src/core/contributions-feed/ContributionsFeedService';

import { EVENT_PAGE_KEY } from 'src/pages/pagesKeys';

export default ({ dispatch, getState }) => (next) => (action) => {
  const state = getState();
  const eventPageState = state[EVENT_PAGE_KEY];
  const labels = eventPageState ? eventPageState.labels : null;

  const result = next(action);

  switch (action.type) {
    case FETCH_CONTRIBUTIONS_FEED:
      fetch(action.userAction);
      break;

    case CONTRIBUTIONS_FEED_LOADED:
      persistence.set(action.payload);
      break;

    case SET_CONTRIBUTIONS_FEED_ERROR:
      if (labels) {
        dispatch(showNotification({ message: labels.common.noNetworkTitle }));
      }
      break;

    default:
  }
  return result;
};
