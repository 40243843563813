import { ENV } from 'app-customs/config/config';

// const LOG_PREF = '[KlipsoLeadsHelper] ';

export function getLastUpdate(contact) {
  const lastHistoryEntry = contact.history[0];
  if (typeof lastHistoryEntry.date === 'string') {
    return new Date(lastHistoryEntry.date);
  }
  return lastHistoryEntry.date;
}

/*
export const isContactSync = contact => (
    contact
        && contact.lastSync
        && Array.isArray(contact.history)
        && contact.lastSync > getLastUpdate(contact)
)
// (shortcut)
export const isContactNotSync = contact => isContactSync(contact) !== true
*/

export function contactFieldsToObject(fields) {
  const obj = {};
  if (Array.isArray(fields)) {
    fields.forEach((field) => {
      const fieldName = field.Id;

      obj[fieldName] =
        Array.isArray(field.Values) && field.Values.length > 0 ? field.Values : field.Value;
    });
  }
  return obj;
}

if (ENV === 'dev') {
  global.testContactFeldsToObject = function() {
    console.log(
      contactFieldsToObject([
        {
          Id: 'AutreNom',
          Label: 'Autre Nom',
          Value: 'autre nom',
          Values: [],
          Codes: [],
        },
        {
          Id: 'AutreSociete',
          Label: 'Autre Societe',
          Value: 'autre société',
          Values: [],
          Codes: [],
        },
        {
          Id: 'AutreEmail',
          Label: 'Autre Email',
          Value: 'test@leni.fr',
          Values: [],
          Codes: [],
        },
        {
          Id: 'Commentaire',
          Label: 'Commentaire',
          Value: 'test sync',
          Values: [],
          Codes: [],
        },
      ])
    );
  };
}
