import React from 'react';
import ReactDOM from 'react-dom';
import { throttle } from 'lodash-custom';

import { LIST_OF_GROUPS_SIDEINDEX_THRESHOLD } from 'app-customs/config/listConfig';

import { simpleSort } from 'src/core/util/sortUtil';
import scrollHack from 'src/core/util/scrollHack';
import { isIOS, isFirefox } from 'src/core/util/browser';

import List from 'src/components/list/List';
import TypeBar from 'src/components/type-bar/TypeBar';
import SideIndex from 'src/components/side-index/SideIndex';

export function parseListOfGroups({
  groupedItems,
  dataType,
  favIconDisabled,
  isPending,
  favorites,
  pageKey,
  actions,
  labels,
}) {
  const separatorEls = {};
  let hasSideIndex = true;
  let totalItems = 0;

  if (groupedItems) {
    Object.keys(groupedItems).forEach((groupId) => {
      const group = groupedItems[groupId];

      // Detect if there is a side index
      hasSideIndex = hasSideIndex && !!group.sideIndexLabel;

      // Update total
      totalItems += Array.isArray(group.items) ? group.items.length : 0;
    });

    if (Object.keys(groupedItems).length <= 1) {
      hasSideIndex = false;
    }
    if (totalItems < LIST_OF_GROUPS_SIDEINDEX_THRESHOLD) {
      hasSideIndex = false;
    }

    const sortedGroupKeys = Object.keys(groupedItems).sort((group1Key, group2Key) =>
      simpleSort(groupedItems[group1Key].rank, groupedItems[group2Key].rank)
    );

    let containerEl;
    let hackScroller;
    let sideIndexRef;

    const setContainerEl = (el) => {
      if (el) {
        containerEl = el;

        if (isIOS() || isFirefox()) {
          // execute a hack to be able to stop scroll momentum
          hackScroller = scrollHack(containerEl);
        }
      }
    };

    const scrollTo = throttle((offset, indexKey) => {
      if (hackScroller) {
        hackScroller(offset);
      } else if (containerEl) {
        containerEl.scrollTop = offset;
      }
    }, 80);

    function scrollToItemId(id) {
      const selector = `li[data-id="${id}"]`;
      const liEl = containerEl.querySelector(selector);
      if (!liEl) {
        console.warn(`Could not find <li> element to scroll to, no match for select: ${selector}`);
        return;
      }
      window.setTimeout(() => {
        scrollTo(liEl.offsetTop - 40);
      }, 100);
    }

    const setSideIndexRef = (ref) => {
      sideIndexRef = ref;
    };

    const onScroll = (event) => {
      if (sideIndexRef) {
        updateSelectedIndex(event.target.scrollTop);
      }
    };

    function updateSelectedIndex(scrollPosition) {
      sideIndexRef.setSelectedIndexFromScrollPosition(scrollPosition);
    }

    function renderGroup(groupKey) {
      const group = groupedItems[groupKey];
      return (
        <div key={groupKey}>
          <TypeBar
            isClickable={false}
            label={group.name}
            subtitle={group.subtitle}
            style={group.style}
            ref={(ref) => {
              separatorEls[groupKey] = ReactDOM.findDOMNode(ref);
            }}
          />
          <List
            items={group.items}
            dataType={dataType}
            isPending={isPending}
            displayFavorites={favIconDisabled !== true}
            favorites={favorites}
            actions={actions}
            labels={labels}
            pageKey={pageKey}
          />
        </div>
      );
    }

    return {
      scrollTo,
      scrollToItemId,
      content: (
        <div onScroll={hasSideIndex ? onScroll : null} className="items-list" ref={setContainerEl}>
          <div>{sortedGroupKeys.map(renderGroup)}</div>
        </div>
      ),
      sideIndex: hasSideIndex ? (
        <SideIndex
          ref={setSideIndexRef}
          indexes={sortedGroupKeys}
          separatorsGetter={() => separatorEls}
          scrollTo={scrollTo}
          getLabel={(groupKey) => groupedItems[groupKey].sideIndexLabel}
        />
      ) : null,
    };
  } else {
    return;
  }
}
