import * as Db from 'src/core/data-and-assets/Db';
import localforage from 'localforage';

import { FLIGHTS_SCHEDULE_PAGE_KEY, AIRCRAFT_PAGE_KEY } from 'src/pages/pagesKeys';

import { DATA_TYPE_AIRCRAFTS, DATA_TYPE_FLIGHTS_SCHEDULE } from 'app-customs/config/dataConfig';

import {
  CONFIG_JSON_LOADED,
  POLL_CONFIG_LOADED,
  DATA_ASSETS_UPDATED,
  FAILED,
  FETCHED_DATA,
  FLIGHT_CLICKED,
  HAS_NAVIGATED,
  LANG_CHANGED,
  NAVIGATE,
  PROFILE_CHANGED,
  REFRESH_DATA,
  RESTORE_DATA,
  STORED_DATA,
  TOGGLE_LOCATION_STATUS,
  TOGGLE_MENU,
  UPDATE_PAGE_STATE,
} from 'src/store/actionTypes';

import {
  configJsonLoaded,
  pollConfigLoaded,
  getPageCommonDefaultState,
  togglePageAfterNavigation,
  updatePageState,
  toggleLocationStatus,
  toggleMenu,
} from 'src/store/reducers/commons';

import * as Query from 'src/core/query/Query';

const { BO_URL } = require('app-customs/config/config');

export const id = (x) => x;

/*
 * Takes the data member of of a flights request
 * and returns a boolean determining if flights are
 * scheduled or not
 */
export function hasFlightsScheduled(data) {
  if (!data) {
    return false;
  }
  const days = Object.keys(data);

  if (days.length === 0) {
    return false;
  }
  let i = -1;
  while (++i < days.length) {
    if (data[days[i]].length > 0) {
      return true;
    }
  }
  return false;
}

export const URL = `https://${BO_URL}/flights/flights.json?`;

const getDefaultState = () => ({
  ...getPageCommonDefaultState(FLIGHTS_SCHEDULE_PAGE_KEY),
  lastFetched: null,
  currentIndex: 0, // what is it good for ?
  eventsByDays: null,
  isPending: true,
  failedLastAttempt: false,
  orignalData: null,
  aircrafts: null,
});

/**
 * Normalizes data keys
 * data has shape :
 * {
 *
 *      [ dateString ] : {}
 *
 * }
 * To :
 * {
 *
 *      [ timestamp ] : {}
 *
 * }
 */
export function keyToDate(key) {
  return new Date([key.slice(0, 4), key.slice(4, 6), key.slice(6)].join('-')).getTime();
}

export function linkWithAircrafts(event, aircraftsById) {
  return {
    ...event,
    aircraft: aircraftsById[event.aeronef_id] || null,
  };
}

export function fromOrder(order) {
  return (doc) =>
    doc.reduce((result, value, index) => ((result[order[index]] = value), result), {});
}

export function toObject(db) {
  if (!db) {
    return;
  }

  const { order, data } = db;
  const toObject = fromOrder(order);
  return Object.keys(data).reduce((result, key) => {
    const object = toObject(data[key]);
    result[object.original_id] = object;
    return result;
  }, {});
}

/**
 * @param data - data received with the events
 * @returns normalizedData - data enriched with aircrafts data and correct date stamp
 */
export function normalize(data, normalizeKeys = id, sort = id, labels, aircrafts) {
  const { notScheduledYet, unknownAircraft } = labels.data[DATA_TYPE_FLIGHTS_SCHEDULE];

  return Object.keys(data).reduce((result, key) => {
    const date = normalizeKeys(key);

    result[date] = sort(
      data[key].map((event) => linkWithAircrafts({ ...event, date }, aircrafts))
    ).map((event) => ({
      ...event,
      time_start: event.time_start ? event.time_start : '',
      time_end: event.time_start ? '' : notScheduledYet,
      aircraft: {
        ...(event.aircraft || {}),
        title: event.aircraft && event.aircraft.title ? event.aircraft.title : unknownAircraft,
      },
      hasAircraft: !!event.aircraft,
    }));
    return result;
  }, {});
}

export const noStartDate = (event) => !event.time_start;
export const neg = (val) => !val;
export const compose = (...fn) => (value) => fn.reduce((value, fn) => fn(value), value);

export const withStartDate = compose(noStartDate, neg);

export function sortTimeStart(e1, e2) {
  const [h1, m1] = e1.time_start.split(':').map(Number);
  const [h2, m2] = e2.time_start.split(':').map(Number);

  if (h1 === h2) {
    return m1 >= m2 ? 1 : -1;
  }
  return h1 >= h2 ? 1 : -1;
}

export function sortEvents(events) {
  if (events.length <= 1) {
    return events;
  }
  const head = events.filter(withStartDate);
  const tail = events.filter(noStartDate);

  return [].concat(head.sort(sortTimeStart), tail);
}

export function reducer(state = getDefaultState(), action) {
  let aircrafts;
  switch (action.type) {
    case CONFIG_JSON_LOADED:
      return configJsonLoaded(state);
    case POLL_CONFIG_LOADED:
      return pollConfigLoaded(state, action);

    case NAVIGATE:
      if (action.pageKey === FLIGHTS_SCHEDULE_PAGE_KEY && action.options && action.options.id) {
        return {
          ...state,
          id: action.options.id,
        };
      }
      return {
        ...state,
        id: null,
      };

    case HAS_NAVIGATED:
      return togglePageAfterNavigation(state, FLIGHTS_SCHEDULE_PAGE_KEY);

    case FAILED:
      return {
        ...state,
        failedLastAttempt: true,
      };

    case PROFILE_CHANGED:
      return {
        ...state,
        profile: action.profile,
      };

    case TOGGLE_LOCATION_STATUS:
      return toggleLocationStatus(state, action);

    case TOGGLE_MENU:
      return toggleMenu(state, action, FLIGHTS_SCHEDULE_PAGE_KEY);

    case LANG_CHANGED:
      return {
        ...state,
        labels: action.labels,
      };

    case RESTORE_DATA:
    case FETCHED_DATA:
      return {
        ...state,
        isPending: false,
        itemNotFound: hasFlightsScheduled(action.data) === false,
        lastFetched: action.meta.date,
        eventsByDays: normalize(action.data, keyToDate, sortEvents, state.labels, state.aircrafts),
        orignalData: action.data,
        failedLastAttempt: false,
      };

    case DATA_ASSETS_UPDATED:
      aircrafts = toObject(Db.getData()[DATA_TYPE_AIRCRAFTS]);
      const newState = {
        ...state,
        eventsByDays:
          state.isPending !== true && state.itemNotFound !== true
            ? normalize(state.orignalData, keyToDate, sortEvents, state.labels, aircrafts)
            : {},
        aircrafts,
      };
      return newState;

    case UPDATE_PAGE_STATE:
      return updatePageState(state, action, FLIGHTS_SCHEDULE_PAGE_KEY);

    default:
      return state;
  }
}

export function getData() {
  return fetchData().then(
    (json) => ({
      type: FETCHED_DATA,
      data: json,
      meta: {
        date: Date.now(),
      },
    }),
    (err) => ({
      type: FAILED,
      data: err,
      meta: {
        date: Date.now(),
      },
    })
  );
}

/**
 * use with ORIGINAL_ID
 */
export function findAeronefById(id, db) {
  const { order, data } = db;
  const toObject = fromOrder(order);
  const result = Object.keys(data)
    .map((key) => data[key])
    .map(toObject)
    .filter((aeronef) => aeronef.original_id == id)[0];

  return result || null;
}

export function middleware(store) {
  let setReady;
  const dbReady = new Promise((resolve) => {
    setReady = resolve;
  });

  localforage
    .getItem(FLIGHTS_SCHEDULE_PAGE_KEY)
    .then(function(result) {
      if (result && result.data && result.date) {
        dbReady.then(function() {
          store.dispatch({
            type: RESTORE_DATA,
            data: result.data,
            meta: {
              date: result.date,
            },
          });
        });
      }
    })
    .catch((e) => {
      console.error('Cannot get flight schedule from localforage', e);
    });

  return (next) => (action) => {
    const result = next(action);

    switch (action.type) {
      case NAVIGATE:
        if (action.pageKey === FLIGHTS_SCHEDULE_PAGE_KEY) {
          getData().then((action) => dbReady.then(() => store.dispatch(action)));
          return result;
        }
        return result;

      case FETCHED_DATA:
        localforage
          .setItem(FLIGHTS_SCHEDULE_PAGE_KEY, { data: action.data, date: action.meta.date })
          .then(
            () => ({
              type: STORED_DATA,
              meta: { success: true },
            }),
            (error) => ({
              type: STORED_DATA,
              meta: {
                success: false,
                error,
              },
            })
          )
          .then(store.dispatch);

        return result;

      case DATA_ASSETS_UPDATED: {
        setReady();
        return result;
      }

      case RESTORE_DATA:
        return result;

      case FLIGHT_CLICKED: {
        const id = action.data;
        const event = findEventById(id, store.getState()[FLIGHTS_SCHEDULE_PAGE_KEY].eventsByDays);

        const aeronef = event
          ? findAeronefById(event.aeronef_id, Db.getData()[DATA_TYPE_AIRCRAFTS])
          : null;

        if (event && aeronef) {
          store.dispatch({
            type: NAVIGATE,
            pageKey: AIRCRAFT_PAGE_KEY,
            options: {
              id: Number(aeronef.id),
            },
          });
          return result;
        }
        return result;
      }

      case REFRESH_DATA:
        getData().then((action) => dbReady.then(() => store.dispatch(action)));
        return result;

      default:
        return result;
    }
  };
}

export function findEventById(id, eventsByDays) {
  const days = Object.keys(eventsByDays);
  const l = days.length;
  let i = -1;
  let item;

  while (++i < l) {
    item = eventsByDays[days[i]].filter((event) => event.id == id)[0];

    if (item) {
      break;
    }
  }

  return item || null;
}

const isNonNull = (obj) => typeof obj === 'object' && obj !== null;

export function getFlightCounts(aircraft_id, eventsByDays, aircrafts) {
  if (isNonNull(eventsByDays) && isNonNull(aircrafts)) {
    const db = Db.getData()[DATA_TYPE_AIRCRAFTS];
    const { original_id } = db.data[aircraft_id];

    return Object.keys(eventsByDays).reduce((flightCount, key) => {
      const events = eventsByDays[key];
      if (!Array.isArray(events)) {
        return flightCount;
      }
      return flightCount + events.filter((event) => event.aeronef_id == original_id).length;
    }, 0);
  }
  return 0;
}

export function fetchData() {
  return fetch(URL + Math.random())
    .then((res) => res.json())
    .then((json) => {
      if (json.hasOwnProperty('flights')) {
        return json.flights;
      }
      throw new Error('missing FLIGTS in json');
    });
}

// for a given aircraft id, return original_id
export function getAircraftOriginalId(id) {
  const aircraft = Query.get(id, DATA_TYPE_AIRCRAFTS);
  return aircraft ? aircraft.original_id : null;
}
