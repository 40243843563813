import React from 'react';
import PropTypes from 'prop-types';
import * as dialogConfig from 'app-customs/config/dialogsConfig'

import './FlagButton.scss';

function FlagButton({ className, lang, labels, onClick }) {
  const {chooseLangByLabel} = dialogConfig || {};
  if (!lang) {
    return null;
  }

  const cssClasses = ['flag-select', `flag-${lang}`];

  function _onClick() {
    if (typeof onClick === 'function') {
      onClick(lang);
    }
  }
  return (
    <span
      className={`flag-select flag-${lang} ${className || ''} ${chooseLangByLabel && 'no-flag'}`}
      title={labels ? labels.lang[lang] : ''}
      data-label={lang}
      onClick={_onClick}
    />
  );
}

FlagButton.propTypes = {
  className: PropTypes.string,
  lang: PropTypes.string.isRequired,
  labels: PropTypes.object,
  onClick: PropTypes.func,
};

export default FlagButton;
