// doc: https://github.com/phonegap/phonegap-plugin-barcodescanner

export default (function (labels) {
  return {
    preferFrontCamera: false,
    // iOS and Android
    showFlipCameraButton: true,
    // iOS and Android
    showTorchButton: true,
    // iOS and Android
    torchOn: false,
    // Android, launch with the torch switched on (if available)
    saveHistory: false,
    // Android, save scan history (default false)
    prompt: labels.klipsoLeads.androidScannerText,
    // Android
    resultDisplayDuration: 0,
    // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
    orientation: 'portrait',
    // Android only (portrait|landscape), default unset so it rotates with the device
    disableAnimations: true,
    // iOS
    disableSuccessBeep: true // iOS and Android

    /**
     * Possible values:
     *   QR_CODE
     *   DATA_MATRIX
     *   UPC_A
     *   UPC_E
     *   EAN_8
     *   EAN_13
     *   CODE_39
     *   CODE_93
     *   CODE_128
     *   ITF
     *   AZTEC
     *   PDF_417
     *   RSS_EXPANDED
     *
     * separate with a comma e.g 'QR_CODE,UPC_A,UPC_E,EAN_8,EAN_13'
     */
    // formats : 'QR_CODE,EAN_13', // default value: all but PDF_417 and RSS_EXPANDED
  };
});