let currentPageKey;

export const getCurrentPageKey = () => currentPageKey;

export function setCurrentPageKey(value) {
  currentPageKey = value;
}

let currentPageProps;

export const getCurrentPageProps = () => currentPageProps;

export function setCurrentPageProps(value) {
  currentPageProps = value;
}

let startPosition;
let endPosition;
let pmr;
export const getStartPosition = () => startPosition;
export const setStartPosition = (value) => {
  try {
    const valueParsed = typeof value === 'object' ? value : JSON.parse(value);
    startPosition = valueParsed
    
  } catch (error) {
    console.error('error on set startPosition', error);
  }
};

export const getEndPosition = () => endPosition;
export const setEndPosition = (value) => {
  try {
    const valueParsed = typeof value === 'object' ? value : JSON.parse(value);
    endPosition = valueParsed;
    
  } catch (error) {
    console.error('error on set end position', error)
  }
};

export const getPmr = () => pmr;
export const setPmr = (value) => {pmr = value};