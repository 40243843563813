import { SHOW_PDF } from 'src/store/actionTypes';

import { openUrl } from 'src/core/util/JsTools';
import { isAndroid } from 'src/core/util/browser';
import showPdf from 'src/core/pdf/pdfViewerAndroid';
import { getBindedActions } from 'src/store/bindedActions';

function _showPdf(item) {
  const pdf = item?.lump?.pdf;
  const url = pdf?.url;
  if (url && isAndroid()) {
    showPdf(url, pdf?.name, {
      headerColor: '#000000',
      showScroll: true,
      showShareButton: false,
      showCloseButton: true,
      swipeHorizontal: false,
    });
  } else if (url && openUrl(url, null, '_blank', true)) {
    getBindedActions().navigate(url);
  }
}

export default ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case SHOW_PDF:
        _showPdf(action.paperItem);
        break;
      default: // for linter
    }

    return result;
  };
