const LOCALSTORAGE_EVENT_KEY = 'events_calendars';

/**
 * events getter
 * @return {string}
 */
export function getEvents_calendars() {
  const data = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_EVENT_KEY));

  return data ? data : [];
}

/**
 * events find
 * @return {string}
 */
export function event_Exsist(id) {
  return id && getEvents_calendars()?.includes(id) ? true : false;
}

/**
 * events setter
 * @param {string} value
 */
export function setEvents_calendars(value) {
  value && window.localStorage.setItem(LOCALSTORAGE_EVENT_KEY, JSON.stringify(value));
}

/**
 * event add
 * @param {string} value
 */
export function add_Event_calendars(id) {
  const data = getEvents_calendars();
  data?.push(id);
  setEvents_calendars(data);
}
