import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';

import './InputSwitch.scss';

const SWITCH_STYLE = {
  onColor: '#86d3ff',
  onHandleColor: '#2693e6',
  handleDiameter: 22,
  uncheckedIcon: false,
  checkedIcon: false,
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.6)',
  activeBoxShadow: '0px 0px 1px 10px rgba(0, 0, 0, 0.2)',
  height: 12,
  width: 36,
};

class InputSwitch extends React.PureComponent {
  state = {};

  resetState = () => {
    this.setState({ value: null });
  };

  onChangeHandler = (value) => {
    this.setState({ value: value });
    this.props.submit(this.props.name, value);
  };

  isValueModified = () => this.state.value !== undefined && this.state.value !== null;

  getFieldName = () => this.props.name;

  render() {
    return (
      <div key={this.props.name} className="input-switch-container">
        {this.props.label && <span className="input-switch-label">{this.props.label}</span>}
        <Switch
          className="react-switch"
          checked={this.isValueModified() ? this.state.value : this.props.value}
          disabled={!this.props.editable}
          onChange={this.onChangeHandler}
          {...SWITCH_STYLE}
        />
      </div>
    );
  }
}

InputSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  editable: PropTypes.bool,
  submit: PropTypes.func.isRequired,
};

export default InputSwitch;
