import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';

import Tabs from 'src/components/tabs/Tabs';
import Loader from 'src/components/loader/Loader';
import NoResult from 'src/components/no-result/NoResult';
import SynopticAgendaGrid from './SynopticAgendaGrid';

class SynopticAgendaContent extends React.Component {
  getDaysCount = () => Object.keys(this.props.groupedItems)?.length;

  handleChangeIndex = (value) => {
    this.props.actions.synopticAgendaTabIndexUpdate(value);
  };

  findKeyIndex(index) {
    const { tabsData } = this.props;
    let resultKeyIndex = tabsData
      ? tabsData.findIndex((x) => x.key === index) === -1
        ? index
        : tabsData.findIndex((x) => x.key === index)
      : index;
    return resultKeyIndex;
  }

  gridsRenderer = () => {
    // doc API: https://react-swipeable-views.com/api/api/
    return (
      <SwipeableViews
        disabled={true}
        index={this.findKeyIndex(this.props.index)}
        onChangeIndex={this.handleChangeIndex}
      >
        {this.getDaysCount() && Object.keys(this.props.groupedItems)?.map(this.gridRenderer)}
      </SwipeableViews>
    );
  };

  gridRenderer = (date) => (
    <SynopticAgendaGrid
      key={date}
      events={this.props.groupedItems?.[date].items}
      eventsByPlaces={this.props.eventsByDayByPlaces[date]}
      isPageVisible={this.props.isPageVisible}
      synoConfig={this.props.synoConfig}
      labels={this.props.labels}
      actions={this.props.actions}
    />
  );

  getTabsData = () =>
    this.props.tabsData.map(({ key, label }, index) => ({
      key,
      getTabContent: () => label,
      isCurrentTab: () => this.findKeyIndex(this.props.index) === index,
    }));

  render() {
    if (
      !this.props.groupedItems ||
      Object.keys(this.props.groupedItems).length === 0 ||
      !this.props.eventsByDayByPlaces ||
      Object.keys(this.props.eventsByDayByPlaces).length === 0
    ) {
      // No result
      if (this.props.itemNotFound === true) {
        return <NoResult labels={this.props.labels} />;

        // Pending request
      } else if (this.props.isPending === true) {
        return <Loader labels={this.props.labels} />;
      }
      console.error(
        'Unexpected state (no events, and request is neither pending nor without result)'
      );
      return null;
    }
    return (
      <div id="synoptic-container" className="content-below-apptoolbar">
        <Tabs
          currentIndex={this.props.index}
          data={this.getTabsData()}
          onTabIndexChange={this.handleChangeIndex}
        />
        {this.gridsRenderer()}
      </div>
    );
  }
}

SynopticAgendaContent.propTypes = {
  synoConfig: PropTypes.object,
  isPending: PropTypes.bool,
  itemNotFound: PropTypes.bool,
  groupedItems: PropTypes.object,
  eventsByDayByPlaces: PropTypes.object,
  tabsData: PropTypes.array,
  labels: PropTypes.object,
  actions: PropTypes.object,
};

export default SynopticAgendaContent;
