import { getBindedActions } from 'src/store/bindedActions';

let hold = false;

const applyResize = () => {
  getBindedActions().windowResized();
  hold = false;
};

const start = () => {
  if (hold !== true) {
    hold = true;
    window.requestAnimationFrame(applyResize);
  }
};

export const init = () => {
  window.addEventListener('resize', start);
};
