import { PARTICIPANT_PAGE_KEY } from 'src/pages/pagesKeys';

import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';

import { isSessionValid } from 'src/core/login/LoginService';
import ParticipantContent from './ParticipantContent';

export default {
  key: PARTICIPANT_PAGE_KEY,
  path: '/participant',
  elId: DOM_ID,
  className: 'participant-page',
  component: GenericItemPage,
  childComponent: ParticipantContent,
  relatedDataToFetch: ['participantDbEvents', 'participantExhibitor'],
  isGranted: () => isSessionValid(),
};
