import {
  ALL_FAVORITES_DELETED,
  CODIFICATIONS_FETCHED,
  CONFIG_JSON_LOADED,
  HAS_NAVIGATED,
  KEYBOARD_TOGGLED,
  LANG_CHANGED,
  PROFILE_CHANGED,
  TAIGA_SEARCH_ONGOING,
  TAIGA_SEARCH_PERFORMED,
  TOGGLE_LOCATION_STATUS,
  TAIGA_SEARCH_CLEARED,
  TOGGLE_FAVORITE,
  TOGGLE_MENU,
  UPDATE_PAGE_STATE,
  USER_DATA_UPDATED,
} from 'src/store/actionTypes';

import {
  allFavoritesDeleted,
  configJsonLoaded,
  getPageCommonDefaultState,
  togglePageAfterNavigation,
  langChanged,
  profileChanged,
  toggleLocationStatus,
  toggleMenu,
  updateKeyboardState,
  updateObject,
  updatePageState,
  userDataUpdated,
} from 'src/store/reducers/commons';

import { parseListItems } from 'src/store/reducers/listCommons';
import { SEARCH_TAIGA_PAGE_KEY } from 'src/pages/pagesKeys';
import { getUserData } from 'src/core/user-data/UserDataService';

const getDefaultState = () => ({
  ...getPageCommonDefaultState(SEARCH_TAIGA_PAGE_KEY), // other default props:
  userData: getUserData(),
});

const _taigaSearchOngoing = (state, action) =>
  updateObject(state, {
    results: null,
    status: {
      [action.dataType]: action.status,
    },
  });

function _taigaSearchPerformed(state, action) {
  if (action.error) {
    return _taigaSearchCleared(state, action);
  }
  const status = {
    [action.dataType]: action.status,
  };
  const items = {
    [action.dataType]: action.data,
  };

  return updateObject(state, {
    results: parseListItems(status, items, SEARCH_TAIGA_PAGE_KEY),
    status,
    currentSearchFields: action.fields,
  });
}

const _taigaSearchCleared = (state, action) =>
  updateObject(state, {
    results: null,
    status: null,
    currentSearchFields: null,
  });

const _toggleFavorite = (state, action) => updateObject(state, { favorites: action.favorites });

function _updatePageState(state, action) {
  if (action.pageKey !== SEARCH_TAIGA_PAGE_KEY) {
    return state;
  }

  // When navigating again to SearchTaigaPage, reset navIndex prop
  let propsToResetNavIndex = {};
  if (typeof action.props.navIndex !== 'number') {
    propsToResetNavIndex = { navIndex: null };
  }
  return Object.assign(updatePageState(state, action, SEARCH_TAIGA_PAGE_KEY), propsToResetNavIndex);
}


export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case ALL_FAVORITES_DELETED:
      return allFavoritesDeleted(state);
    case CODIFICATIONS_FETCHED:
      return updateObject(state, { lastCodificationsFetch: new Date().getTime() });
    case CONFIG_JSON_LOADED:
      return configJsonLoaded(state);
    case HAS_NAVIGATED:
      return togglePageAfterNavigation(state, SEARCH_TAIGA_PAGE_KEY);
    case KEYBOARD_TOGGLED:
      return updateKeyboardState(state, action);
    case LANG_CHANGED:
      return langChanged(state, action);
    case PROFILE_CHANGED:
      return profileChanged(state, action, SEARCH_TAIGA_PAGE_KEY);
    case TAIGA_SEARCH_ONGOING:
      return _taigaSearchOngoing(state, action);
    case TAIGA_SEARCH_PERFORMED:
      return _taigaSearchPerformed(state, action);
    case TAIGA_SEARCH_CLEARED:
      return _taigaSearchCleared(state, action);
    case TOGGLE_FAVORITE:
      return _toggleFavorite(state, action);
    case TOGGLE_LOCATION_STATUS:
      return toggleLocationStatus(state, action);
    case TOGGLE_MENU:
      return toggleMenu(state, action, SEARCH_TAIGA_PAGE_KEY);
    case UPDATE_PAGE_STATE:
      return _updatePageState(state, action);
    case USER_DATA_UPDATED:
      return userDataUpdated(state, action);

    default:
      return state;
  }
};
