import pagesConfig from 'app-customs/config/pagesConfig';
import { initKeysOfPagesUsingGenericItem } from 'src/pages/generic-item-page/helpers';

/**
 * CSS class attribute for all page containers
 * @type {String}
 */
export const PAGE_CSSCLASS = 'page-container';

// Store container DOM element associated with each page
const elements = {};

const getElement = (elId) => {
  if (!elements[elId]) {
    elements[elId] = document.getElementById(elId);
  }
  return elements[elId];
};

// Assigned to each page
const COMMON_DEFAULT_PROPERTIES = {
  getElement() {
    return getElement(this.elId);
  },
  setElement(el) {
    elements[this.elId] = el;
  },
  active: false,
};

// Create a key/value association to expose
const pagesDefinition = {};
pagesConfig.forEach((def) => {
  if (typeof def.key === 'undefined') {
    console.error("Missing 'key' attribute! ", def);
    return;
  }
  pagesDefinition[def.key] = Object.assign(def, COMMON_DEFAULT_PROPERTIES);
});

// Expose object in dev env
if (process.env.NODE_ENV !== 'production') {
  global.Pages = pagesDefinition;
}

export default pagesDefinition;

initKeysOfPagesUsingGenericItem();
